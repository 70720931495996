/* eslint no-unused-vars: "off" */
import { put, call, fork, takeLatest } from 'redux-saga/effects'
import toastr from 'toastr'
import * as testCentreTypes from './test.centre.action.types'
import { USER_LOGOUT } from '../../Auth/login/engine/login.action.types'
import api from '../../../services/api/exams.api'
import { TOASTR_OPTIONS } from '../../../consts'
import { transformValidatedExamQuestions } from '../../../services/transform/exam.res.transform'

/**
 * TEST CENTRE SAGAS
 * @type Core
 * @author Ehsan
 * @version 0.1.1
 */

Object.assign(toastr.options, TOASTR_OPTIONS)

export function* listTestsRequest(action) {
    const [accessToken, userId, sortType] = action.payload
    try {
        const response = yield call(api.exams.list, action.payload)
        if (response && response.status === 200) {
            yield put({
                type: testCentreTypes.LIST_TESTS_SUCCESS,
                payload: { ...response.data, sortType },
            })
            toastr['success']('Exams successfully listed.')
        } else {
            throw response
        }
    } catch (e) {
        const delay = (ms) => new Promise((res) => setTimeout(res, ms))
        yield put({
            type: testCentreTypes.LIST_TESTS_FAIL,
            payload: e,
        })
        let message
        let status
        if (e.response) {
            message = e.response.data.message
            status = e.response.data.status
        }
        if (status === 401) {
            toastr['error'](message, 'Authentication Error')
            yield put({
                type: testCentreTypes.LIST_TESTS_FAIL,
                payload: message,
            })
            yield delay(3000)
            yield put({
                type: USER_LOGOUT,
            })
        } else {
            toastr['error'](message)
        }
    }
}

export function* generateExamRequest(action) {
    const [examData, accessToken, userId, sortType] = action.payload
    const delay = (ms) => new Promise((res) => setTimeout(res, ms))
    try {
        const response = yield call(api.exams.generate, action.payload)
        if (response && response.status === 201) {
            yield put({
                type: testCentreTypes.GENERATE_EXAM_SUCCESS,
            })
            toastr['success']('Exam successfully generated.')
            delay(2000)
            yield put({
                type: testCentreTypes.LIST_TESTS_START,
                payload: [accessToken, userId, sortType],
            })
        } else {
            throw response
        }
    } catch (e) {
        const delay = (ms) => new Promise((res) => setTimeout(res, ms))
        yield put({
            type: testCentreTypes.GENERATE_EXAM_FAIL,
            payload: e,
        })
        let message
        let status
        if (e.response) {
            message = e.response.data.message
            status = e.response.data.status
        }
        if (status === 401) {
            toastr['error'](message, 'Authentication Error')
            yield put({
                type: testCentreTypes.GENERATE_EXAM_FAIL,
                payload: message,
            })
            yield delay(3000)
            yield put({
                type: USER_LOGOUT,
            })
        } else {
            toastr['error'](message)
        }
    }
}

export function* deleteExamRequest(action) {
    const [examId, accessToken, userId, sortType] = action.payload
    const delay = (ms) => new Promise((res) => setTimeout(res, ms))
    try {
        const response = yield call(api.exams.delete, action.payload)
        if (response && response.status === 200) {
            yield put({
                type: testCentreTypes.DELETE_EXAM_SUCCESS,
            })
            toastr['success']('Exam successfully deleted.')
            delay(2500)
            yield put({
                type: testCentreTypes.LIST_TESTS_START,
                payload: [accessToken, userId, sortType],
            })
        } else {
            throw response
        }
    } catch (e) {
        const delay = (ms) => new Promise((res) => setTimeout(res, ms))
        yield put({
            type: testCentreTypes.DELETE_EXAM_FAIL,
            payload: e,
        })
        let message
        let status
        if (e.response) {
            message = e.response.data.message
            status = e.response.data.status
        }
        if (status === 401) {
            toastr['error'](message, 'Authentication Error')
            yield put({
                type: testCentreTypes.DELETE_EXAM_FAIL,
                payload: message,
            })
            yield delay(3000)
            yield put({
                type: USER_LOGOUT,
            })
        } else {
            toastr['error'](message)
        }
    }
}

export function* addBookmarkExamRequest(action) {
    const [data, accessToken, shouldLoadBookmarks, sortType] = action.payload
    try {
        const response = yield call(api.exams.addBookmark, action.payload)
        if (response && response.status === 200) {
            yield put({
                type: testCentreTypes.ADD_USER_EXAM_BOOKMARK_SUCCESS,
                payload: { data: response.data, shouldLoadBookmarks, sortType },
            })

            // const { userId } = data
            // yield put({
            //     type: testCentreTypes.LIST_USER_EXAM_BOOKMARKS_START,
            //     payload: [accessToken, userId],
            // })
            // list user bookmarks!?
            toastr['success']('Exam successfully bookmarked.')
        } else {
            throw response
        }
    } catch (e) {
        const delay = (ms) => new Promise((res) => setTimeout(res, ms))
        yield put({
            type: testCentreTypes.ADD_USER_EXAM_BOOKMARK_FAIL,
            payload: e,
        })
        let message
        let status
        if (e.response) {
            message = e.response.data.message
            status = e.response.data.status
        }
        if (status === 401) {
            toastr['error'](message, 'Authentication Error')
            yield put({
                type: testCentreTypes.ADD_USER_EXAM_BOOKMARK_FAIL,
                payload: message,
            })
            yield delay(3000)
            yield put({
                type: USER_LOGOUT,
            })
        } else {
            toastr['error'](message)
        }
    }
}

export function* removeBookmarkExamRequest(action) {
    const [data, accessToken, shouldLoadBookmarks, sortType] = action.payload
    try {
        const response = yield call(api.exams.removeBookmark, action.payload)
        if (response && response.status === 200) {
            yield put({
                type: testCentreTypes.REMOVE_USER_EXAM_BOOKMARK_SUCCESS,
                payload: { data: response.data, shouldLoadBookmarks, sortType },
            })
            toastr['success']('Bookmark successfully removed.')
        } else {
            throw response
        }
    } catch (e) {
        const delay = (ms) => new Promise((res) => setTimeout(res, ms))
        yield put({
            type: testCentreTypes.REMOVE_USER_EXAM_BOOKMARK_FAIL,
            payload: e,
        })
        let message
        let status
        if (e.response) {
            message = e.response.data.message
            status = e.response.data.status
        }
        if (status === 401) {
            toastr['error'](message, 'Authentication Error')
            yield put({
                type: testCentreTypes.REMOVE_USER_EXAM_BOOKMARK_FAIL,
                payload: message,
            })
            yield delay(3000)
            yield put({
                type: USER_LOGOUT,
            })
        } else {
            toastr['error'](message)
        }
    }
}

export function* listExamBookmarsRequest(action) {
    try {
        const [accessToken, userId, sortType] = action.payload
        const response = yield call(api.exams.listExamBookmarks, action.payload)
        if (response && response.status === 200) {
            yield put({
                type: testCentreTypes.LIST_USER_EXAM_BOOKMARKS_SUCCESS,
                payload: { ...response.data, sortType },
            })
        } else {
            throw response
        }
    } catch (e) {
        const delay = (ms) => new Promise((res) => setTimeout(res, ms))
        yield put({
            type: testCentreTypes.LIST_USER_EXAM_BOOKMARKS_FAIL,
            payload: e,
        })
        let message
        let status
        if (e.response) {
            message = e.response.data.message
            status = e.response.data.status
        }
        if (status === 401) {
            toastr['error'](message, 'Authentication Error')
            yield put({
                type: testCentreTypes.LIST_USER_EXAM_BOOKMARKS_FAIL,
                payload: message,
            })
            yield delay(3000)
            yield put({
                type: USER_LOGOUT,
            })
        } else {
            toastr['error'](message)
        }
    }
}

export function* validateExamQuestionsRequest(action) {
    try {
        const response = yield call(api.exams.validateExamQuestions, action.payload)
        if (response && response.status === 200) {
            const {
                data: { data },
            } = response
            yield put({
                type: testCentreTypes.VALIDATE_EXAM_QUESTIONS_SUCCESS,
                payload: transformValidatedExamQuestions(data),
            })
        } else {
            throw response
        }
    } catch (e) {
        const delay = (ms) => new Promise((res) => setTimeout(res, ms))
        yield put({
            type: testCentreTypes.VALIDATE_EXAM_QUESTIONS_FAIL,
            payload: e,
        })
        let message
        let status
        if (e.response) {
            message = e.response.data.message
            status = e.response.data.status
        }
        if (status === 401) {
            toastr['error'](message, 'Authentication Error')
            yield put({
                type: testCentreTypes.VALIDATE_EXAM_QUESTIONS_FAIL,
                payload: message,
            })
            yield delay(3000)
            yield put({
                type: USER_LOGOUT,
            })
        } else {
            toastr['error'](message)
        }
    }
}

// a watcher to listen to our request ‘start’ action
function* watchUserLoginRequest() {
    yield takeLatest(testCentreTypes.LIST_TESTS_START, listTestsRequest)
    yield takeLatest(testCentreTypes.GENERATE_EXAM_START, generateExamRequest)
    yield takeLatest(testCentreTypes.DELETE_EXAM_START, deleteExamRequest)
    yield takeLatest(testCentreTypes.ADD_USER_EXAM_BOOKMARK_START, addBookmarkExamRequest)
    yield takeLatest(testCentreTypes.REMOVE_USER_EXAM_BOOKMARK_START, removeBookmarkExamRequest)
    yield takeLatest(testCentreTypes.LIST_USER_EXAM_BOOKMARKS_START, listExamBookmarsRequest)
    yield takeLatest(testCentreTypes.VALIDATE_EXAM_QUESTIONS_START, validateExamQuestionsRequest)
}

// use 'fork' to start multiple sagas in the background
// they are always attached to thier parent
export default [fork(watchUserLoginRequest)]
