import { combineReducers } from 'redux'
import loginReducer from './Auth/login/engine/login.reducer'
import userReducer from './Users/engine/user.reducer'
import userFeedbackReducer from './UserFeedback/engine/feedback.reducer'
import questionsReducer from './Questions/engine/questions.reducer'
import tagsReducer from './Tags/engine/tags.reducer'
import testCentreReducer from './TestCentre/engine/test.centre.reducer'
import examDetailsReducer from './ExamDetails/engine/exam.details.reducer'

export default combineReducers({
    login: loginReducer,
    users: userReducer,
    feedback: userFeedbackReducer,
    questions: questionsReducer,
    tags: tagsReducer,
    exams: testCentreReducer,
    examDetails: examDetailsReducer,
})
