/* eslint-disable no-underscore-dangle */
import { createSelector } from 'reselect'

const fetchStatusSelector = (state) => state.pages.login.isFetching
export const getFetchStatus = createSelector(fetchStatusSelector, (status) => status)

const userAuthStatusSelector = (state) => state.pages.login.userIsAuthenticated
export const getUserAuthStatus = createSelector(userAuthStatusSelector, (status) => status)

const accessTokenSelector = (state) => state.persistedData.token
export const getAccessToken = createSelector(accessTokenSelector, (token) => token)

const persistedThemeSelector = (state) => state.persistedData.selectedTheme
export const getPersistedTheme = createSelector(persistedThemeSelector, (theme) => theme)

const persistedCodingLaguageSelector = (state) => state.persistedData.codingLanguages
export const getPersistedCodingLanguages = createSelector(
    persistedCodingLaguageSelector,
    (languages) => languages
)

const userRoleSelector = (state) => state.persistedData.userData
export const getUserRole = createSelector(userRoleSelector, (data) => (data ? data.role : null))

const userExpiredExamsSelector = (state) =>
    state.persistedData.userData ? state.persistedData.userData.expiredExams : []
export const getUserExpiredExams = createSelector(
    userExpiredExamsSelector,
    (expiredExams) => expiredExams
)

const getExpirySelector = (state) => state.persistedData.tokenExpiry
export const getTokenExpiry = createSelector(getExpirySelector, (data) => data)

const userDataSelector = (state) => state.persistedData.userData
export const getUserData = createSelector(userDataSelector, (data) => data)

const userIdSelector = (state) => state.persistedData.userData
export const getUserId = createSelector(userIdSelector, (data) => (data ? data._id : null))

const isSignedupStatusSelector = (state) => state.pages.login.isSignedup
export const getSignupStatus = createSelector(isSignedupStatusSelector, (status) => status)

// const userLoginStatusSelector = (state) => state.persistedData.isUserLoggedIn
// export const getUserLoginStatus = createSelector(userLoginStatusSelector, (status) => status)

// const userFullNameSelector = (state) => state.persistedData.userFullName
// export const getUserFullName = createSelector(userFullNameSelector, (userFullName) => userFullName)

// const authTokenStatusSelector = (state) => state.persistedData.hasToken
// export const getAuthTokenStatus = createSelector(authTokenStatusSelector, (status) => status)

// const userIdSelector = (state) => state.persistedData.userData._id
// export const getUserId = createSelector(userIdSelector, (id) => id)
