import * as actions from './questions.action.types'

const initialState = {
    isFetching: false,
    questions: [],
    questionsCount: 0,
    isAdding: false,
    addedQuestionId: null,
    isBookmarking: false,
    userBookmarks: [],
    userSectionBookmarks: [],
    isSectionBookmarking: false,
    stats: null,
}

export default (state = initialState, action) => {
    switch (action.type) {
        case actions.GET_QUESTIONS_STATS_START:
        case actions.LIST_QUESTIONS_START:
            return {
                ...state,
                isFetching: true,
            }

        // case actions.SEARCH_FILTERED_QUESTION_SUCCESS:
        case actions.LIST_FILTERED_QUESTIONS_SUCCESS:
        case actions.GET_QUESTION_SUCCESS:
        case actions.LIST_QUESTIONS_SUCCESS: {
            const { questions, count: questionsCount } = action.payload
            return {
                ...state,
                isFetching: false,
                questions,
                questionsCount,
            }
        }

        case actions.GET_QUESTIONS_STATS_SUCCESS:
            return {
                ...state,
                isFetching: false,
                stats: action.payload,
            }

        case actions.GET_QUESTIONS_STATS_FAIL:
            return {
                ...state,
                stats: null,
                isFetching: false,
            }

        case actions.SEARCH_FILTERED_QUESTION_SUCCESS:
        case actions.SEARCH_QUESTION_SUCCESS: {
            const { questions, count: questionsCount, pagination } = action.payload
            const { limit, page } = pagination
            const startIndex = (page - 1) * limit
            const endIndex = Math.min(startIndex + limit - 1, questionsCount - 1)

            // console.log('pagination', pagination)
            // console.log(startIndex, endIndex)
            const paginatedQuestions = questions.slice(startIndex, endIndex + 1)
            // console.log('questions', questions)
            return {
                ...state,
                isFetching: false,
                questions: paginatedQuestions,
                questionsCount,
            }
        }

        case actions.SEARCH_FILTER_QUESTION_BY_LNG_SUCCESS:
        case actions.FILTER_QUESTION_BY_LNG_SUCCESS_BOOKMARK:
        case actions.LIST_BOOKMARK_FILTERED_QUESTION_SUCCESS:
        case actions.SEARCH_BOOKMARK_QUESTION_SUCCESS:
        case actions.LIST_BOOKMARKED_QUESTIONS_SUCCESS: {
            const { questions, count: questionsCount, pagination } = action.payload
            const { limit, page } = pagination
            const startIndex = (page - 1) * limit
            const endIndex = Math.min(startIndex + limit - 1, questionsCount - 1)

            // console.log('pagination', pagination)
            // console.log(startIndex, endIndex)
            const paginatedQuestions = questions.slice(startIndex, endIndex + 1)

            return {
                ...state,
                isFetching: false,
                questions: paginatedQuestions,
                questionsCount,
            }
        }

        case actions.LIST_QUESTIONS_FAIL: {
            const { count: questionsCount } = action.payload
            return {
                ...state,
                isFetching: false,
                questions: [],
                questionsCount,
            }
        }

        case actions.CLEAR_ALL_USER_BOOKMARKS_START:
            return {
                ...state,
                isFetching: true,
            }
        case actions.CLEAR_ALL_USER_BOOKMARKS_SUCCESS:
            return {
                ...state,
                isFetching: false,
                userBookmarks: [],
            }
        case actions.CLEAR_ALL_USER_BOOKMARKS_FAIL:
            return {
                ...state,
                isFetching: false,
            }

        case actions.LIST_USER_BOOKMARKS_START:
            return {
                ...state,
                userBookmarks: [],
            }

        case actions.LIST_USER_BOOKMARKS_SUCCESS: {
            const { data: userBookmarks } = action.payload
            return {
                ...state,
                userBookmarks,
            }
        }
        case actions.LIST_USER_SECTION_BOOKMARKS_SUCCESS: {
            const { data: userSectionBookmarks } = action.payload
            return {
                ...state,
                userSectionBookmarks,
            }
        }
        case actions.LIST_USER_SECTION_BOOKMARKS_FAIL: {
            return {
                ...state,
                userSectionBookmarks: [],
            }
        }

        case actions.LIST_USER_BOOKMARKS_FAIL:
            return {
                ...state,
                userBookmarks: [],
            }

        case actions.REMOVE_USER_BOOKMARK_START:
        case actions.ADD_USER_BOOKMARK_START: {
            return {
                ...state,
                isBookmarking: true,
            }
        }

        case actions.TOGGLE_SECTION_BOOKMARK_START: {
            return {
                ...state,
                isSectionBookmarking: true,
            }
        }

        case actions.TOGGLE_SECTION_BOOKMARK_FAIL:
        case actions.TOGGLE_SECTION_BOOKMARK_SUCCESS: {
            return {
                ...state,
                isSectionBookmarking: false,
            }
        }

        case actions.REMOVE_USER_BOOKMARK_SUCCESS:
        case actions.ADD_USER_BOOKMARK_SUCCESS:
            return {
                ...state,
                isBookmarking: false,
            }

        case actions.REMOVE_USER_BOOKMARK_FAIL:
        case actions.ADD_USER_BOOKMARK_FAIL:
            return {
                ...state,
                isBookmarking: false,
            }

        case actions.LIST_FILTERED_QUESTIONS_START:
        case actions.LIST_BOOKMARKED_QUESTIONS_START:
        case actions.GET_QUESTION_START:
        case actions.DELETE_SECTION_START:
        case actions.ADD_SECTION_START:
        case actions.EDIT_SECTION_START:
        case actions.EDIT_QUESTION_START:
        case actions.DELETE_QUESTION_START:
            return {
                ...state,
                isFetching: true,
            }

        case actions.DELETE_SECTION_SUCCESS:
        case actions.ADD_SECTION_SUCCESS:
        case actions.EDIT_SECTION_SUCCESS:
        case actions.EDIT_QUESTION_SUCCESS: {
            return {
                ...state,
                isFetching: false,
            }
        }

        case actions.DELETE_QUESTION_SUCCESS: {
            return {
                ...state,
                isFetching: false,
                addedQuestionId: null,
            }
        }

        case actions.LIST_FILTERED_QUESTIONS_FAIL:
        case actions.LIST_BOOKMARKED_QUESTIONS_FAIL:
        case actions.GET_QUESTION_FAIL:
        case actions.DELETE_SECTION_FAIL:
        case actions.ADD_SECTION_FAIL:
        case actions.EDIT_SECTION_FAIL:
        case actions.EDIT_QUESTION_FAIL:
        case actions.DELETE_QUESTION_FAIL:
            return {
                ...state,
                isFetching: false,
            }

        case actions.ADD_QUESTION_START:
            return {
                ...state,
                isAdding: true,
                addedQuestionId: null,
            }

        case actions.ADD_QUESTION_SUCCESS: {
            const {
                data: { _id },
            } = action.payload
            return {
                ...state,
                isAdding: false,
                addedQuestionId: _id,
            }
        }

        case actions.REMOVE_ADDED_QUESTION_ID:
        case actions.ADD_QUESTION_FAIL:
            return {
                ...state,
                isAdding: false,
                addedQuestionId: null,
                questions: [],
                questionsCount: 0,
            }

        case actions.FILTER_QUESTION_BY_LNG_START:
            return {
                ...state,
                isFetching: true,
            }

        // case actions.FILTER_QUESTION_BY_LNG_SUCCESS:
        //     return {
        //         ...state,
        //         isFetching: false,
        //     }

        case actions.FILTER_QUESTION_BY_LNG_FAIL:
            return {
                ...state,
                isFetching: false,
            }

        default:
            return state
    }
}
