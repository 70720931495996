import * as actions from './exam.details.action.types'

export const fetchExam = (...data) => ({
    type: actions.FETCH_EXAM_START,
    payload: data,
})

export const fetchExamQuestions = (...data) => ({
    type: actions.FETCH_EXAM_QUESTION_START,
    payload: data,
})

export const submitExam = (...data) => ({
    type: actions.SUBMIT_EXAM_START,
    payload: data,
})

export const deleteSubmission = (...data) => ({
    type: actions.DELETE_EXAM_SUBMISSION_START,
    payload: data,
})

export const updateExamSubmitStatus = (status) => ({
    type: actions.UPDATE_EXAM_SUBMIT_STATUS,
    payload: status,
})

export const listSubmittedExams = (...data) => ({
    type: actions.LIST_SUBMITTED_EXAMS_START,
    payload: data,
})

export const clearSubmissions = () => ({
    type: actions.CLEAR_PERSISTED_SUBMISSIONS,
})

export const sortExamDesc = (type) => ({
    type: actions.SORT_SUBMISSIONS,
    payload: type,
})

export const setSubmissionScore = (...data) => ({
    type: actions.SET_SUBMISSION_SCORE_START,
    payload: data,
})

export const clearSubmissionScore = (...data) => ({
    type: actions.CLEAR_SUBMISSION_SCORE_START,
    payload: data,
})

export const addExamSubmissionComment = (...data) => ({
    type: actions.ADD_SUBMISSION_COMMENT_START,
    payload: data,
})

export const deleteExamSubmissionComment = (...data) => ({
    type: actions.DELETE_SUBMISSION_COMMENT_START,
    payload: data,
})

export const updateExamExpiryStatus = (...data) => ({
    type: actions.UPDATE_EXAM_EXPIRY_START,
    payload: data,
})
