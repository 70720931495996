/* eslint-disable no-underscore-dangle */
import { createSelector } from 'reselect'

const fetchStatusSelector = (state) => state.pages.tags.isFetching
export const getFetchStatus = createSelector(fetchStatusSelector, (status) => status)

const getAvailableTagsSelector = (state) => state.persistedData.tags
export const getAvailableTags = createSelector(getAvailableTagsSelector, (tags) => tags)

const getAvailableTagNamesSelector = (state) => state.persistedData.tagNames
export const getAvailableTagNames = createSelector(
    getAvailableTagNamesSelector,
    (tagNames) => tagNames
)

// const getAvailableTagsSelector = (state) => state.pages.tags.tags
// export const getAvailableTags = createSelector(getAvailableTagsSelector, (tags) => tags)

const getAddedStatusSelector = (state) => state.pages.tags.added
export const getAddedStatus = createSelector(getAddedStatusSelector, (status) => status)

const getTagsCountSelector = (state) => state.pages.tags.count
export const getTagsCount = createSelector(getTagsCountSelector, (count) => count)

const getQTagsSelector = (state) => state.pages.tags.questionTitlesForDupTags
export const getQuestionTitlesStatus = createSelector(
    getQTagsSelector,
    (questionTitles) => questionTitles
)
