import { combineReducers } from 'redux'
import { routerReducer } from 'react-router-redux'
import pagesRootReducers from '../pages/pages.reducers'
import pagesPersistedData from '../pages/pages.persisted.reducer'

export default combineReducers({
    router: routerReducer,
    pages: pagesRootReducers,
    persistedData: pagesPersistedData,
})
