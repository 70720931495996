/* eslint-disable no-underscore-dangle */
export const transformData = (data) => {
    const result = {}
    const { data: users } = data
    result.count = data.count

    const list = users.reduce((res, user) => {
        res.push({
            id: user._id,
            key: user._id,
            username: user.username,
            unique_id: user.unique_system_id,
            role: user.role,
            code: user.web_code,
        })
        return res
    }, [])

    result.users = list
    return result
}
