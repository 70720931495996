/* eslint-disable no-underscore-dangle */
export const transformData = (data) => {
    const result = {}
    const { data: users } = data
    result.count = data.count

    const list = users.reduce((res, user) => {
        res.push({
            id: user._id,
            key: user._id,
            username: user.username,
            email: user.email,
            role: user.role,
            code: user.signup_confirmation_code,
            lastLogin: user.last_login,
        })
        return res
    }, [])

    result.users = list
    return result
}
