/* eslint no-unused-vars: "off" */
import { put, call, fork, takeLatest } from 'redux-saga/effects'
import toastr from 'toastr'
import * as actionTypes from './exam.details.action.types'
import { USER_LOGOUT } from '../../Auth/login/engine/login.action.types'
import api from '../../../services/api/exams.api'
import { TOASTR_OPTIONS } from '../../../consts'
import {
    transformData,
    transformExamQuestions,
} from '../../../services/transform/exam.res.transform'

/**
 * EXAM DETAILS SAGAS
 * @type Core
 * @author Ehsan
 * @version 0.1.1
 */

Object.assign(toastr.options, TOASTR_OPTIONS)

export function* fetchExamDetailsRequest(action) {
    const [accessToken, userId, examId, persistedCodingLanguages] = action.payload
    try {
        const response = yield call(api.exams.getExam, action.payload)
        if (response && response.status === 200) {
            const transformedData = transformData(response.data, persistedCodingLanguages)
            yield put({
                type: actionTypes.FETCH_EXAM_SUCCESS,
                payload: transformedData,
            })
            toastr['success']('Exam successfully fetched.')
        } else {
            throw response
        }
    } catch (e) {
        const delay = (ms) => new Promise((res) => setTimeout(res, ms))
        yield put({
            type: actionTypes.FETCH_EXAM_FAIL,
            payload: e,
        })
        let message
        let status
        if (e.response) {
            message = e.response.data.message
            status = e.response.data.status
        }
        if (status === 401) {
            toastr['error'](message, 'Authentication Error')
            yield put({
                type: actionTypes.FETCH_EXAM_FAIL,
                payload: message,
            })
            yield delay(3000)
            yield put({
                type: USER_LOGOUT,
            })
        } else {
            toastr['error'](message)
        }
    }
}

export function* fetchExamQuestionsDetailsRequest(action) {
    try {
        const response = yield call(api.exams.getExamQuestions, action.payload)
        if (response && response.status === 200) {
            const transformedData = transformExamQuestions(response.data)
            yield put({
                type: actionTypes.FETCH_EXAM_QUESTION_SUCCESS,
                payload: transformedData,
            })
        } else {
            throw response
        }
    } catch (e) {
        const delay = (ms) => new Promise((res) => setTimeout(res, ms))
        yield put({
            type: actionTypes.FETCH_EXAM_QUESTION_FAIL,
            payload: e,
        })
        let message
        let status
        if (e.response) {
            message = e.response.data.message
            status = e.response.data.status
        }
        if (status === 401) {
            toastr['error'](message, 'Authentication Error')
            yield put({
                type: actionTypes.FETCH_EXAM_QUESTION_FAIL,
                payload: message,
            })
            yield delay(3000)
            yield put({
                type: USER_LOGOUT,
            })
        } else {
            toastr['error'](message)
        }
    }
}

export function* submitExamRequest(action) {
    try {
        const response = yield call(api.exams.submitExam, action.payload)
        if (response && response.status === 200) {
            yield put({
                type: actionTypes.SUBMIT_EXAM_SUCCESS,
                payload: response.data,
            })
            toastr['success']('Exam successfully submitted.')
        } else {
            throw response
        }
    } catch (e) {
        const delay = (ms) => new Promise((res) => setTimeout(res, ms))
        yield put({
            type: actionTypes.SUBMIT_EXAM_FAIL,
            payload: e,
        })
        let message
        let status
        if (e.response) {
            message = e.response.data.message
            status = e.response.data.status
        }
        if (status === 401) {
            toastr['error'](message, 'Authentication Error')
            yield put({
                type: actionTypes.SUBMIT_EXAM_FAIL,
                payload: message,
            })
            yield delay(3000)
            yield put({
                type: USER_LOGOUT,
            })
        } else {
            toastr['error'](message)
        }
    }
}

export function* listSubmittedExamsRequest(action) {
    try {
        const [accessToken, examId, sortType] = action.payload
        const response = yield call(api.exams.listSubmittedExams, action.payload)
        if (response && response.status === 200) {
            yield put({
                type: actionTypes.LIST_SUBMITTED_EXAMS_SUCCESS,
                payload: { submissions: response.data, sortType },
            })

            if (response.data.count === 0) {
                toastr['error']('No submission found for this exam!')
            } else {
                toastr['success']('Submissions successfully listed.')
            }
        } else {
            throw response
        }
    } catch (e) {
        const delay = (ms) => new Promise((res) => setTimeout(res, ms))
        yield put({
            type: actionTypes.LIST_SUBMITTED_EXAMS_FAIL,
            payload: e,
        })
        let message
        let status
        if (e.response) {
            message = e.response.data.message
            status = e.response.data.status
        }
        if (status === 401) {
            toastr['error'](message, 'Authentication Error')
            yield put({
                type: actionTypes.LIST_SUBMITTED_EXAMS_FAIL,
                payload: message,
            })
            yield delay(3000)
            yield put({
                type: USER_LOGOUT,
            })
        } else {
            toastr['error'](message)
        }
    }
}

export function* setSubmissionScoreRequest(action) {
    try {
        const response = yield call(api.exams.setSubmissionScore, action.payload)
        if (response && response.status === 200) {
            yield put({
                type: actionTypes.SET_SUBMISSION_SCORE_SUCCESS,
                payload: response.data,
            })

            toastr['success']('Question successfully marked.')
        } else {
            throw response
        }
    } catch (e) {
        const delay = (ms) => new Promise((res) => setTimeout(res, ms))
        yield put({
            type: actionTypes.SET_SUBMISSION_SCORE_FAIL,
            payload: e,
        })
        let message
        let status
        if (e.response) {
            message = e.response.data.message
            status = e.response.data.status
        }
        if (status === 401) {
            toastr['error'](message, 'Authentication Error')
            yield put({
                type: actionTypes.SET_SUBMISSION_SCORE_FAIL,
                payload: message,
            })
            yield delay(3000)
            yield put({
                type: USER_LOGOUT,
            })
        } else {
            toastr['error'](message)
        }
    }
}

export function* clearSubmissionScoreRequest(action) {
    try {
        const response = yield call(api.exams.clearSubmissionScore, action.payload)
        if (response && response.status === 200) {
            yield put({
                type: actionTypes.CLEAR_SUBMISSION_SCORE_SUCCESS,
                payload: response.data,
            })

            toastr['success']('Score successfully removed.')
        } else {
            throw response
        }
    } catch (e) {
        const delay = (ms) => new Promise((res) => setTimeout(res, ms))
        yield put({
            type: actionTypes.CLEAR_SUBMISSION_SCORE_FAIL,
            payload: e,
        })
        let message
        let status
        if (e.response) {
            message = e.response.data.message
            status = e.response.data.status
        }
        if (status === 401) {
            toastr['error'](message, 'Authentication Error')
            yield put({
                type: actionTypes.CLEAR_SUBMISSION_SCORE_FAIL,
                payload: message,
            })
            yield delay(3000)
            yield put({
                type: USER_LOGOUT,
            })
        } else {
            toastr['error'](message)
        }
    }
}

export function* addSubmissionCommentRequest(action) {
    try {
        const [
            accessToken,
            commentedBy,
            submissionId,
            questionId,
            comment,
            commentCode,
        ] = action.payload
        const response = yield call(api.exams.addSubmissionComment, action.payload)
        if (response && response.status === 200) {
            yield put({
                type: actionTypes.ADD_SUBMISSION_COMMENT_SUCCESS,
                payload: { res: response.data, submissionId },
            })

            toastr['success']('Comment successfully added.')
        } else {
            throw response
        }
    } catch (e) {
        const delay = (ms) => new Promise((res) => setTimeout(res, ms))
        yield put({
            type: actionTypes.ADD_SUBMISSION_COMMENT_FAIL,
            payload: e,
        })
        let message
        let status
        if (e.response) {
            message = e.response.data.message
            status = e.response.data.status
        }
        if (status === 401) {
            toastr['error'](message, 'Authentication Error')
            yield put({
                type: actionTypes.ADD_SUBMISSION_COMMENT_FAIL,
                payload: message,
            })
            yield delay(3000)
            yield put({
                type: USER_LOGOUT,
            })
        } else {
            toastr['error'](message)
        }
    }
}

export function* deleteSubmissionCommentRequest(action) {
    try {
        const [accessToken, submissionId, commentId] = action.payload
        const response = yield call(api.exams.deleteSubmissionComment, action.payload)
        if (response && response.status === 200) {
            yield put({
                type: actionTypes.DELETE_SUBMISSION_COMMENT_SUCCESS,
                payload: { res: response.data, submissionId },
            })

            toastr['success']('Comment successfully removed.')
        } else {
            throw response
        }
    } catch (e) {
        const delay = (ms) => new Promise((res) => setTimeout(res, ms))
        yield put({
            type: actionTypes.DELETE_SUBMISSION_COMMENT_FAIL,
            payload: e,
        })
        let message
        let status
        if (e.response) {
            message = e.response.data.message
            status = e.response.data.status
        }
        if (status === 401) {
            toastr['error'](message, 'Authentication Error')
            yield put({
                type: actionTypes.DELETE_SUBMISSION_COMMENT_FAIL,
                payload: message,
            })
            yield delay(3000)
            yield put({
                type: USER_LOGOUT,
            })
        } else {
            toastr['error'](message)
        }
    }
}

export function* deleteExamSubmissionRequest(action) {
    const delay = (ms) => new Promise((res) => setTimeout(res, ms))
    try {
        const [
            accessToken,
            signInUserId,
            submissionId,
            candidateId,
            examId,
            sortType,
        ] = action.payload
        const response = yield call(api.exams.deleteExamSubmission, action.payload)
        if (response && response.status === 200) {
            yield put({
                type: actionTypes.DELETE_EXAM_SUBMISSION_SUCCESS,
            })
            delay(1000)
            yield put({
                type: actionTypes.LIST_SUBMITTED_EXAMS_START,
                payload: [accessToken, examId, sortType],
            })
            toastr['success']('Submission successfully removed.')
        } else {
            throw response
        }
    } catch (e) {
        yield put({
            type: actionTypes.DELETE_EXAM_SUBMISSION_FAIL,
            payload: e,
        })
        let message
        let status
        if (e.response) {
            message = e.response.data.message
            status = e.response.data.status
        }
        if (status === 401) {
            toastr['error'](message, 'Authentication Error')
            yield put({
                type: actionTypes.DELETE_EXAM_SUBMISSION_FAIL,
                payload: message,
            })
            yield delay(3000)
            yield put({
                type: USER_LOGOUT,
            })
        } else {
            toastr['error'](message)
        }
    }
}

export function* updateExamExpiryRequest(action) {
    const delay = (ms) => new Promise((res) => setTimeout(res, ms))
    try {
        const response = yield call(api.exams.updateExamExpiry, action.payload)
        if (response && response.status === 200) {
            yield put({
                type: actionTypes.UPDATE_EXAM_EXPIRY_SUCCESS,
            })
            toastr['info']('Exam expired.')
        } else {
            throw response
        }
    } catch (e) {
        yield put({
            type: actionTypes.UPDATE_EXAM_EXPIRY_FAIL,
            payload: e,
        })
        let message
        let status
        if (e.response) {
            message = e.response.data.message
            status = e.response.data.status
        }
        if (status === 401) {
            toastr['error'](message, 'Authentication Error')
            yield put({
                type: actionTypes.UPDATE_EXAM_EXPIRY_FAIL,
                payload: message,
            })
            yield delay(3000)
            yield put({
                type: USER_LOGOUT,
            })
        } else {
            toastr['error'](message)
        }
    }
}

// a watcher to listen to our request ‘start’ action
function* watchUserLoginRequest() {
    yield takeLatest(actionTypes.FETCH_EXAM_START, fetchExamDetailsRequest)
    yield takeLatest(actionTypes.FETCH_EXAM_QUESTION_START, fetchExamQuestionsDetailsRequest)
    yield takeLatest(actionTypes.SUBMIT_EXAM_START, submitExamRequest)
    yield takeLatest(actionTypes.LIST_SUBMITTED_EXAMS_START, listSubmittedExamsRequest)
    yield takeLatest(actionTypes.SET_SUBMISSION_SCORE_START, setSubmissionScoreRequest)
    yield takeLatest(actionTypes.CLEAR_SUBMISSION_SCORE_START, clearSubmissionScoreRequest)
    yield takeLatest(actionTypes.ADD_SUBMISSION_COMMENT_START, addSubmissionCommentRequest)
    yield takeLatest(actionTypes.DELETE_SUBMISSION_COMMENT_START, deleteSubmissionCommentRequest)
    yield takeLatest(actionTypes.DELETE_EXAM_SUBMISSION_START, deleteExamSubmissionRequest)
    yield takeLatest(actionTypes.UPDATE_EXAM_EXPIRY_START, updateExamExpiryRequest)
}

// use 'fork' to start multiple sagas in the background
// they are always attached to thier parent
export default [fork(watchUserLoginRequest)]
