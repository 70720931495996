/* eslint-disable import/no-cycle */
import loginSaga from './Auth/login/engine/login.sagas'
import userSaga from './Users/engine/user.sagas'
import userFeedbackSaga from './UserFeedback/engine/feedback.sagas'
import questionsSaga from './Questions/engine/questions.sagas'
import tagsSaga from './Tags/engine/tags.sagas'
import testsSaga from './TestCentre/engine/test.centre.sagas'
import examDetailsSaga from './ExamDetails/engine/exam.details.sagas'

const pagesSaga = [
    ...loginSaga,
    ...userSaga,
    ...userFeedbackSaga,
    ...questionsSaga,
    ...tagsSaga,
    ...testsSaga,
    ...examDetailsSaga,
]

export default pagesSaga
