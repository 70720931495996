/* eslint-disable no-underscore-dangle */
import * as actions from './test.centre.action.types'
import { sortByDate } from '../../../utils'

const initialState = {
    isFetching: false,
    exams: [
        // {
        //     created_at: '2020-08-30T03:53:36.076Z',
        //     estimatedTime: 0,
        //     examDesc: 'salam',
        //     examTitle: 'v3 with de',
        //     examType: 'Randomized',
        //     languages: ['5ef03044b9aa11acb0920126', '5ef0304db9aa11acb0920127'],
        //     levelAdvanced: true,
        //     levelBasic: true,
        //     levelIntermediate: true,
        //     questions: [],
        //     questionsCount: 5,
        //     tags: [
        //         'cookie',
        //         'misc',
        //         'server-side rendering',
        //         'xxxx',
        //         'xxxx',
        //         'xxxx',
        //         'xxxx',
        //         'xxxx',
        //         'xxxx',
        //         'xxxx',
        //         'xxxx',
        //         'xxxx',
        //         'xxxx',
        //         'xxxx',
        //     ],
        //     tagsCount: 3,
        //     user_id: '5e95efbfe387c2a7bac7ec57',
        //     __v: 0,
        //     _id: '5f4b22c0548d8099004f0789',
        //     stats: [
        //         {
        //             name: 'Javascript',
        //             advanced: 0,
        //             basics: 39,
        //             intermediate: 6,
        //         },
        //         {
        //             name: 'ReactJs',
        //             advanced: 2,
        //             basics: 3,
        //             intermediate: 5,
        //         },
        //     ],
        // },
    ],
    filteredExams: [],
    examsCount: 0,
    isBookmarking: false,
    bookmarks: [],
    bookmarkIds: [],
    validateExamQuestions: null,
}

export default (state = initialState, action) => {
    switch (action.type) {
        case actions.LIST_USER_EXAM_BOOKMARKS_START:
        case actions.GENERATE_EXAM_START:
        case actions.LIST_TESTS_START:
        case actions.DELETE_EXAM_START:
            return {
                ...state,
                isFetching: true,
            }

        case actions.VALIDATE_EXAM_QUESTIONS_START:
            return {
                ...state,
                validateExamQuestions: null,
            }

        case actions.LIST_USER_EXAM_BOOKMARKS_SUCCESS: {
            const {
                data: { bookmarks },
                sortType,
            } = action.payload

            return {
                ...state,
                isFetching: false,
                bookmarks: sortByDate(bookmarks, 'created_at', sortType),
                examsCount: bookmarks.length,
            }
        }

        case actions.DELETE_EXAM_SUCCESS:
        case actions.GENERATE_EXAM_SUCCESS:
            return {
                ...state,
                isFetching: false,
            }

        case actions.VALIDATE_EXAM_QUESTIONS_SUCCESS:
            return {
                ...state,
                validateExamQuestions: action.payload,
            }
        case actions.LIST_TESTS_SUCCESS: {
            const {
                data: { exams: userExams, bookmarks, bookmarkIds },
                count: examsCount,
                sortType,
            } = action.payload

            return {
                ...state,
                isFetching: false,
                exams: sortByDate(userExams, 'created_at', sortType),
                examsCount,
                bookmarks,
                bookmarkIds,
            }
        }

        case actions.LIST_USER_EXAM_BOOKMARKS_FAIL:
        case actions.GENERATE_EXAM_FAIL:
        case actions.LIST_TESTS_FAIL:
        case actions.DELETE_EXAM_FAIL:
            return {
                ...state,
                isFetching: false,
            }

        case actions.VALIDATE_EXAM_QUESTIONS_FAIL:
            return {
                ...state,
                validateExamQuestions: null,
            }

        case actions.SORT_EXAM_ASC: {
            const { exams: data, shouldLoadBookmarks } = action.payload
            if (!shouldLoadBookmarks) {
                return {
                    ...state,
                    exams: sortByDate(data, 'created_at', 'asc'),
                    bookmarks: sortByDate(state.bookmarks, 'created_at', 'asc'),
                }
            }

            return {
                ...state,
                bookmarks: sortByDate(data, 'created_at', 'asc'),
                exmas: sortByDate(state.exams, 'created_at', 'asc'),
            }
        }
        case actions.SORT_EXAM_DESC: {
            const { exams: data, shouldLoadBookmarks } = action.payload

            if (!shouldLoadBookmarks) {
                return {
                    ...state,
                    exams: sortByDate(data, 'created_at', 'desc'),
                    bookmarks: sortByDate(state.bookmarks, 'created_at', 'desc'),
                }
            }

            return {
                ...state,
                bookmarks: sortByDate(data, 'created_at', 'desc'),
                exams: sortByDate(state.exams, 'created_at', 'desc'),
            }
        }
        case actions.SEARCH_EXAM_START: {
            // eslint-disable-next-line no-case-declarations
            const [searchValue, sortType, exams] = action.payload
            const filteredExams = exams.reduce((res, curr) => {
                const title = curr.examTitle
                const desc = curr.examDesc
                if ((desc && desc.includes(searchValue)) || title.includes(searchValue))
                    res.push(curr)
                return res
            }, [])

            return {
                ...state,
                filteredExams:
                    filteredExams.length > 0
                        ? sortByDate(filteredExams, 'created_at', sortType)
                        : [],
                examsCount: filteredExams.length,
            }
        }

        case actions.UPDATE_EXAMS_COUNT: {
            return {
                ...state,
                examsCount: action.payload,
            }
        }

        case actions.REMOVE_USER_EXAM_BOOKMARK_START:
        case actions.ADD_USER_EXAM_BOOKMARK_START:
            return {
                ...state,
                isBookmarking: true,
            }

        case actions.REMOVE_USER_EXAM_BOOKMARK_SUCCESS:
        case actions.ADD_USER_EXAM_BOOKMARK_SUCCESS: {
            const {
                data: { bookmarks, bookmarkIds },
            } = action.payload.data
            const { shouldLoadBookmarks, sortType } = action.payload

            return {
                ...state,
                isBookmarking: false,
                bookmarks: sortByDate(bookmarks, 'created_at', sortType),
                bookmarkIds,
                examsCount: shouldLoadBookmarks ? bookmarks.length : state.examsCount,
            }
        }

        case actions.REMOVE_USER_EXAM_BOOKMARK_FAIL:
        case actions.ADD_USER_EXAM_BOOKMARK_FAIL:
            return {
                ...state,
                isBookmarking: false,
            }
        default:
            return state
    }
}
