/* eslint no-unused-vars: "off" */
import axios from 'axios'

/**
 * TAGS APIs
 * this file contains all tags realted APIs
 * @type Service
 * @author Ehsan
 * @version 0.1.0
 * @param {array} credentials
 * @param {string} token
 */

const environment = process.env.NODE_ENV
let ENDPOINT
if (environment === 'development') {
    ENDPOINT = process.env.REACT_APP_DEV_API_ENDPOINT
} else {
    ENDPOINT = process.env.REACT_APP_PROD_API_ENDPOINT
}

export default {
    tags: {
        list: (data) => {
            const [accessToken] = data
            const url = `${ENDPOINT}/tags/`
            return axios({
                method: 'get',
                url,
                headers: {
                    Authorization: `Bearer ${accessToken}`,
                },
            })
        },
        add: (data) => {
            const [name, accessToken] = data
            const url = `${ENDPOINT}/tags/`
            return axios({
                method: 'post',
                url,
                data: {
                    name,
                },
                headers: {
                    Authorization: `Bearer ${accessToken}`,
                },
            })
        },
        delete: (data) => {
            const [id, accessToken] = data
            const url = `${ENDPOINT}/tags/`
            return axios({
                method: 'delete',
                url,
                data: {
                    tag_id: id,
                },
                headers: {
                    Authorization: `Bearer ${accessToken}`,
                },
            })
        },
    },
}
