import * as actions from './exam.details.action.types'
import { CLEAR_EXAM_QUESTIONS } from '../../TestCentre/engine/test.centre.action.types'

const initialState = {
    isFetching: false,
    exam: null,
    examQuestions: null,
    examIsSubmitting: false,
    examIsSubmitted: false,
}

export default (state = initialState, action) => {
    switch (action.type) {
        case actions.DELETE_EXAM_SUBMISSION_START:
        case actions.DELETE_SUBMISSION_COMMENT_START:
        case actions.ADD_SUBMISSION_COMMENT_START:
        case actions.SET_SUBMISSION_SCORE_START:
        case actions.CLEAR_SUBMISSION_SCORE_START:
        case actions.FETCH_EXAM_QUESTION_START:
        case actions.UPDATE_EXAM_EXPIRY_START:
        case actions.FETCH_EXAM_START:
            return {
                ...state,
                isFetching: true,
            }
        case actions.FETCH_EXAM_SUCCESS: {
            return {
                ...state,
                isFetching: false,
                exam: action.payload,
            }
        }

        case actions.FETCH_EXAM_FAIL:
            return {
                ...state,
                isFetching: false,
            }
        case actions.FETCH_EXAM_QUESTION_FAIL:
            return {
                ...state,
                isFetching: false,
                examQuestions: null,
            }
        case actions.FETCH_EXAM_QUESTION_SUCCESS:
            return {
                ...state,
                isFetching: false,
                examQuestions: action.payload,
            }
        case CLEAR_EXAM_QUESTIONS:
            return {
                ...state,
                examQuestions: null,
            }
        case actions.SUBMIT_EXAM_START:
            return {
                ...state,
                examIsSubmitting: true,
                examIsSubmitted: false,
            }
        case actions.SUBMIT_EXAM_SUCCESS:
            return {
                ...state,
                examIsSubmitting: false,
                examIsSubmitted: true,
            }
        case actions.SUBMIT_EXAM_FAIL:
            return {
                ...state,
                examIsSubmitting: false,
                examIsSubmitted: false,
            }
        case actions.UPDATE_EXAM_SUBMIT_STATUS:
            return {
                ...state,
                examIsSubmitting: action.payload,
            }
        case actions.LIST_SUBMITTED_EXAMS_START:
            return {
                ...state,
                isFetching: true,
            }
        case actions.LIST_SUBMITTED_EXAMS_SUCCESS:
            return {
                ...state,
                isFetching: false,
            }
        case actions.LIST_SUBMITTED_EXAMS_FAIL:
            return {
                ...state,
                isFetching: false,
            }
        case actions.SET_SUBMISSION_SCORE_SUCCESS:
        case actions.CLEAR_SUBMISSION_SCORE_SUCCESS:
            return {
                ...state,
                isFetching: false,
            }

        case actions.DELETE_EXAM_SUBMISSION_SUCCESS:
        case actions.DELETE_SUBMISSION_COMMENT_SUCCESS:
        case actions.UPDATE_EXAM_EXPIRY_SUCCESS:
        case actions.ADD_SUBMISSION_COMMENT_SUCCESS: {
            return {
                ...state,
                isFetching: false,
            }
        }

        case actions.DELETE_EXAM_SUBMISSION_FAIL:
        case actions.DELETE_SUBMISSION_COMMENT_FAIL:
        case actions.ADD_SUBMISSION_COMMENT_FAIL:
        case actions.SET_SUBMISSION_SCORE_FAIL:
        case actions.UPDATE_EXAM_EXPIRY_FAIL:
        case actions.CLEAR_SUBMISSION_SCORE_FAIL:
            return {
                ...state,
                isFetching: false,
            }

        default:
            return state
    }
}
