// import React from 'react'
// import { Button, Icon } from 'semantic-ui-react'
// import styles from './CoreConst.module.css'

export const TOASTR_OPTIONS = {
    closeButton: false,
    debug: false,
    newestOnTop: false,
    progressBar: true,
    positionClass: 'toast-bottom-right',
    preventDuplicates: false,
    onclick: null,
    showDuration: '300',
    hideDuration: '1000',
    timeOut: '3000',
    extendedTimeOut: '1000',
    showEasing: 'swing',
    hideEasing: 'linear',
    showMethod: 'fadeIn',
    hideMethod: 'fadeOut',
}

export const TOASTR_OPTIONS_EXAMS = {
    closeButton: false,
    debug: false,
    newestOnTop: false,
    progressBar: true,
    positionClass: 'toast-bottom-right',
    preventDuplicates: false,
    onclick: null,
    showDuration: '300',
    hideDuration: '2000',
    timeOut: '7000',
    extendedTimeOut: '1000',
    showEasing: 'swing',
    hideEasing: 'linear',
    showMethod: 'fadeIn',
    hideMethod: 'fadeOut',
}

export const SEARCH_OPTIONS = [
    // { key: 'all', text: 'All', value: 'all', icon: 'globe' },
    {
        key: 'title',
        text: 'Title',
        value: 'title',
        icon: 'question',
    },
    {
        key: 'answer',
        text: 'Answer',
        value: 'answer',
        icon: 'lightbulb',
    },
    {
        key: 'titleCode',
        text: 'Code',
        value: 'titleCode',
        icon: 'code',
    },
]

export const PAGE_SIZE_OPTIONS = [
    { key: 1, text: '5', value: 5 },
    { key: 2, text: '10', value: 10 },
    { key: 3, text: '15', value: 15 },
    // { key: 4, text: '50', value: 20 },
]

export const DIFFICULTY_OPTIONS = [
    { key: 1, text: 'basics', value: 'basics' },
    { key: 2, text: 'intermediate', value: 'intermediate' },
    { key: 3, text: 'advanced', value: 'advanced' },
]

export const LANGUAGE_OPTIONS = (data) => {
    if (data) {
        const options = data.reduce((res, curr) => {
            const opt = {
                key: curr.key,
                text: curr.name,
                // eslint-disable-next-line no-underscore-dangle
                value: curr._id,
            }
            res.push(opt)
            return res
        }, [])

        return options
    }

    return []
}

export const FEEDBACK_OPTIONS = [
    { key: 1, text: 'Feedback', value: 'Feedback' },
    { key: 2, text: 'Report a bug', value: 'BugReport' },
]

export const CODE_LANGUAGE_OPTIONS = [
    { key: 1, text: 'javascript', value: 'javascript' },
    { key: 2, text: 'html', value: 'html' },
    { key: 3, text: 'css', value: 'css' },
    { key: 4, text: 'sass', value: 'sass' },
    { key: 5, text: 'jsx', value: 'jsx' },
    { key: 6, text: 'json', value: 'json' },
    { key: 7, text: 'bash', value: 'bash' },
    { key: 8, text: 'graphql', value: 'graphql' },
    { key: 9, text: 'docker', value: 'docker' },
    { key: 10, text: 'git', value: 'git' },
    { key: 11, text: 'markdown', value: 'markdown' },
    { key: 12, text: 'regex', value: 'regex' },
    { key: 13, text: 'yaml', value: 'yaml' },
]
