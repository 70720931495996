/* eslint no-unused-vars: "off" */
import { put, call, fork, takeLatest } from 'redux-saga/effects'
import toastr from 'toastr'
import * as questionTypes from './questions.action.types'
import { USER_LOGOUT } from '../../Auth/login/engine/login.action.types'
import api from '../../../services/api/questions.api'
import { TOASTR_OPTIONS } from '../../../consts'
import { transformData } from '../../../services/transform/list.questions.res.transform'
import { transformData as transformDataStats } from '../../../services/transform/list.stats.res.transform'

/**
 * QUESTION SAGAS
 * this file contains all related user sagas and its associated async calls
 * @type Core
 * @author Ehsan
 * @version 0.1.1
 */

Object.assign(toastr.options, TOASTR_OPTIONS)

export function* listQuestionsRequest(action) {
    try {
        const response = yield call(api.questions.list, action.payload)
        if (response && response.status === 200) {
            const transformedData = yield call(transformData, response.data)
            // console.log('tranform', transformedData)
            if (transformedData) {
                yield put({
                    type: questionTypes.LIST_QUESTIONS_SUCCESS,
                    payload: transformedData,
                })
                // toastr['success']('Users successfully fetched.')
            }
        } else {
            throw response
        }
    } catch (e) {
        const delay = (ms) => new Promise((res) => setTimeout(res, ms))
        yield put({
            type: questionTypes.LIST_QUESTIONS_FAIL,
            payload: e,
        })
        let message
        let status
        if (e.response) {
            message = e.response.data.message
            status = e.response.data.status
        }
        if (status === 401) {
            toastr['error'](message, 'Authentication Error')
            yield put({
                type: questionTypes.LIST_QUESTIONS_FAIL,
                payload: message,
            })
            yield delay(3000)
            yield put({
                type: USER_LOGOUT,
            })
        } else {
            toastr['error'](message, 'List questions failed!')
        }
    }
}

export function* listFilteredQuestionsRequest(action) {
    try {
        const response = yield call(api.questions.filter, action.payload)
        if (response && response.status === 200) {
            const transformedData = yield call(transformData, response.data)
            // console.log('tranform', transformedData)
            if (transformedData) {
                yield put({
                    type: questionTypes.LIST_FILTERED_QUESTIONS_SUCCESS,
                    payload: transformedData,
                })
                // toastr['success']('Users successfully fetched.')
            }
        } else {
            throw response
        }
    } catch (e) {
        const delay = (ms) => new Promise((res) => setTimeout(res, ms))
        yield put({
            type: questionTypes.LIST_FILTERED_QUESTIONS_FAIL,
            payload: e,
        })
        const [limit, page, accessToken] = action.payload
        yield put({
            type: questionTypes.LIST_QUESTIONS_START,
            payload: [limit, 1, accessToken],
        })
        let message
        let status
        if (e.response) {
            message = e.response.data.message
            status = e.response.data.status
        }
        if (status === 401) {
            toastr['error'](message, 'Authentication Error')
            yield put({
                type: questionTypes.LIST_FILTERED_QUESTIONS_FAIL,
                payload: message,
            })
            yield delay(3000)
            yield put({
                type: USER_LOGOUT,
            })
        } else {
            toastr['error']('No Question Found!')
        }
    }
}

export function* searchFilteredQuestionsRequest(action) {
    try {
        const response = yield call(api.questions.searchFilter, action.payload)
        if (response && response.status === 200) {
            const transformedData = yield call(transformData, response.data)
            if (transformedData) {
                const [limit, page, accessToken, value, category, filters] = action.payload
                yield put({
                    type: questionTypes.SEARCH_FILTERED_QUESTION_SUCCESS,
                    payload: { ...transformedData, pagination: { limit, page } },
                })
                // toastr['success']('Users successfully fetched.')
            }
        } else {
            throw response
        }
    } catch (e) {
        const delay = (ms) => new Promise((res) => setTimeout(res, ms))
        yield put({
            type: questionTypes.SEARCH_FILTERED_QUESTION_FAIL,
            payload: e,
        })
        const [limit, page, accessToken] = action.payload
        yield put({
            type: questionTypes.LIST_QUESTIONS_START,
            payload: [limit, 1, accessToken],
        })
        let message
        let status
        if (e.response) {
            message = e.response.data.message
            status = e.response.data.status
        }
        if (status === 401) {
            toastr['error'](message, 'Authentication Error')
            yield put({
                type: questionTypes.SEARCH_FILTERED_QUESTION_FAIL,
                payload: message,
            })
            yield delay(3000)
            yield put({
                type: USER_LOGOUT,
            })
        } else {
            toastr['error']('No Question Found!')
        }
    }
}

export function* searchQuestionsRequest(action) {
    try {
        // const [limit, page, accessToken, value, category] = action.payload
        const response = yield call(api.questions.search, action.payload)
        // console.log('Category:', value, category)
        if (response && response.status === 200) {
            const transformedData = yield call(transformData, response.data)
            // console.log('tranform', transformedData)
            if (transformedData) {
                const [limit, page] = action.payload
                yield put({
                    type: questionTypes.SEARCH_QUESTION_SUCCESS,
                    payload: { ...transformedData, pagination: { limit, page } },
                })
                // toastr['success']('Users successfully fetched.')
            }
        } else {
            throw response
        }
    } catch (e) {
        const delay = (ms) => new Promise((res) => setTimeout(res, ms))
        yield put({
            type: questionTypes.SEARCH_QUESTION_FAIL,
            payload: e,
        })
        let message
        let status
        if (e.response) {
            message = e.response.data.message
            status = e.response.data.status
        }
        if (status === 401) {
            toastr['error'](message, 'Authentication Error')
            yield put({
                type: questionTypes.SEARCH_QUESTION_FAIL,
                payload: message,
            })
            yield delay(3000)
            yield put({
                type: USER_LOGOUT,
            })
        } else {
            toastr['error'](message, 'Search questions failed!')
        }
    }
}

export function* listUserBookmarksRequest(action) {
    try {
        const [data, token, limit, activePage] = action.payload
        const response = yield call(api.questions.listBookmarkQuestions, action.payload)
        if (response && response.status === 200) {
            const transformedData = yield call(transformData, response.data)
            // console.log('tranform', transformedData)
            if (transformedData) {
                yield put({
                    type: questionTypes.LIST_BOOKMARKED_QUESTIONS_SUCCESS,
                    payload: {
                        ...transformedData,
                        pagination: {
                            limit,
                            page: activePage,
                        },
                    },
                })
                // toastr['success']('Users successfully fetched.')
            }
        } else {
            throw response
        }
    } catch (e) {
        const delay = (ms) => new Promise((res) => setTimeout(res, ms))
        yield put({
            type: questionTypes.LIST_BOOKMARKED_QUESTIONS_FAIL,
            payload: e,
        })
        let message
        let status
        if (e.response) {
            message = e.response.data.message
            status = e.response.data.status
        }
        if (status === 401) {
            toastr['error'](message, 'Authentication Error')
            yield put({
                type: questionTypes.LIST_BOOKMARKED_QUESTIONS_FAIL,
                payload: message,
            })
            yield delay(3000)
            yield put({
                type: USER_LOGOUT,
            })
        } else {
            toastr['error'](message, 'List bookmarked questions failed!')
        }
    }
}

export function* searchBookmarkedQuestionsRequest(action) {
    try {
        const [data, token, limit, activePage, value, category] = action.payload
        // console.log('CATE:', value, category)
        const response = yield call(api.questions.searchookmarkQuestions, action.payload)
        if (response && response.status === 200) {
            const transformedData = yield call(transformData, response.data)
            // console.log('tranform', transformedData)
            if (transformedData) {
                yield put({
                    type: questionTypes.SEARCH_BOOKMARK_QUESTION_SUCCESS,
                    payload: {
                        ...transformedData,
                        pagination: {
                            limit,
                            page: activePage,
                        },
                    },
                })
                // toastr['success']('Users successfully fetched.')
            }
        } else {
            throw response
        }
    } catch (e) {
        const delay = (ms) => new Promise((res) => setTimeout(res, ms))
        yield put({
            type: questionTypes.SEARCH_BOOKMARK_QUESTION_FAIL,
            payload: e,
        })
        let message
        let status
        if (e.response) {
            message = e.response.data.message
            status = e.response.data.status
        }
        if (status === 401) {
            toastr['error'](message, 'Authentication Error')
            yield put({
                type: questionTypes.SEARCH_BOOKMARK_QUESTION_FAIL,
                payload: message,
            })
            yield delay(3000)
            yield put({
                type: USER_LOGOUT,
            })
        } else {
            toastr['error'](message, 'Search bookmarked questions failed!')
        }
    }
}

export function* listBookmarkedFilteredQuestionsRequest(action) {
    try {
        const [data, token, limit, activePage, value, category] = action.payload
        const response = yield call(api.questions.searchBookmarkFilteredQuestions, action.payload)
        if (response && response.status === 200) {
            const transformedData = yield call(transformData, response.data)
            // console.log('tranform', transformedData)
            if (transformedData) {
                yield put({
                    type: questionTypes.LIST_BOOKMARK_FILTERED_QUESTION_SUCCESS,
                    payload: {
                        ...transformedData,
                        pagination: {
                            limit,
                            page: activePage,
                        },
                    },
                })
                // toastr['success']('Users successfully fetched.')
            }
        } else {
            throw response
        }
    } catch (e) {
        const delay = (ms) => new Promise((res) => setTimeout(res, ms))
        yield put({
            type: questionTypes.LIST_BOOKMARK_FILTERED_QUESTION_FAIL,
            payload: e,
        })
        let message
        let status
        if (e.response) {
            message = e.response.data.message
            status = e.response.data.status
        }
        if (status === 401) {
            toastr['error'](message, 'Authentication Error')
            yield put({
                type: questionTypes.LIST_BOOKMARK_FILTERED_QUESTION_FAIL,
                payload: message,
            })
            yield delay(3000)
            yield put({
                type: USER_LOGOUT,
            })
        } else {
            toastr['error'](message, 'List filtered bookmarked questions failed!')
        }
    }
}

export function* editQuestionRequest(action) {
    try {
        const [
            questionData,
            token,
            limit,
            oldActivePage,
            isFromCreate,
            signInUserId,
            shouldLoadBookmarks,
            searchValue,
            selectedSearchCategory,
            filters,
            quickFilter,
        ] = action.payload
        const response = yield call(api.questions.edit, [questionData, token])
        if (response && response.status === 200) {
            yield put({
                type: questionTypes.EDIT_QUESTION_SUCCESS,
                payload: response.data,
            })
            if (!isFromCreate) {
                if (!quickFilter) {
                    if (!searchValue) {
                        if (!shouldLoadBookmarks && !filters) {
                            yield put({
                                type: questionTypes.LIST_QUESTIONS_START,
                                payload: [limit, oldActivePage, token],
                            })
                        }

                        if (shouldLoadBookmarks && !filters) {
                            yield put({
                                type: questionTypes.LIST_BOOKMARKED_QUESTIONS_START,
                                payload: [
                                    {
                                        user_id: signInUserId,
                                    },
                                    token,
                                    limit,
                                    oldActivePage,
                                ],
                            })
                        }

                        if (!shouldLoadBookmarks && filters) {
                            yield put({
                                type: questionTypes.LIST_FILTERED_QUESTIONS_START,
                                payload: [limit, oldActivePage, token, filters],
                            })
                        }

                        if (shouldLoadBookmarks && filters) {
                            yield put({
                                type: questionTypes.LIST_BOOKMARK_FILTERED_QUESTION_START,
                                payload: [
                                    {
                                        user_id: signInUserId,
                                        ...filters,
                                    },
                                    token,
                                    limit,
                                    oldActivePage,
                                    '',
                                    '',
                                ],
                            })
                        }
                    }

                    if (searchValue) {
                        if (!filters) {
                            yield put({
                                type: questionTypes.SEARCH_QUESTION_START,
                                payload: [
                                    limit,
                                    oldActivePage,
                                    token,
                                    searchValue,
                                    selectedSearchCategory,
                                ],
                            })
                        } else {
                            yield put({
                                type: questionTypes.LIST_BOOKMARK_FILTERED_QUESTION_START,
                                payload: [
                                    {
                                        user_id: signInUserId,
                                        ...filters,
                                    },
                                    token,
                                    limit,
                                    oldActivePage,
                                    searchValue,
                                    selectedSearchCategory,
                                ],
                            })
                        }
                    }
                }

                if (quickFilter) {
                    // A
                    // !searchValue
                    // !shouldLoadBookmarks
                    if (!searchValue && !shouldLoadBookmarks) {
                        yield put({
                            type: questionTypes.FILTER_QUESTION_BY_LNG_START,
                            payload: [limit, oldActivePage, quickFilter, token],
                        })
                    }

                    // B
                    // !searchValue
                    // shouldLoadBookmarks
                    if (!searchValue && shouldLoadBookmarks) {
                        yield put({
                            type: questionTypes.FILTER_QUESTION_BY_LNG_START,
                            payload: [limit, oldActivePage, quickFilter, token, true, signInUserId],
                        })
                    }

                    // C
                    // searchValue
                    // !shouldLoadBookmarks
                    if (searchValue && !shouldLoadBookmarks) {
                        yield put({
                            type: questionTypes.SEARCH_FILTER_QUESTION_BY_LNG_START,
                            payload: [
                                limit,
                                oldActivePage,
                                quickFilter,
                                token,
                                searchValue,
                                selectedSearchCategory,
                            ],
                        })
                    }

                    // D
                    // searchValue
                    // shouldLoadBookmarks
                    if (searchValue && shouldLoadBookmarks) {
                        yield put({
                            type: questionTypes.SEARCH_FILTER_QUESTION_BY_LNG_START,
                            payload: [
                                limit,
                                oldActivePage,
                                quickFilter,
                                token,
                                searchValue,
                                selectedSearchCategory,
                                true,
                                signInUserId,
                            ],
                        })
                    }
                }
            } else {
                yield put({
                    type: questionTypes.GET_QUESTION_START,
                    // eslint-disable-next-line no-underscore-dangle
                    payload: [1, 1, questionData.question_id, token], // (1, 1, addedQuestionId, token)
                })
            }
            toastr['success']('Question successfully edited.')
        } else {
            throw response
        }
    } catch (e) {
        const delay = (ms) => new Promise((res) => setTimeout(res, ms))
        yield put({
            type: questionTypes.EDIT_QUESTION_FAIL,
            payload: e,
        })
        let message
        let status
        if (e.response) {
            message = e.response.data.message
            status = e.response.data.status
        }
        if (status === 401) {
            toastr['error'](message, 'Authentication Error')
            yield put({
                type: questionTypes.EDIT_QUESTION_FAIL,
                payload: message,
            })
            yield delay(3000)
            yield put({
                type: USER_LOGOUT,
            })
        } else {
            toastr['error'](message, 'Edit question failed!')
        }
    }
}

export function* addQuestionRequest(action) {
    try {
        const [data, accessToken] = action.payload
        const response = yield call(api.questions.add, action.payload)
        if (response && response.status === 201) {
            // yield put({
            //     type: questionTypes.LIST_QUESTIONS_START,
            //     payload: [1, 1, accessToken],
            // })
            yield put({
                type: questionTypes.ADD_QUESTION_SUCCESS,
                payload: response.data,
            })
            toastr['success']('Question successfully added.')
        } else {
            throw response
        }
    } catch (e) {
        const delay = (ms) => new Promise((res) => setTimeout(res, ms))
        yield put({
            type: questionTypes.ADD_QUESTION_FAIL,
            payload: e,
        })
        let message
        let status
        if (e.response) {
            message = e.response.data.message
            status = e.response.data.status
        }
        if (status === 401) {
            toastr['error'](message, 'Authentication Error')
            yield put({
                type: questionTypes.ADD_QUESTION_FAIL,
                payload: message,
            })
            yield delay(3000)
            yield put({
                type: USER_LOGOUT,
            })
        } else {
            toastr['error'](message, 'Add question failed!')
        }
    }
}

export function* getQuestionRequest(action) {
    try {
        const [limit, page, id, token] = action.payload

        const response = yield call(api.questions.getById, action.payload)
        const fetchedQuestion = {
            data: [{ ...response.data.data }],
        }
        if (response && response.status === 200) {
            const transformedData = yield call(transformData, fetchedQuestion)

            if (transformedData) {
                yield put({
                    type: questionTypes.GET_QUESTION_SUCCESS,
                    payload: transformedData,
                })
            }
            // toastr['success']('Question successfully fetched.')
        } else {
            throw response
        }
    } catch (e) {
        const delay = (ms) => new Promise((res) => setTimeout(res, ms))
        yield put({
            type: questionTypes.GET_QUESTION_FAIL,
            payload: e,
        })
        let message
        let status
        if (e.response) {
            message = e.response.data.message
            status = e.response.data.status
        }
        if (status === 401) {
            toastr['error'](message, 'Authentication Error')
            yield put({
                type: questionTypes.GET_QUESTION_FAIL,
                payload: message,
            })
            yield delay(3000)
            yield put({
                type: USER_LOGOUT,
            })
        } else {
            toastr['error'](message, 'Fetch question failed!')
        }
    }
}

export function* deleteQuestionRequest(action) {
    try {
        const [
            questionId,
            token,
            limit,
            activePage,
            isFromCreate,
            signInUserId,
            shouldLoadBookmarks,
            questionsCount, // questions count before delete!
            searchValue,
            selectedSearchCategory,
            quickFilter,
        ] = action.payload
        const response = yield call(api.questions.delete, action.payload)
        if (response && response.status === 200) {
            yield put({
                type: questionTypes.DELETE_QUESTION_SUCCESS,
                payload: response.data,
            })

            if (!isFromCreate) {
                if (!quickFilter) {
                    //
                    if (!shouldLoadBookmarks) {
                        yield put({
                            type: questionTypes.LIST_QUESTIONS_START,
                            payload: [limit, activePage, token],
                        })
                    } else if (shouldLoadBookmarks && questionsCount >= 2) {
                        yield put({
                            type: questionTypes.LIST_BOOKMARKED_QUESTIONS_START,
                            payload: [
                                {
                                    user_id: signInUserId,
                                },
                                token,
                                limit,
                                activePage,
                            ],
                        })
                    }

                    // there is only one question remains and it's going to be deleted
                    // after this action total will be 0
                    if (questionsCount === 1 && shouldLoadBookmarks) {
                        yield put({
                            type: questionTypes.CLEAR_ALL_USER_BOOKMARKS_START,
                            payload: [{ userId: signInUserId }, token, limit],
                        })
                        yield put({
                            type: questionTypes.LIST_QUESTIONS_START,
                            payload: [limit, activePage, token],
                        })
                    }

                    if (searchValue) {
                        yield put({
                            type: questionTypes.SEARCH_QUESTION_START,
                            payload: [
                                limit,
                                activePage,
                                token,
                                searchValue,
                                selectedSearchCategory,
                            ],
                        })
                    }
                }

                if (quickFilter) {
                    // A
                    // !searchValue
                    // !shouldLoadBookmarks
                    if (!searchValue && !shouldLoadBookmarks) {
                        yield put({
                            type: questionTypes.FILTER_QUESTION_BY_LNG_START,
                            payload: [limit, activePage, quickFilter, token],
                        })
                    }

                    // B
                    // !searchValue
                    // shouldLoadBookmarks
                    if (!searchValue && shouldLoadBookmarks) {
                        yield put({
                            type: questionTypes.FILTER_QUESTION_BY_LNG_START,
                            payload: [limit, activePage, quickFilter, token, true, signInUserId],
                        })
                    }

                    // C
                    // searchValue
                    // !shouldLoadBookmarks
                    if (searchValue && !shouldLoadBookmarks) {
                        yield put({
                            type: questionTypes.SEARCH_FILTER_QUESTION_BY_LNG_START,
                            payload: [
                                limit,
                                activePage,
                                quickFilter,
                                token,
                                searchValue,
                                selectedSearchCategory,
                            ],
                        })
                    }

                    // D
                    // searchValue
                    // shouldLoadBookmarks
                    if (searchValue && shouldLoadBookmarks) {
                        yield put({
                            type: questionTypes.SEARCH_FILTER_QUESTION_BY_LNG_START,
                            payload: [
                                limit,
                                activePage,
                                quickFilter,
                                token,
                                searchValue,
                                selectedSearchCategory,
                                true,
                                signInUserId,
                            ],
                        })
                    }
                }
            } else {
                yield put({
                    type: questionTypes.REMOVE_ADDED_QUESTION_ID,
                })
            }

            toastr['success']('Question successfully deleted.')
        } else {
            throw response
        }
    } catch (e) {
        const delay = (ms) => new Promise((res) => setTimeout(res, ms))
        yield put({
            type: questionTypes.DELETE_QUESTION_FAIL,
            payload: e,
        })
        let message
        let status
        if (e.response) {
            message = e.response.data.message
            status = e.response.data.status
        }
        if (status === 401) {
            toastr['error'](message, 'Authentication Error')
            yield put({
                type: questionTypes.DELETE_QUESTION_FAIL,
                payload: message,
            })
            yield delay(3000)
            yield put({
                type: USER_LOGOUT,
            })
        } else {
            toastr['error'](message, 'Delete question failed!')
        }
    }
}

export function* editSectionRequest(action) {
    try {
        const [
            data,
            token,
            limit,
            oldActivePage,
            isFromCreate,
            signInUserId,
            shouldLoadBookmarks,
            searchValue,
            selectedSearchCategory,
            filters,
            quickFilter,
        ] = action.payload
        const response = yield call(api.sections.edit, action.payload)
        if (response && response.status === 200) {
            yield put({
                type: questionTypes.EDIT_SECTION_SUCCESS,
                payload: response.data,
            })

            if (!isFromCreate) {
                if (!quickFilter) {
                    if (!shouldLoadBookmarks && !filters) {
                        yield put({
                            type: questionTypes.LIST_QUESTIONS_START,
                            payload: [limit, oldActivePage, token],
                        })
                    }

                    if (!shouldLoadBookmarks && filters) {
                        yield put({
                            type: questionTypes.LIST_FILTERED_QUESTIONS_START,
                            payload: [limit, oldActivePage, token, filters],
                        })
                    }

                    if (shouldLoadBookmarks && !filters) {
                        yield put({
                            type: questionTypes.LIST_BOOKMARKED_QUESTIONS_START,
                            payload: [
                                {
                                    user_id: signInUserId,
                                },
                                token,
                                limit,
                                oldActivePage,
                            ],
                        })
                    }

                    if (shouldLoadBookmarks && filters) {
                        yield put({
                            type: questionTypes.LIST_BOOKMARK_FILTERED_QUESTION_START,
                            payload: [
                                {
                                    user_id: signInUserId,
                                    ...filters,
                                },
                                token,
                                limit,
                                oldActivePage,
                                '',
                                '',
                            ],
                        })
                    }

                    if (searchValue) {
                        yield put({
                            type: questionTypes.SEARCH_QUESTION_START,
                            payload: [
                                limit,
                                oldActivePage,
                                token,
                                searchValue,
                                selectedSearchCategory,
                            ],
                        })
                    }
                }

                if (quickFilter) {
                    // A
                    // !searchValue
                    // !shouldLoadBookmarks
                    if (!searchValue && !shouldLoadBookmarks) {
                        yield put({
                            type: questionTypes.FILTER_QUESTION_BY_LNG_START,
                            payload: [limit, oldActivePage, quickFilter, token],
                        })
                    }

                    // B
                    // !searchValue
                    // shouldLoadBookmarks
                    if (!searchValue && shouldLoadBookmarks) {
                        yield put({
                            type: questionTypes.FILTER_QUESTION_BY_LNG_START,
                            payload: [limit, oldActivePage, quickFilter, token, true, signInUserId],
                        })
                    }

                    // C
                    // searchValue
                    // !shouldLoadBookmarks
                    if (searchValue && !shouldLoadBookmarks) {
                        yield put({
                            type: questionTypes.SEARCH_FILTER_QUESTION_BY_LNG_START,
                            payload: [
                                limit,
                                oldActivePage,
                                quickFilter,
                                token,
                                searchValue,
                                selectedSearchCategory,
                            ],
                        })
                    }

                    // D
                    // searchValue
                    // shouldLoadBookmarks
                    if (searchValue && shouldLoadBookmarks) {
                        yield put({
                            type: questionTypes.SEARCH_FILTER_QUESTION_BY_LNG_START,
                            payload: [
                                limit,
                                oldActivePage,
                                quickFilter,
                                token,
                                searchValue,
                                selectedSearchCategory,
                                true,
                                signInUserId,
                            ],
                        })
                    }
                }
            } else {
                yield put({
                    type: questionTypes.GET_QUESTION_START,
                    payload: [1, 1, data.question_id, token], // (1, 1, addedQuestionId, token)
                })
            }
            toastr['success']('Section successfully edited.')
        } else {
            throw response
        }
    } catch (e) {
        const delay = (ms) => new Promise((res) => setTimeout(res, ms))
        yield put({
            type: questionTypes.EDIT_SECTION_FAIL,
            payload: e,
        })
        let message
        let status
        if (e.response) {
            message = e.response.data.message
            status = e.response.data.status
        }
        if (status === 401) {
            toastr['error'](message, 'Authentication Error')
            yield put({
                type: questionTypes.EDIT_SECTION_FAIL,
                payload: message,
            })
            yield delay(3000)
            yield put({
                type: USER_LOGOUT,
            })
        } else {
            toastr['error'](message, 'Edit section failed!')
        }
    }
}

export function* addSectionRequest(action) {
    try {
        const [
            data,
            token,
            limit,
            oldActivePage,
            isFromCreate,
            signInUserId,
            shouldLoadBookmarks,
            searchValue,
            selectedSearchCategory,
            filters,
            quickFilter,
        ] = action.payload
        const response = yield call(api.sections.add, action.payload)
        if (response && response.status === 201) {
            yield put({
                type: questionTypes.ADD_SECTION_SUCCESS,
                payload: response.data,
            })
            if (!isFromCreate) {
                if (!quickFilter) {
                    if (!shouldLoadBookmarks && !filters) {
                        yield put({
                            type: questionTypes.LIST_QUESTIONS_START,
                            payload: [limit, oldActivePage, token],
                        })
                    }

                    if (!shouldLoadBookmarks && filters) {
                        yield put({
                            type: questionTypes.LIST_FILTERED_QUESTIONS_START,
                            payload: [limit, oldActivePage, token, filters],
                        })
                    }

                    if (shouldLoadBookmarks && !filters) {
                        yield put({
                            type: questionTypes.LIST_BOOKMARKED_QUESTIONS_START,
                            payload: [
                                {
                                    user_id: signInUserId,
                                },
                                token,
                                limit,
                                oldActivePage,
                            ],
                        })
                    }

                    if (shouldLoadBookmarks && filters) {
                        yield put({
                            type: questionTypes.LIST_BOOKMARK_FILTERED_QUESTION_START,
                            payload: [
                                {
                                    user_id: signInUserId,
                                    ...filters,
                                },
                                token,
                                limit,
                                oldActivePage,
                                '',
                                '',
                            ],
                        })
                    }

                    if (searchValue) {
                        yield put({
                            type: questionTypes.SEARCH_QUESTION_START,
                            payload: [
                                limit,
                                oldActivePage,
                                token,
                                searchValue,
                                selectedSearchCategory,
                            ],
                        })
                    }
                }

                if (quickFilter) {
                    // A
                    // !searchValue
                    // !shouldLoadBookmarks
                    if (!searchValue && !shouldLoadBookmarks) {
                        yield put({
                            type: questionTypes.FILTER_QUESTION_BY_LNG_START,
                            payload: [limit, oldActivePage, quickFilter, token],
                        })
                    }

                    // B
                    // !searchValue
                    // shouldLoadBookmarks
                    if (!searchValue && shouldLoadBookmarks) {
                        yield put({
                            type: questionTypes.FILTER_QUESTION_BY_LNG_START,
                            payload: [limit, oldActivePage, quickFilter, token, true, signInUserId],
                        })
                    }

                    // C
                    // searchValue
                    // !shouldLoadBookmarks
                    if (searchValue && !shouldLoadBookmarks) {
                        yield put({
                            type: questionTypes.SEARCH_FILTER_QUESTION_BY_LNG_START,
                            payload: [
                                limit,
                                oldActivePage,
                                quickFilter,
                                token,
                                searchValue,
                                selectedSearchCategory,
                            ],
                        })
                    }

                    // D
                    // searchValue
                    // shouldLoadBookmarks
                    if (searchValue && shouldLoadBookmarks) {
                        yield put({
                            type: questionTypes.SEARCH_FILTER_QUESTION_BY_LNG_START,
                            payload: [
                                limit,
                                oldActivePage,
                                quickFilter,
                                token,
                                searchValue,
                                selectedSearchCategory,
                                true,
                                signInUserId,
                            ],
                        })
                    }
                }
            } else {
                yield put({
                    type: questionTypes.GET_QUESTION_START,
                    payload: [1, 1, data.question_id, token], // (1, 1, addedQuestionId, token)
                })
            }
            toastr['success']('Section successfully added.')
        } else {
            throw response
        }
    } catch (e) {
        const delay = (ms) => new Promise((res) => setTimeout(res, ms))
        yield put({
            type: questionTypes.ADD_SECTION_FAIL,
            payload: e,
        })
        let message
        let status
        if (e.response) {
            message = e.response.data.message
            status = e.response.data.status
        }
        if (status === 401) {
            toastr['error'](message, 'Authentication Error')
            yield put({
                type: questionTypes.ADD_SECTION_FAIL,
                payload: message,
            })
            yield delay(3000)
            yield put({
                type: USER_LOGOUT,
            })
        } else {
            toastr['error'](message, 'Add section failed!')
        }
    }
}

export function* deleteSectionRequest(action) {
    try {
        const [
            data,
            token,
            limit,
            oldActivePage,
            isFromCreate,
            signInUserId,
            shouldLoadBookmarks,
            searchValue,
            selectedSearchCategory,
            quickFilter,
        ] = action.payload
        const response = yield call(api.sections.delete, action.payload)
        if (response && response.status === 200) {
            yield put({
                type: questionTypes.DELETE_SECTION_SUCCESS,
                payload: response.data,
            })
            if (!isFromCreate) {
                if (!quickFilter) {
                    if (!shouldLoadBookmarks) {
                        yield put({
                            type: questionTypes.LIST_QUESTIONS_START,
                            payload: [limit, oldActivePage, token],
                        })
                    } else {
                        yield put({
                            type: questionTypes.LIST_BOOKMARKED_QUESTIONS_START,
                            payload: [
                                {
                                    user_id: signInUserId,
                                },
                                token,
                                limit,
                                oldActivePage,
                            ],
                        })
                    }
                    if (searchValue) {
                        yield put({
                            type: questionTypes.SEARCH_QUESTION_START,
                            payload: [
                                limit,
                                oldActivePage,
                                token,
                                searchValue,
                                selectedSearchCategory,
                            ],
                        })
                    }
                }

                if (quickFilter) {
                    // A
                    // !searchValue
                    // !shouldLoadBookmarks
                    if (!searchValue && !shouldLoadBookmarks) {
                        yield put({
                            type: questionTypes.FILTER_QUESTION_BY_LNG_START,
                            payload: [limit, oldActivePage, quickFilter, token],
                        })
                    }

                    // B
                    // !searchValue
                    // shouldLoadBookmarks
                    if (!searchValue && shouldLoadBookmarks) {
                        yield put({
                            type: questionTypes.FILTER_QUESTION_BY_LNG_START,
                            payload: [limit, oldActivePage, quickFilter, token, true, signInUserId],
                        })
                    }

                    // C
                    // searchValue
                    // !shouldLoadBookmarks
                    if (searchValue && !shouldLoadBookmarks) {
                        yield put({
                            type: questionTypes.SEARCH_FILTER_QUESTION_BY_LNG_START,
                            payload: [
                                limit,
                                oldActivePage,
                                quickFilter,
                                token,
                                searchValue,
                                selectedSearchCategory,
                            ],
                        })
                    }

                    // D
                    // searchValue
                    // shouldLoadBookmarks
                    if (searchValue && shouldLoadBookmarks) {
                        yield put({
                            type: questionTypes.SEARCH_FILTER_QUESTION_BY_LNG_START,
                            payload: [
                                limit,
                                oldActivePage,
                                quickFilter,
                                token,
                                searchValue,
                                selectedSearchCategory,
                                true,
                                signInUserId,
                            ],
                        })
                    }
                }
            } else {
                yield put({
                    type: questionTypes.GET_QUESTION_START,
                    payload: [1, 1, data.question_id, token], // (1, 1, addedQuestionId, token)
                })
            }
            toastr['success']('Section successfully deleted.')
        } else {
            throw response
        }
    } catch (e) {
        const delay = (ms) => new Promise((res) => setTimeout(res, ms))
        yield put({
            type: questionTypes.DELETE_SECTION_FAIL,
            payload: e,
        })
        let message
        let status
        if (e.response) {
            message = e.response.data.message
            status = e.response.data.status
        }
        if (status === 401) {
            toastr['error'](message, 'Authentication Error')
            yield put({
                type: questionTypes.DELETE_SECTION_FAIL,
                payload: message,
            })
            yield delay(3000)
            yield put({
                type: USER_LOGOUT,
            })
        } else {
            toastr['error'](message, 'Delete section failed!')
        }
    }
}

export function* getUserBookmarksRequest(action) {
    try {
        const response = yield call(api.questions.getBookmarks, action.payload)
        if (response && response.status === 200) {
            yield put({
                type: questionTypes.LIST_USER_BOOKMARKS_SUCCESS,
                payload: response.data,
            })

            // toastr['success']('Bookmarks successfully fetched.')
        } else {
            throw response
        }
    } catch (e) {
        const delay = (ms) => new Promise((res) => setTimeout(res, ms))
        yield put({
            type: questionTypes.LIST_USER_BOOKMARKS_FAIL,
            payload: e,
        })
        let message
        let status
        if (e.response) {
            message = e.response.data.message
            status = e.response.data.status
        }
        if (status === 401) {
            toastr['error'](message, 'Authentication Error')
            yield put({
                type: questionTypes.LIST_USER_BOOKMARKS_FAIL,
                payload: message,
            })
            yield delay(3000)
            yield put({
                type: USER_LOGOUT,
            })
        } else {
            toastr['error'](message, 'List bookmarks failed!')
        }
    }
}

export function* listUserSectionBookmarksRequest(action) {
    try {
        const response = yield call(api.questions.getSectionBookmarks, action.payload)
        if (response && response.status === 200) {
            yield put({
                type: questionTypes.LIST_USER_SECTION_BOOKMARKS_SUCCESS,
                payload: response.data,
            })

            // toastr['success']('Bookmarks successfully fetched.')
        } else {
            throw response
        }
    } catch (e) {
        const delay = (ms) => new Promise((res) => setTimeout(res, ms))
        yield put({
            type: questionTypes.LIST_USER_SECTION_BOOKMARKS_FAIL,
            payload: e,
        })
        let message
        let status
        if (e.response) {
            message = e.response.data.message
            status = e.response.data.status
        }
        if (status === 401) {
            toastr['error'](message, 'Authentication Error')
            yield put({
                type: questionTypes.LIST_USER_SECTION_BOOKMARKS_FAIL,
                payload: message,
            })
            yield delay(3000)
            yield put({
                type: USER_LOGOUT,
            })
        } else {
            toastr['error'](message, 'List section bookmarks failed!')
        }
    }
}

export function* addUserBookmarkRequest(action) {
    try {
        const [data, token] = action.payload
        const response = yield call(api.questions.addBookmark, action.payload)
        if (response && response.status === 201) {
            yield put({
                type: questionTypes.ADD_USER_BOOKMARK_SUCCESS,
                payload: response.data,
            })
            yield put({
                type: questionTypes.LIST_USER_BOOKMARKS_START,
                payload: [
                    {
                        user_id: data.userId,
                    },

                    token,
                ],
            })

            toastr['success']('Question successfully bookmarked.')
        } else {
            throw response
        }
    } catch (e) {
        const delay = (ms) => new Promise((res) => setTimeout(res, ms))
        yield put({
            type: questionTypes.ADD_USER_BOOKMARK_FAIL,
            payload: e,
        })
        let message
        let status
        if (e.response) {
            message = e.response.data.message
            status = e.response.data.status
        }
        if (status === 401) {
            toastr['error'](message, 'Authentication Error')
            yield put({
                type: questionTypes.ADD_USER_BOOKMARK_FAIL,
                payload: message,
            })
            yield delay(3000)
            yield put({
                type: USER_LOGOUT,
            })
        } else {
            toastr['error'](message, 'Add bookmark failed!')
        }
    }
}

export function* toggleSectionBookmarkRequest(action) {
    try {
        const [data, token] = action.payload
        const response = yield call(api.questions.toggleSectionBookmark, action.payload)
        if (response && response.status === 200) {
            yield put({
                type: questionTypes.TOGGLE_SECTION_BOOKMARK_SUCCESS,
                payload: response.data,
            })
            yield put({
                type: questionTypes.LIST_USER_SECTION_BOOKMARKS_START,
                payload: [
                    {
                        user_id: data.userId,
                    },

                    token,
                ],
            })

            toastr['success'](response.data.message)
        } else {
            throw response
        }
    } catch (e) {
        const delay = (ms) => new Promise((res) => setTimeout(res, ms))
        yield put({
            type: questionTypes.TOGGLE_SECTION_BOOKMARK_FAIL,
            payload: e,
        })
        let message
        let status
        if (e.response) {
            message = e.response.data.message
            status = e.response.data.status
        }
        if (status === 401) {
            toastr['error'](message, 'Authentication Error')
            yield put({
                type: questionTypes.TOGGLE_SECTION_BOOKMARK_FAIL,
                payload: message,
            })
            yield delay(3000)
            yield put({
                type: USER_LOGOUT,
            })
        } else {
            toastr['error'](message, 'Section bookmark failed!')
        }
    }
}

export function* clearUserBookmarksRequest(action) {
    try {
        const [data, token] = action.payload
        const response = yield call(api.questions.clearAllBookmarks, action.payload)
        if (response && response.status === 201) {
            yield put({
                type: questionTypes.CLEAR_ALL_USER_BOOKMARKS_SUCCESS,
                payload: response.data,
            })
            yield put({
                type: questionTypes.LIST_USER_BOOKMARKS_START,
                payload: [
                    {
                        user_id: data.userId,
                    },

                    token,
                ],
            })
            toastr['success']('Bookmarks successfully cleared.')
        } else {
            throw response
        }
    } catch (e) {
        const delay = (ms) => new Promise((res) => setTimeout(res, ms))
        yield put({
            type: questionTypes.CLEAR_ALL_USER_BOOKMARKS_FAIL,
            payload: e,
        })
        let message
        let status
        if (e.response) {
            message = e.response.data.message
            status = e.response.data.status
        }
        if (status === 401) {
            toastr['error'](message, 'Authentication Error')
            yield put({
                type: questionTypes.CLEAR_ALL_USER_BOOKMARKS_FAIL,
                payload: message,
            })
            yield delay(3000)
            yield put({
                type: USER_LOGOUT,
            })
        } else {
            toastr['error'](message, 'Clear bookmarks failed!')
        }
    }
}

export function* removeUserBookmarkRequest(action) {
    try {
        const [
            data,
            token,
            limit,
            activePage,
            shouldLoadBookmarks,
            quickFilter,
            signInUserId,
        ] = action.payload
        const response = yield call(api.questions.removeBookmark, action.payload)
        if (response && response.status === 201) {
            yield put({
                type: questionTypes.REMOVE_USER_BOOKMARK_SUCCESS,
                payload: response.data,
            })

            if (!quickFilter) {
                yield put({
                    type: questionTypes.LIST_USER_BOOKMARKS_START,
                    payload: [
                        {
                            user_id: data.userId,
                        },

                        token,
                    ],
                })
            } else {
                if (shouldLoadBookmarks) {
                    yield put({
                        type: questionTypes.FILTER_QUESTION_BY_LNG_START,
                        payload: [limit, activePage, quickFilter, token, true, signInUserId],
                    })
                }
                if (!shouldLoadBookmarks) {
                    yield put({
                        type: questionTypes.FILTER_QUESTION_BY_LNG_START,
                        payload: [limit, activePage, quickFilter, token],
                    })
                    yield put({
                        type: questionTypes.LIST_USER_BOOKMARKS_START,
                        payload: [
                            {
                                user_id: data.userId,
                            },

                            token,
                        ],
                    })
                }
            }

            toastr['success']('Bookmark successfully removed.')
        } else {
            throw response
        }
    } catch (e) {
        const delay = (ms) => new Promise((res) => setTimeout(res, ms))
        yield put({
            type: questionTypes.REMOVE_USER_BOOKMARK_FAIL,
            payload: e,
        })
        let message
        let status
        if (e.response) {
            message = e.response.data.message
            status = e.response.data.status
        }
        if (status === 401) {
            toastr['error'](message, 'Authentication Error')
            yield put({
                type: questionTypes.REMOVE_USER_BOOKMARK_FAIL,
                payload: message,
            })
            yield delay(3000)
            yield put({
                type: USER_LOGOUT,
            })
        } else {
            toastr['error'](message, 'Remove bookmark failed!')
        }
    }
}

export function* listLanguagesRequest(action) {
    try {
        const response = yield call(api.questions.listLanguages, action.payload.data)
        if (response && response.status === 200) {
            yield put({
                type: questionTypes.GET_QUESTION_LANGUAGE_SUCCESS,
                payload: response.data,
            })

            // console.log('>>>>languages', response.data)
        } else {
            throw response
        }
    } catch (e) {
        const delay = (ms) => new Promise((res) => setTimeout(res, ms))
        yield put({
            type: questionTypes.GET_QUESTION_LANGUAGE_FAIL,
            payload: e,
        })
        let message
        let status
        if (e.response) {
            message = e.response.data.message
            status = e.response.data.status
        }
        if (status === 401) {
            toastr['error'](message, 'Authentication Error')
            yield put({
                type: questionTypes.GET_QUESTION_LANGUAGE_FAIL,
                payload: message,
            })
            yield delay(3000)
            yield put({
                type: USER_LOGOUT,
            })
        } else {
            toastr['error'](message, 'List available languages failed!')
        }
    }
}

export function* listQuestionsStatsRequest(action) {
    try {
        const response = yield call(api.questions.listStats, action.payload)
        if (response && response.status === 200) {
            const transformedData = yield call(transformDataStats, response.data)
            yield put({
                type: questionTypes.GET_QUESTIONS_STATS_SUCCESS,
                payload: transformedData,
            })
        } else {
            throw response
        }
    } catch (e) {
        const delay = (ms) => new Promise((res) => setTimeout(res, ms))
        yield put({
            type: questionTypes.GET_QUESTIONS_STATS_FAIL,
            payload: e,
        })
        let message
        let status
        if (e.response) {
            message = e.response.data.message
            status = e.response.data.status
        }
        if (status === 401) {
            toastr['error'](message, 'Authentication Error')
            yield put({
                type: questionTypes.GET_QUESTIONS_STATS_FAIL,
                payload: message,
            })
            yield delay(3000)
            yield put({
                type: USER_LOGOUT,
            })
        } else {
            toastr['error'](message, 'List statistics failed!')
        }
    }
}

export function* filterQuestionsByLangRequest(action) {
    const [limit, page, languageId, accessToken] = action.payload
    try {
        const response = yield call(api.questions.filterByLng, action.payload)
        if (response && response.status === 200) {
            const transformedData = yield call(transformData, response.data)
            // console.log('tranform', transformedData)
            const { showBookmarkSuccess } = response.data

            if (transformedData) {
                yield put({
                    type: questionTypes.FILTER_QUESTION_BY_LNG_SUCCESS,
                    payload: languageId,
                })

                if (!showBookmarkSuccess) {
                    yield put({
                        type: questionTypes.LIST_QUESTIONS_SUCCESS,
                        payload: transformedData,
                    })
                } else {
                    yield put({
                        type: questionTypes.FILTER_QUESTION_BY_LNG_SUCCESS_BOOKMARK,
                        payload: {
                            ...transformedData,
                            pagination: {
                                limit,
                                page,
                            },
                        },
                    })
                }
            }
        } else {
            throw response
        }
    } catch (e) {
        const delay = (ms) => new Promise((res) => setTimeout(res, ms))
        yield put({
            type: questionTypes.FILTER_QUESTION_BY_LNG_FAIL,
            payload: e,
        })
        yield put({
            type: questionTypes.LIST_QUESTIONS_START,
            payload: [limit, 1, accessToken],
        })
        let message
        let status
        if (e.response) {
            message = e.response.data.message
            status = e.response.data.status
        }
        if (status === 401) {
            toastr['error'](message, 'Authentication Error')
            yield put({
                type: questionTypes.FILTER_QUESTION_BY_LNG_FAIL,
                payload: message,
            })
            yield delay(3000)
            yield put({
                type: USER_LOGOUT,
            })
        } else {
            toastr['error'](message, 'Filter questions failed!')
        }
    }
}

// FE has access to all filtered questions for the specifc lng, so it's more performant to do the search in FE (NO API CALL!)
export function* searchFilterQuestionsByLangRequest(action) {
    try {
        const [limit, page, languageId, accessToken, searchValue] = action.payload
        const response = yield call(api.questions.searchFilterByLng, action.payload)
        if (response && response.status === 200) {
            const transformedData = yield call(transformData, response.data)
            yield put({
                type: questionTypes.FILTER_QUESTION_BY_LNG_SUCCESS,
                payload: languageId,
            })
            // yield put({
            //     type: questionTypes.LIST_QUESTIONS_SUCCESS,
            //     payload: transformedData,
            // })
            yield put({
                type: questionTypes.SEARCH_FILTER_QUESTION_BY_LNG_SUCCESS,
                payload: {
                    ...transformedData,
                    pagination: {
                        limit,
                        page,
                    },
                },
            })
        } else {
            throw response
        }
    } catch (e) {
        const delay = (ms) => new Promise((res) => setTimeout(res, ms))
        yield put({
            type: questionTypes.SEARCH_FILTER_QUESTION_BY_LNG_FAIL,
            payload: e,
        })
        let message
        let status
        if (e.response) {
            message = e.response.data.message
            status = e.response.data.status
        }
        if (status === 401) {
            toastr['error'](message, 'Authentication Error')
            yield put({
                type: questionTypes.SEARCH_FILTER_QUESTION_BY_LNG_FAIL,
                payload: message,
            })
            yield delay(3000)
            yield put({
                type: USER_LOGOUT,
            })
        } else {
            toastr['error'](message, 'Search filter questions failed!')
        }
    }
}

// a watcher to listen to our request ‘start’ action
function* watchUserLoginRequest() {
    yield takeLatest(questionTypes.LIST_QUESTIONS_START, listQuestionsRequest)
    yield takeLatest(questionTypes.LIST_FILTERED_QUESTIONS_START, listFilteredQuestionsRequest)
    yield takeLatest(questionTypes.SEARCH_QUESTION_START, searchQuestionsRequest)
    yield takeLatest(questionTypes.SEARCH_FILTERED_QUESTION_START, searchFilteredQuestionsRequest)
    yield takeLatest(questionTypes.EDIT_QUESTION_START, editQuestionRequest)
    yield takeLatest(questionTypes.DELETE_QUESTION_START, deleteQuestionRequest)
    yield takeLatest(questionTypes.EDIT_SECTION_START, editSectionRequest)
    yield takeLatest(questionTypes.ADD_SECTION_START, addSectionRequest)
    yield takeLatest(questionTypes.DELETE_SECTION_START, deleteSectionRequest)
    yield takeLatest(questionTypes.ADD_QUESTION_START, addQuestionRequest)
    yield takeLatest(questionTypes.GET_QUESTION_START, getQuestionRequest)
    yield takeLatest(questionTypes.LIST_USER_BOOKMARKS_START, getUserBookmarksRequest)
    yield takeLatest(questionTypes.ADD_USER_BOOKMARK_START, addUserBookmarkRequest)
    yield takeLatest(questionTypes.REMOVE_USER_BOOKMARK_START, removeUserBookmarkRequest)
    yield takeLatest(questionTypes.LIST_BOOKMARKED_QUESTIONS_START, listUserBookmarksRequest)
    yield takeLatest(questionTypes.CLEAR_ALL_USER_BOOKMARKS_START, clearUserBookmarksRequest)
    yield takeLatest(questionTypes.SEARCH_BOOKMARK_QUESTION_START, searchBookmarkedQuestionsRequest)
    yield takeLatest(questionTypes.TOGGLE_SECTION_BOOKMARK_START, toggleSectionBookmarkRequest)
    yield takeLatest(
        questionTypes.LIST_USER_SECTION_BOOKMARKS_START,
        listUserSectionBookmarksRequest
    )
    yield takeLatest(
        questionTypes.LIST_BOOKMARK_FILTERED_QUESTION_START,
        listBookmarkedFilteredQuestionsRequest
    )
    yield takeLatest(questionTypes.GET_QUESTION_LANGUAGE_START, listLanguagesRequest)
    yield takeLatest(questionTypes.GET_QUESTIONS_STATS_START, listQuestionsStatsRequest)
    yield takeLatest(questionTypes.FILTER_QUESTION_BY_LNG_START, filterQuestionsByLangRequest)
    yield takeLatest(
        questionTypes.SEARCH_FILTER_QUESTION_BY_LNG_START,
        searchFilterQuestionsByLangRequest
    )
}

// use 'fork' to start multiple sagas in the background
// they are always attached to thier parent
export default [fork(watchUserLoginRequest)]
