import { createSelector } from 'reselect'

// const languageSelector = (state) => state.persistedData.locale.language
// export const getLanguage = createSelector(languageSelector, (language) => language)

const persistedExamsSelector = (state) => state.persistedData.exams
export const getPersistedExams = createSelector(persistedExamsSelector, (exams) => exams)

const pathnameSelector = (state) => state.persistedData.urlPathname
export const getPersistedPathname = createSelector(pathnameSelector, (pathname) => pathname)

const toggleSelector = (state) => state.persistedData.toggle
export const getPersistedToggle = createSelector(toggleSelector, (status) => status)

const examSubmissionsSelector = (state) => state.persistedData.examSubmissions
export const getExamSubmissions = createSelector(examSubmissionsSelector, (exams) => exams)

const examSubmissionsCountSelector = (state) => state.persistedData.examSubmissionsCount
export const getExamSubmissionsCount = createSelector(
    examSubmissionsCountSelector,
    (count) => count
)

const examSubmissionsSortTypeSelector = (state) => state.persistedData.submissionsSort
export const getSubmissionsSortType = createSelector(
    examSubmissionsSortTypeSelector,
    (sortType) => sortType
)

const showLngIconsSelector = (state) => state.persistedData.showLanguageIcons
export const getShowIconsStatus = createSelector(showLngIconsSelector, (status) => status)

const quickFilterSelector = (state) => state.persistedData.quickFilter
export const getQuickFilterId = createSelector(quickFilterSelector, (id) => id)

const persistedLimitSelector = (state) => state.persistedData.limit
export const getPersistedLimit = createSelector(persistedLimitSelector, (limit) => limit)
