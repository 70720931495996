import React from 'react'
import PropTypes from 'prop-types'
import { PulseLoader } from 'react-spinners'

/**
 * Spinner
 * @type Component
 * @author Ehsan
 * @version 0.1.0
 */
const Spinner = (props) => {
    const { centerScreen, size } = props
    const centerScreenStyle = {
        position: 'absolute',
        top: `calc(50% - ${size / 2}px)`,
        left: `calc(50% - ${size / 2}px)`,
        zIndex: 10000000,
    }

    return (
        <div style={centerScreen ? centerScreenStyle : null}>
            <PulseLoader color="#dc5258" loading />
        </div>
    )
}

Spinner.defaultProps = {
    centerScreen: false,
}

Spinner.propTypes = {
    /**
     * diameter of the circle
     */
    size: PropTypes.number,
    centerScreen: PropTypes.bool,
}

Spinner.defaultProps = {
    size: 75,
}

export default Spinner
