/* eslint no-unused-vars: "off" */
import { put, call, fork, takeLatest } from 'redux-saga/effects'
import toastr from 'toastr'
import * as loginTypes from './login.action.types'
import * as tagsTypes from '../../../Tags/engine/tags.action.types'
import api from '../../../../services/api/user.api'
import { validateUsername } from '../../../../services/validation'
import { TOASTR_OPTIONS } from '../../../../consts'
import { GET_QUESTION_LANGUAGE_START } from '../../../Questions/engine/questions.action.types'

/**
 * USER SAGAS
 * this file contains all related user sagas and its associated async calls
 * @type Core
 * @author Ehsan
 * @version 0.1.1
 */

Object.assign(toastr.options, TOASTR_OPTIONS)

export function* userLoginRequest(action) {
    try {
        const [e, p, persistedCodingLanguages, persistedPathname, history] = action.data
        const response = yield call(api.user.login, action.data)

        const delay = (ms) => new Promise((res) => setTimeout(res, ms))

        if (response && response.status === 200) {
            // 'put' is similar to dispatch in thunk!
            const { token, data: user } = response.data
            yield put({
                type: loginTypes.USER_LOGIN_REQUEST_SUCCESS,
                payload: {
                    data: response.data,
                },
            })
            yield delay(750)
            toastr['success'](`Hello ${user.username}`, 'WELCOME')

            yield put({
                type: tagsTypes.LIST_TAGS_START,
                payload: [token],
            })

            if (!persistedCodingLanguages) {
                yield put({
                    type: GET_QUESTION_LANGUAGE_START,
                    payload: {
                        data: token,
                    },
                })
            }

            // add a flag to persisted store to either push to path or not (add this flag in settings page!) if (persistedPathname && flag)
            // yield delay(1500)
            if (persistedPathname) {
                history.push(persistedPathname)
            }
        } else {
            throw response
        }
    } catch (e) {
        yield put({
            type: loginTypes.USER_LOGIN_REQUEST_FAIL,
            payload: e,
        })

        let message
        let status
        if (e.response) {
            message = e.response.data.message
            status = e.response.data.status
        }

        if (status === 401) {
            toastr['error'](message, 'Authentication Error')
            yield put({
                type: loginTypes.CHECK_ACCESS_TOKEN_IS_EXISTED_FAILED,
                payload: message,
            })
        } else {
            toastr['error'](message, 'Authentication Error')
        }
    }
}

export function* userSignupRequest(action) {
    try {
        // use 'call' to get data from any async function (promise, different saga, etc)
        const response = yield call(api.user.signup, action.data)

        const delay = (ms) => new Promise((res) => setTimeout(res, ms))
        // // manually delay the stream (simulate the time that may be spent on receiving the response data from an api)

        if (response && response.status === 201) {
            // 'put' is similar to dispatch in thunk!
            yield put({
                type: loginTypes.USER_SIGNUP_REQUEST_SUCCESS,
                payload: {
                    data: response.data,
                },
            })
            // yield put({
            //     type: sharedTypes.SNACK_BAR_SHOW,
            //     payload: {
            //         snackbarMessage: 'User successfully created 🚀',
            //         snackbarType: 'success',
            //         snackbarStatus: true,
            //     },
            // })
        } else {
            throw response
        }
    } catch (e) {
        yield put({
            type: loginTypes.USER_SIGNUP_REQUEST_FAIL,
            payload: e,
        })

        let error
        let message
        let status
        if (e.response) {
            error = e.response.data.error
            message = e.response.data.message
            status = e.response.data.status
        }

        if (status === 401) {
            // yield put({
            //     type: sharedTypes.SNACK_BAR_SHOW,
            //     payload: {
            //         snackbarMessage: `🙅 ${error || message ? error || message : ''}‍`,
            //         snackbarType: 'error',
            //         snackbarStatus: true,
            //     },
            // })
            yield put({
                type: loginTypes.CHECK_ACCESS_TOKEN_IS_EXISTED_FAILED,
                payload: error,
            })
        } else {
            // yield put({
            //     type: sharedTypes.SNACK_BAR_SHOW,
            //     payload: {
            //         snackbarMessage: `Signup failed! 🥺 ${
            //             error || message ? error || message : ''
            //         }`,
            //         snackbarType: 'error',
            //         snackbarStatus: true,
            //     },
            // })
        }
    }
}

export function* adminSignupRequest(action) {
    try {
        const response = yield call(api.user.adminSignup, action.data)

        const delay = (ms) => new Promise((res) => setTimeout(res, ms))

        if (response && response.status === 201) {
            yield put({
                type: loginTypes.USER_ADMIN_SIGNUP_REQUEST_SUCCESS,
                payload: {
                    data: response.data,
                },
            })
            yield delay(1000)
            toastr['success']('user successfully signedup', 'Registration Succeeded')
        } else {
            throw response
        }
    } catch (e) {
        yield put({
            type: loginTypes.USER_ADMIN_SIGNUP_REQUEST_FAIL,
            payload: e,
        })

        let error
        let message
        let status
        if (e.response) {
            error = e.response.data.error
            message = e.response.data.message
            status = e.response.data.status
        }

        if (status === 401) {
            toastr['error'](message, 'Authentication Error')
            yield put({
                type: loginTypes.CHECK_ACCESS_TOKEN_IS_EXISTED_FAILED,
                payload: e,
            })
        } else {
            toastr['error'](error, 'Registration Failed')
        }
    }
}

export function* userLogoutRequest(action) {
    try {
        yield put({
            type: loginTypes.USER_LOGOUT_SUCCESS,
        })
        // window.location.reload(true)
    } catch (e) {
        yield put({
            type: loginTypes.USER_LOGOUT_FAIL,
            payload: e.messages,
        })
    }
}

export function* checkUsernameRequest(action) {
    try {
        if (validateUsername(action.data)) {
            const response = yield call(api.user.checkAvailability, action.data)

            if (response && response.status === 200) {
                // 'put' is similar to dispatch in thunk!
                yield put({
                    type: loginTypes.CHECK_USER_NAME_START_SUCCESS,
                    payload: {
                        data: response.data,
                    },
                })
                toastr['success'](response.data.message)
            } else {
                throw response
            }
        } else {
            toastr['error'](
                "make sure it's not more than 30 characters. Note that some special characters are prohibited!",
                'Invalid username'
            )
        }
    } catch (e) {
        yield put({
            type: loginTypes.CHECK_USER_NAME_START_FAIL,
            payload: e,
        })

        let message
        let status
        if (e.response) {
            message = e.response.data.message
            status = e.response.data.status
        }

        if (status === 401) {
            toastr['error'](message, 'Authentication Error')
            yield put({
                type: loginTypes.CHECK_USER_NAME_START_FAIL,
                payload: message,
            })
        } else {
            toastr['error'](message, 'Not Available')
        }
    }
}

export function* checkUserAuthRequest(action) {
    try {
        const response = yield call(api.user.checkUserAuth, action.token)

        if (response && response.status === 200) {
            // 'put' is similar to dispatch in thunk!
            yield put({
                type: loginTypes.CHECK_USER_IS_AUTHENTICATED_SUCCESS,
                payload: {
                    data: response.data,
                },
            })
        } else {
            throw response
        }
    } catch (e) {
        yield put({
            type: loginTypes.CHECK_USER_IS_AUTHENTICATED_FAIL,
            payload: e,
        })
        const delay = (ms) => new Promise((res) => setTimeout(res, ms))
        let message
        let status
        if (e.response) {
            message = e.response.data.message
            status = e.response.data.status
        }

        yield put({
            type: loginTypes.CHECK_USER_IS_AUTHENTICATED_FAIL,
            payload: message,
        })
        yield put({
            type: loginTypes.USER_LOGOUT,
        })
    }
}

// check for existed access token
export function* isAccessTokenExisted(action) {
    try {
        const localAccessToken = localStorage.getItem('persist:session')
        const { token } = JSON.parse(JSON.parse(localAccessToken).persistedData)
        const stateToken = action.token

        const hasValidToken = token === stateToken
        if (localAccessToken && token && hasValidToken) {
            yield put({
                type: loginTypes.CHECK_ACCESS_TOKEN_IS_EXISTED_TRUE,
            })
        } else {
            yield put({
                type: loginTypes.CHECK_ACCESS_TOKEN_IS_EXISTED_FALSE,
            })
            // yield put({
            //     type: sharedTypes.SNACK_BAR_SHOW,
            //     payload: {
            //         snackbarMessage: 'Unauthorized user! 🙅‍',
            //         snackbarType: 'error',
            //         snackbarStatus: true,
            //     },
            // })
            // TODO: add logout user here!!!!
        }
    } catch (e) {
        yield put({
            type: loginTypes.CHECK_ACCESS_TOKEN_IS_EXISTED_FAILED,
            payload: e.messages,
        })
    }
}

// a watcher to listen to our request ‘start’ action
function* watchUserLoginRequest() {
    yield takeLatest(loginTypes.USER_LOGIN_REQUEST_START, userLoginRequest)
    yield takeLatest(loginTypes.USER_SIGNUP_REQUEST_START, userSignupRequest)
    yield takeLatest(loginTypes.USER_ADMIN_SIGNUP_REQUEST_START, adminSignupRequest)
    yield takeLatest(loginTypes.USER_LOGOUT, userLogoutRequest)
    yield takeLatest(loginTypes.CHECK_ACCESS_TOKEN_IS_EXISTED, isAccessTokenExisted)
    yield takeLatest(loginTypes.CHECK_USER_NAME_START, checkUsernameRequest)
    yield takeLatest(loginTypes.CHECK_USER_IS_AUTHENTICATED_START, checkUserAuthRequest)
}

// use 'fork' to start multiple sagas in the background
// they are always attached to thier parent
export default [fork(watchUserLoginRequest)]
