import React from 'react'
import PropTypes from 'prop-types'
import { Icon, Menu } from 'semantic-ui-react'
import styles from './Sidebarlist.module.css'

// eslint-disable-next-line react/prop-types
const SidebarList = ({ history, onListClick, dimmerActive, role }) => {
    const isAuthorized = role === 'Admin'
    return (
        <>
            <Menu size="large" vertical className={styles.sidebarMenuWrapper}>
                <Menu.Item
                    as="a"
                    className={styles.sidebarMenuItem}
                    onClick={() => {
                        dimmerActive && onListClick()
                        history.push('/')
                    }}
                >
                    <Icon name="home" color="grey" />
                    Dashboard
                </Menu.Item>
                {isAuthorized && (
                    <>
                        <Menu.Item
                            as="a"
                            className={styles.sidebarMenuItem}
                            onClick={() => {
                                dimmerActive && onListClick()
                                history.push('/web-users')
                            }}
                        >
                            <Icon name="users" color="grey" />
                            Web users
                        </Menu.Item>
                        <Menu.Item
                            as="a"
                            className={styles.sidebarMenuItem}
                            onClick={() => {
                                dimmerActive && onListClick()
                                history.push('/mobile-users')
                            }}
                        >
                            <Icon name="mobile alternate" color="grey" />
                            Mobile users
                        </Menu.Item>
                        <Menu.Item
                            as="a"
                            className={styles.sidebarMenuItem}
                            onClick={() => {
                                dimmerActive && onListClick()
                                history.push('/feedbacks')
                            }}
                        >
                            <Icon name="bullhorn" color="grey" />
                            Feedbacks
                        </Menu.Item>
                        <Menu.Item
                            as="a"
                            className={styles.sidebarMenuItem}
                            onClick={() => {
                                dimmerActive && onListClick()
                                history.push('/reported-bugs')
                            }}
                        >
                            <Icon name="bug" color="grey" />
                            Reported bugs
                        </Menu.Item>
                        <Menu.Item
                            as="a"
                            className={styles.sidebarMenuItem}
                            onClick={() => {
                                dimmerActive && onListClick()
                                history.push('/tags')
                            }}
                        >
                            <Icon name="tags" color="grey" />
                            Tags
                        </Menu.Item>
                        <Menu.Item
                            as="a"
                            className={styles.sidebarMenuItem}
                            onClick={() => {
                                dimmerActive && onListClick()
                                history.push('/create-question')
                            }}
                        >
                            <Icon name="add" color="grey" />
                            Create question
                        </Menu.Item>
                    </>
                )}
                <Menu.Item
                    as="a"
                    className={styles.sidebarMenuItem}
                    onClick={() => {
                        dimmerActive && onListClick()
                        history.push('/questions')
                    }}
                >
                    <Icon name="list layout" color="grey" />
                    Questions
                </Menu.Item>
                {role === 'Guest' && (
                    <>
                        <Menu.Item
                            disabled
                            as="a"
                            className={styles.sidebarMenuItem}
                            onClick={() => {
                                dimmerActive && onListClick()
                                history.push('/user-contribution')
                            }}
                        >
                            <Icon name="add" color="grey" disabled />
                            Question Submission
                        </Menu.Item>
                        <Menu.Item
                            as="a"
                            className={styles.sidebarMenuItem}
                            onClick={() => {
                                dimmerActive && onListClick()
                                history.push('/user-feedback')
                            }}
                        >
                            <Icon name="bullhorn" color="grey" />
                            Feedback
                        </Menu.Item>
                    </>
                )}
                <Menu.Item
                    as="a"
                    className={styles.sidebarMenuItem}
                    onClick={() => {
                        dimmerActive && onListClick()
                        history.push('/exams')
                    }}
                >
                    <Icon name="certificate" color="grey" />
                    Exam Centre
                </Menu.Item>
                {/* {isAuthorized && (
                    <Menu.Item
                        as="a"
                        className={styles.sidebarMenuItem}
                        onClick={() => {
                            dimmerActive && onListClick()
                            history.push('/settings')
                        }}
                    >
                        <Icon name="setting" color="grey" />
                        Settings
                    </Menu.Item>
                )} */}
            </Menu>
        </>
    )
}

SidebarList.defaultProps = {
    role: '',
}

SidebarList.propTypes = {
    history: PropTypes.object.isRequired,
    onListClick: PropTypes.func.isRequired,
    role: PropTypes.string,
}

export default SidebarList
