/* eslint-disable camelcase */
/* eslint no-unused-vars: "off" */
import axios from 'axios'

/**
 * QUESTIONS APIs
 * this file contains all user realted APIs
 * @type Service
 * @author Ehsan
 * @version 0.1.0
 * @param {array} credentials
 * @param {string} token
 */

const environment = process.env.NODE_ENV
let ENDPOINT
if (environment === 'development') {
    ENDPOINT = process.env.REACT_APP_DEV_API_ENDPOINT
} else {
    ENDPOINT = process.env.REACT_APP_PROD_API_ENDPOINT
}

export default {
    questions: {
        list: (data) => {
            const [limit, page, accessToken] = data
            const url = `${ENDPOINT}/questions?limit=${limit}&page=${page}`
            return axios({
                method: 'get',
                url,
                headers: {
                    Authorization: `Bearer ${accessToken}`,
                },
            })
        },
        filter: (data) => {
            const [limit, page, accessToken, filters] = data
            const url = `${ENDPOINT}/questions/filter?limit=${limit}&page=${page}`
            return axios({
                method: 'post',
                url,
                data: {
                    ...filters,
                },
                headers: {
                    Authorization: `Bearer ${accessToken}`,
                },
            })
        },
        searchFilter: (data) => {
            const [limit, page, accessToken, value, category, filters] = data
            const url = `${ENDPOINT}/questions/filter?limit=${limit}&page=${page}&q=${value}&cat=${category}`
            return axios({
                method: 'post',
                url,
                data: {
                    ...filters,
                },
                headers: {
                    Authorization: `Bearer ${accessToken}`,
                },
            })
        },
        search: (data) => {
            const [limit, page, accessToken, value, category] = data
            const url = `${ENDPOINT}/questions?limit=${limit}&page=${page}&q=${value}&cat=${category}`
            return axios({
                method: 'get',
                url,
                headers: {
                    Authorization: `Bearer ${accessToken}`,
                },
            })
        },
        listBookmarkQuestions: (data) => {
            const [postData, accessToken, limit, page] = data
            const url = `${ENDPOINT}/questions/bookmarks?limit=${limit}&page=${page}`
            return axios({
                method: 'post',
                url,
                data: {
                    ...postData,
                },
                headers: {
                    Authorization: `Bearer ${accessToken}`,
                },
            })
        },
        searchookmarkQuestions: (data) => {
            const [postData, accessToken, limit, page, value, category] = data
            const url = `${ENDPOINT}/questions/bookmarks?limit=${limit}&page=${page}&q=${value}&cat=${category}`
            return axios({
                method: 'post',
                url,
                data: {
                    ...postData,
                },
                headers: {
                    Authorization: `Bearer ${accessToken}`,
                },
            })
        },
        searchBookmarkFilteredQuestions: (data) => {
            const [postData, accessToken, limit, page, value, category] = data
            const url = `${ENDPOINT}/questions/bookmarks/filter?limit=${limit}&page=${page}&q=${value}&cat=${category}`
            return axios({
                method: 'post',
                url,
                data: {
                    ...postData,
                },
                headers: {
                    Authorization: `Bearer ${accessToken}`,
                },
            })
        },
        getBookmarks: (data) => {
            const [userData, accessToken] = data
            const url = `${ENDPOINT}/users/bookmarks?user_id=${userData.user_id}`
            return axios({
                method: 'get',
                url,
                headers: {
                    Authorization: `Bearer ${accessToken}`,
                },
            })
        },
        getSectionBookmarks: (data) => {
            const [userData, accessToken] = data
            const url = `${ENDPOINT}/users/bookmarks?user_id=${userData.user_id}&sections=${true}`
            return axios({
                method: 'get',
                url,
                headers: {
                    Authorization: `Bearer ${accessToken}`,
                },
            })
        },
        addBookmark: (data) => {
            const [postData, accessToken] = data
            const url = `${ENDPOINT}/users/bookmarks`
            return axios({
                method: 'post',
                url,
                data: {
                    ...postData,
                },
                headers: {
                    Authorization: `Bearer ${accessToken}`,
                },
            })
        },
        toggleSectionBookmark: (data) => {
            const [postData, accessToken] = data
            const { id, userId, sectionId } = postData
            const url = `${ENDPOINT}/users/bookmarks?section_id=${sectionId}`
            return axios({
                method: 'post',
                url,
                data: {
                    ...{ id, userId },
                },
                headers: {
                    Authorization: `Bearer ${accessToken}`,
                },
            })
        },
        removeBookmark: (data) => {
            const [postData, accessToken] = data
            const url = `${ENDPOINT}/users/bookmarks`
            return axios({
                method: 'delete',
                url,
                data: {
                    ...postData,
                },
                headers: {
                    Authorization: `Bearer ${accessToken}`,
                },
            })
        },
        clearAllBookmarks: (data) => {
            const [userData, accessToken] = data
            const url = `${ENDPOINT}/users/bookmarks/clear`
            return axios({
                method: 'post',
                url,
                data: {
                    ...userData,
                },
                headers: {
                    Authorization: `Bearer ${accessToken}`,
                },
            })
        },
        getById: (data) => {
            const [limit, page, id, accessToken] = data
            const url = `${ENDPOINT}/questions?limit=${limit}&page=${page}&question_id=${id}`
            return axios({
                method: 'get',
                url,
                headers: {
                    Authorization: `Bearer ${accessToken}`,
                },
            })
        },
        add: (data) => {
            const [questionData, accessToken] = data
            const url = `${ENDPOINT}/questions`
            return axios({
                method: 'post',
                url,
                data: {
                    ...questionData,
                },
                headers: {
                    Authorization: `Bearer ${accessToken}`,
                },
            })
        },
        edit: (data) => {
            const [questionData, accessToken] = data
            const url = `${ENDPOINT}/questions`
            return axios({
                method: 'put',
                url,
                data: {
                    ...questionData,
                },
                headers: {
                    Authorization: `Bearer ${accessToken}`,
                },
            })
        },
        delete: (data) => {
            const [questionId, accessToken, limit, oldActivePage, isFromCreate, signInUserId] = data
            const url = `${ENDPOINT}/questions`
            return axios({
                method: 'delete',
                url,
                data: {
                    question_id: questionId,
                    user_id: signInUserId,
                },
                headers: {
                    Authorization: `Bearer ${accessToken}`,
                },
            })
        },
        listLanguages: (accessToken) => {
            const url = `${ENDPOINT}/languages`
            return axios({
                method: 'get',
                url,
                headers: {
                    Authorization: `Bearer ${accessToken}`,
                },
            })
        },
        listStats: (accessToken) => {
            const url = `${ENDPOINT}/questions/stats`
            return axios({
                method: 'get',
                url,
                headers: {
                    Authorization: `Bearer ${accessToken}`,
                },
            })
        },
        filterByLng: (data) => {
            const [limit, page, languageId, accessToken, showBookmarksOnly, user_id] = data
            let url = `${ENDPOINT}/questions/filter/quick?limit=${limit}&page=${page}&lng=${languageId}`
            if (showBookmarksOnly && user_id) {
                url = `${ENDPOINT}/questions/filter/quick?limit=${limit}&page=${page}&lng=${languageId}&showBookmarksOnly=${showBookmarksOnly}&user_id=${user_id}`
            }
            return axios({
                method: 'post',
                url,
                headers: {
                    Authorization: `Bearer ${accessToken}`,
                },
            })
        },
        searchFilterByLng: (data) => {
            const [
                limit,
                page,
                languageId,
                accessToken,
                searchValue,
                category,
                showBookmarksOnly,
                user_id,
            ] = data
            let url = `${ENDPOINT}/questions/filter/quick/search?limit=${limit}&page=${page}&lng=${languageId}`
            if (showBookmarksOnly && user_id) {
                url = `${ENDPOINT}/questions/filter/quick/search?limit=${limit}&page=${page}&lng=${languageId}&showBookmarksOnly=${showBookmarksOnly}&user_id=${user_id}`
            }
            return axios({
                method: 'post',
                url,
                data: {
                    search: searchValue,
                    category,
                },
                headers: {
                    Authorization: `Bearer ${accessToken}`,
                },
            })
        },
    },
    sections: {
        edit: (payload) => {
            const [data, accessToken] = payload
            const url = `${ENDPOINT}/sections`
            return axios({
                method: 'put',
                url,
                data: {
                    ...data,
                },
                headers: {
                    Authorization: `Bearer ${accessToken}`,
                },
            })
        },
        add: (payload) => {
            const [data, accessToken] = payload
            const url = `${ENDPOINT}/sections`
            return axios({
                method: 'post',
                url,
                data: {
                    ...data,
                },
                headers: {
                    Authorization: `Bearer ${accessToken}`,
                },
            })
        },
        delete: (payload) => {
            const [data, accessToken] = payload
            const url = `${ENDPOINT}/sections`
            return axios({
                method: 'delete',
                url,
                data: {
                    ...data,
                },
                headers: {
                    Authorization: `Bearer ${accessToken}`,
                },
            })
        },
    },
}
