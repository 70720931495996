import React, { useState, useEffect, useCallback } from 'react'
import PropTypes from 'prop-types'
import { Header } from 'semantic-ui-react'
import toastr from 'toastr'
import { TOASTR_OPTIONS_EXAMS } from '../../../consts'

Object.assign(toastr.options, TOASTR_OPTIONS_EXAMS)
const CountdownTimer = ({ start, onTimerEnd, endTime, resumeTime, beginAt }) => {
    const [secondsRemaining, setSecondsRemaining] = useState(null)
    const [timer, setTimer] = useState('')
    const [halfTimeReminder, setHalfTimeReminder] = useState(null)

    const tick = useCallback(() => {
        const min = Math.floor(secondsRemaining / 60)
        let sec = secondsRemaining - min * 60
        // add a leading zero (as a string value) if seconds less than 10
        if (sec < 10) {
            sec = `0${sec}`
        }

        const timerString = `${min.toString()}:${sec}`
        setTimer(timerString)

        // subtract from seconds remaining
        setSecondsRemaining(secondsRemaining - 1)
    }, [secondsRemaining])

    const converMinutes = () => {
        if (start) {
            const hours = Math.floor(start / 60)
            const minutes = start % 60
            return `${hours} ${hours > 1 ? 'hours' : 'hour'} and ${minutes} ${
                minutes > 1 ? 'minutes' : 'minute'
            }  (Ends at: ${endTime})`
        }

        if (resumeTime) {
            return `Ends at: ${endTime}`
        }

        return ''
    }

    useEffect(() => {
        if (start) {
            setSecondsRemaining(start * 60)
            setHalfTimeReminder(Math.floor((start * 60) / 2))
        }
    }, [start])

    useEffect(() => {
        if (resumeTime) {
            setSecondsRemaining(resumeTime)
        }
    }, [resumeTime])

    useEffect(() => {
        let intervalHandle = null
        // let timeout = null
        intervalHandle = setInterval(tick, 1000)
        if (secondsRemaining === 0) {
            clearInterval(intervalHandle)
            setTimer('')
            onTimerEnd()
        }
        return () => {
            clearInterval(intervalHandle)
        }
    }, [onTimerEnd, secondsRemaining, tick, timer])

    useEffect(() => {
        if (secondsRemaining === 59) {
            toastr['warning'](`You have a minute to wrap up!`, 'EXAM IS ABOUT TO END SOON')
        }
    }, [secondsRemaining])

    useEffect(() => {
        if (halfTimeReminder && secondsRemaining === halfTimeReminder) {
            const status = halfTimeReminder < 60 ? 'error' : 'info'
            toastr[status](`You're half-way through!`, 'REMINDER')
        }
    }, [halfTimeReminder, secondsRemaining])

    return (
        <>
            {!start && !resumeTime && (
                <Header as="h3" inverted>
                    NO Time-Limit Exam
                    {beginAt && <Header.Subheader>{`Started at: ${beginAt}`}</Header.Subheader>}
                </Header>
            )}
            {(start || resumeTime) && (
                <Header as="h2" inverted>
                    {timer}
                    <Header.Subheader>{converMinutes()}</Header.Subheader>
                </Header>
            )}
        </>
    )
}

CountdownTimer.defaultProps = {
    start: null,
    endTime: null,
    resumeTime: null,
    beginAt: null,
}

CountdownTimer.propTypes = {
    start: PropTypes.number,
    onTimerEnd: PropTypes.func.isRequired,
    endTime: PropTypes.string,
    beginAt: PropTypes.string,
    resumeTime: PropTypes.number,
}

export default CountdownTimer
