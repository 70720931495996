/* eslint-disable no-underscore-dangle */
export const transformData = (data) => {
    const result = {}
    const { data: users } = data
    result.count = data.count

    const tagNames = users.reduce((res, tag) => {
        res.push(tag.name)
        return res
    }, [])

    const tags = users.reduce((res, tag) => {
        res.push({
            id: tag._id,
            key: tag._id,
            name: tag.name,
        })
        return res
    }, [])

    result.tags = tags
    result.tagNames = tagNames
    return result
}
