import * as actions from './user.action.types'

const initialState = {
    isFetching: false,
    webUsers: [],
    mobileUsers: [],
    webUsersCount: 0,
    mobileUsersCount: 0,
}

export default (state = initialState, action) => {
    switch (action.type) {
        case actions.SEND_FEEDBACK_EMAIL_START:
        case actions.SEND_INVITATION_EMAIL_START:
        case actions.CREATE_GUEST_USER_START:
        case actions.DELETE_MOBILE_USER_START:
        case actions.LIST_MOBILE_USERS_START:
        case actions.DELETE_USER_START:
        case actions.LIST_USERS_START:
        case actions.SEARCH_USERS_START:
            return {
                ...state,
                isFetching: true,
            }

        case actions.LIST_USERS_SUCCESS: {
            const { users: webUsers, count: webUsersCount } = action.payload
            return {
                ...state,
                isFetching: false,
                webUsers,
                webUsersCount,
            }
        }

        case actions.LIST_MOBILE_USERS_SUCCESS: {
            const { users: mobileUsers, count: mobileUsersCount } = action.payload
            return {
                ...state,
                isFetching: false,
                mobileUsers,
                mobileUsersCount,
            }
        }

        case actions.LIST_MOBILE_USERS_FAIL:
        case actions.LIST_USERS_FAIL:
            return {
                ...state,
                isFetching: false,
                webUsers: [],
                mobileUsers: [],
                webUsersCount: 0,
                mobileUsersCount: 0,
            }

        case actions.SEND_FEEDBACK_EMAIL_SUCCESS:
        case actions.SEND_INVITATION_EMAIL_SUCCESS:
        case actions.CREATE_GUEST_USER_SUCCESS:
        case actions.DELETE_USER_SUCCESS:
        case actions.DELETE_MOBILE_USER_SUCCESS: {
            return {
                ...state,
                isFetching: false,
            }
        }

        case actions.SEND_FEEDBACK_EMAIL_FAIL:
        case actions.SEND_INVITATION_EMAIL_FAIL:
        case actions.CREATE_GUEST_USER_FAIL:
        case actions.DELETE_MOBILE_USER_FAIL:
        case actions.DELETE_USER_FAIL:
        case actions.SEARCH_USERS_FAIL:
            return {
                ...state,
                isFetching: false,
            }

        default:
            return state
    }
}
