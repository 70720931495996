/* eslint-disable no-underscore-dangle */

const generateTagsData = (tagsData) => {
    const result = {}
    for (const [k, v] of Object.entries(tagsData)) {
        result[k] = []
        for (const [kv, vv] of Object.entries(v)) {
            result[k].push({
                name: kv,
                total: vv,
            })
        }
    }

    return result
}

export const transformData = (data) => {
    const result = {}
    const {
        data: { totals, languages },
    } = data

    result.difficultyData = []
    result.languagesData = []
    result.questionsData = []
    const tagsData = {}

    for (const [k, v] of Object.entries(languages)) {
        result.difficultyData.push({
            name: k,
            basics: v.basics,
            intermediate: v.intermediate,
            advanced: v.advanced,
        })
        result.languagesData.push({
            name: k,
            total: v.count,
        })

        const { tags } = v
        tagsData[k] = tags
    }

    for (const [k, v] of Object.entries(totals)) {
        let name = ''
        if (k === 'count') name = 'Total'
        if (k === 'total_lives') name = 'Live'
        if (k === 'total_drafts') name = 'Draft'
        if (k === 'total_undefined_live') name = 'UndefinedQ'
        result.questionsData.push({
            name,
            total: v,
        })
    }

    result.tagsData = generateTagsData(tagsData)
    return result
}
