/* eslint no-unused-vars: "off" */
import { put, call, fork, takeLatest } from 'redux-saga/effects'
import toastr from 'toastr'
import * as userTypes from './user.action.types'
import { USER_LOGOUT } from '../../Auth/login/engine/login.action.types'
import api from '../../../services/api/user.api'
import { transformData as transformDataWeb } from '../../../services/transform/list.users.res.transform'
import { transformData as transformDataMobile } from '../../../services/transform/list.mobile.users.res.transform'
import { TOASTR_OPTIONS } from '../../../consts'

/**
 * USER SAGAS
 * this file contains all related user sagas and its associated async calls
 * @type Core
 * @author Ehsan
 * @version 0.1.1
 */

Object.assign(toastr.options, TOASTR_OPTIONS)

export function* listWebUsersRequest(action) {
    try {
        const response = yield call(api.user.listWebUsers, action.payload)
        // const delay = (ms) => new Promise((res) => setTimeout(res, ms))

        if (response && response.status === 200) {
            const transformedData = yield call(transformDataWeb, response.data)
            if (transformedData) {
                yield put({
                    type: userTypes.LIST_USERS_SUCCESS,
                    payload: transformedData,
                })
                // toastr['success']('Users successfully fetched.')
            }
        } else {
            throw response
        }
    } catch (e) {
        const delay = (ms) => new Promise((res) => setTimeout(res, ms))
        yield put({
            type: userTypes.LIST_USERS_FAIL,
            payload: e,
        })

        let message
        let status
        if (e.response) {
            message = e.response.data.message
            status = e.response.data.status
        }

        if (status === 401) {
            toastr['error'](message, 'Authentication Error')
            yield put({
                type: userTypes.LIST_USERS_FAIL,
                payload: message,
            })
            yield delay(3000)
            yield put({
                type: USER_LOGOUT,
            })
        } else {
            toastr['error'](message, 'List users failed!')
        }
    }
}

export function* listMobilebUsersRequest(action) {
    try {
        const response = yield call(api.user.listMobileUsers, action.payload)
        // const delay = (ms) => new Promise((res) => setTimeout(res, ms))

        if (response && response.status === 200) {
            const transformedData = yield call(transformDataMobile, response.data)
            if (transformedData) {
                yield put({
                    type: userTypes.LIST_MOBILE_USERS_SUCCESS,
                    payload: transformedData,
                })
                toastr['success']('Mobile Users successfully fetched.')
            }
        } else {
            throw response
        }
    } catch (e) {
        const delay = (ms) => new Promise((res) => setTimeout(res, ms))
        yield put({
            type: userTypes.LIST_MOBILE_USERS_FAIL,
            payload: e,
        })

        let message
        let status
        if (e.response) {
            message = e.response.data.message
            status = e.response.data.status
        }

        if (status === 401) {
            toastr['error'](message, 'Authentication Error')
            yield put({
                type: userTypes.LIST_MOBILE_USERS_FAIL,
                payload: message,
            })
            yield delay(3000)
            yield put({
                type: USER_LOGOUT,
            })
        } else {
            toastr['error'](message, 'List mobile users failed!')
        }
    }
}

export function* deleteWebUserRequest(action) {
    try {
        const response = yield call(api.user.deleteWebUser, action.payload)
        // const delay = (ms) => new Promise((res) => setTimeout(res, ms))

        if (response && response.status === 200) {
            yield put({
                type: userTypes.DELETE_USER_SUCCESS,
                payload: response.data,
            })
            const [id, token] = action.payload
            yield put({
                type: userTypes.LIST_USERS_START,
                payload: [10, 1, token], // limit,page
            })
            toastr['success']('User successfully deleted.')
        } else {
            throw response
        }
    } catch (e) {
        const delay = (ms) => new Promise((res) => setTimeout(res, ms))
        yield put({
            type: userTypes.DELETE_USER_FAIL,
            payload: e,
        })

        let message
        let status
        if (e.response) {
            message = e.response.data.message
            status = e.response.data.status
        }

        if (status === 401) {
            toastr['error'](message, 'Authentication Error')
            yield put({
                type: userTypes.DELETE_USER_FAIL,
                payload: message,
            })
            yield delay(3000)
            yield put({
                type: USER_LOGOUT,
            })
        } else {
            toastr['error'](message, 'Delete user failed!')
        }
    }
}

export function* deleteMobilebUserRequest(action) {
    try {
        const response = yield call(api.user.deleteMobilebUser, action.payload)
        // const delay = (ms) => new Promise((res) => setTimeout(res, ms))

        if (response && response.status === 200) {
            yield put({
                type: userTypes.DELETE_MOBILE_USER_SUCCESS,
                payload: response.data,
            })
            const [id, token] = action.payload
            yield put({
                type: userTypes.LIST_MOBILE_USERS_START,
                payload: [10, 1, token], // limit,page
            })
            toastr['success']('Mobile user successfully deleted.')
        } else {
            throw response
        }
    } catch (e) {
        const delay = (ms) => new Promise((res) => setTimeout(res, ms))
        yield put({
            type: userTypes.DELETE_MOBILE_USER_FAIL,
            payload: e,
        })

        let message
        let status
        if (e.response) {
            message = e.response.data.message
            status = e.response.data.status
        }

        if (status === 401) {
            toastr['error'](message, 'Authentication Error')
            yield put({
                type: userTypes.DELETE_MOBILE_USER_FAIL,
                payload: message,
            })
            yield delay(3000)
            yield put({
                type: USER_LOGOUT,
            })
        } else {
            toastr['error'](message, 'Delete mobile user failed!')
        }
    }
}

export function* createGuestUserRequest(action) {
    try {
        const [data, token] = action.payload
        const response = yield call(api.user.createGuestbUser, action.payload)

        if (response && response.status === 201) {
            yield put({
                type: userTypes.CREATE_GUEST_USER_SUCCESS,
                payload: response.data,
            })
            yield put({
                type: userTypes.LIST_USERS_START,
                payload: [10, 1, token], // limit,page
            })
            toastr['success']('Guest user successfully created.')
        } else {
            throw response
        }
    } catch (e) {
        const delay = (ms) => new Promise((res) => setTimeout(res, ms))
        yield put({
            type: userTypes.CREATE_GUEST_USER_FAIL,
            payload: e,
        })

        let message
        let status
        if (e.response) {
            message = e.response.data.message
            status = e.response.data.status
        }

        if (status === 401) {
            toastr['error'](message, 'Authentication Error')
            yield put({
                type: userTypes.CREATE_GUEST_USER_FAIL,
                payload: message,
            })
            yield delay(3000)
            yield put({
                type: USER_LOGOUT,
            })
        } else {
            toastr['error'](message, 'Create guest user failed!')
        }
    }
}

export function* sendInvitationEmailRequest(action) {
    try {
        const response = yield call(api.user.sendInvitation, action.payload)

        if (response && response.status === 200) {
            yield put({
                type: userTypes.SEND_INVITATION_EMAIL_SUCCESS,
                payload: response.data,
            })

            toastr['success']('Email successfully sent.')
        } else {
            throw response
        }
    } catch (e) {
        const delay = (ms) => new Promise((res) => setTimeout(res, ms))
        yield put({
            type: userTypes.SEND_INVITATION_EMAIL_FAIL,
            payload: e,
        })

        let message
        let status
        if (e.response) {
            message = e.response.data.message
            status = e.response.data.status
        }

        if (status === 401) {
            toastr['error'](message, 'Authentication Error')
            yield put({
                type: userTypes.SEND_INVITATION_EMAIL_FAIL,
                payload: message,
            })
            yield delay(3000)
            yield put({
                type: USER_LOGOUT,
            })
        } else {
            toastr['error'](message, 'Send email failed!')
        }
    }
}

export function* sendFeedbackEmailRequest(action) {
    try {
        const response = yield call(api.user.sendGreetingFeedbackEmail, action.payload)

        if (response && response.status === 200) {
            yield put({
                type: userTypes.SEND_FEEDBACK_EMAIL_SUCCESS,
                payload: response.data,
            })

            toastr['success']('Email successfully sent.')
        } else {
            throw response
        }
    } catch (e) {
        const delay = (ms) => new Promise((res) => setTimeout(res, ms))
        yield put({
            type: userTypes.SEND_FEEDBACK_EMAIL_FAIL,
            payload: e,
        })

        let message
        let status
        if (e.response) {
            message = e.response.data.message
            status = e.response.data.status
        }

        if (status === 401) {
            toastr['error'](message, 'Authentication Error')
            yield put({
                type: userTypes.SEND_FEEDBACK_EMAIL_FAIL,
                payload: message,
            })
            yield delay(3000)
            yield put({
                type: USER_LOGOUT,
            })
        } else {
            toastr['error'](message, 'Send email failed!')
        }
    }
}

export function* searchUsersRequest(action) {
    try {
        const response = yield call(api.user.searchUsers, action.payload)
        if (response && response.status === 200) {
            const transformedData = yield call(transformDataWeb, response.data)
            if (transformedData) {
                yield put({
                    type: userTypes.LIST_USERS_SUCCESS,
                    payload: transformedData,
                })
                // toastr['success']('Users successfully fetched.')
            }
        } else {
            throw response
        }
    } catch (e) {
        const delay = (ms) => new Promise((res) => setTimeout(res, ms))
        yield put({
            type: userTypes.SEARCH_USERS_FAIL,
            payload: e,
        })

        let message
        let status
        if (e.response) {
            message = e.response.data.message
            status = e.response.data.status
        }

        if (status === 401) {
            toastr['error'](message, 'Authentication Error')
            yield put({
                type: userTypes.SEARCH_USERS_FAIL,
                payload: message,
            })
            yield delay(3000)
            yield put({
                type: USER_LOGOUT,
            })
        } else {
            toastr['error'](message, 'Search users failed!')
        }
    }
}

// a watcher to listen to our request ‘start’ action
function* watchUserLoginRequest() {
    yield takeLatest(userTypes.LIST_USERS_START, listWebUsersRequest)
    yield takeLatest(userTypes.DELETE_USER_START, deleteWebUserRequest)
    yield takeLatest(userTypes.LIST_MOBILE_USERS_START, listMobilebUsersRequest)
    yield takeLatest(userTypes.DELETE_MOBILE_USER_START, deleteMobilebUserRequest)
    yield takeLatest(userTypes.CREATE_GUEST_USER_START, createGuestUserRequest)
    yield takeLatest(userTypes.SEND_INVITATION_EMAIL_START, sendInvitationEmailRequest)
    yield takeLatest(userTypes.SEND_FEEDBACK_EMAIL_START, sendFeedbackEmailRequest)
    yield takeLatest(userTypes.SEARCH_USERS_START, searchUsersRequest)
}

// use 'fork' to start multiple sagas in the background
// they are always attached to thier parent
export default [fork(watchUserLoginRequest)]
