import passwordValidator from 'password-validator'
import isEmail from 'validator/lib/isEmail'
import isJWT from 'validator/lib/isJWT'
import isMongoId from 'validator/lib/isMongoId'

export const validatePassword = (password) => {
    // eslint-disable-next-line new-cap
    const schema = new passwordValidator()
    schema
        .is()
        .min(8) // Minimum length 8
        .is()
        .max(50) // Maximum length 50
        .has()
        .uppercase() // Must have uppercase letter
        .has()
        .lowercase() // Must have lowercase letter
        .has()
        .digits() // Must have digit
        .has()
        .symbols() // Must have sybmol
        .has()
        .not()
        .spaces() // Should not have spaces
    return {
        isValid: schema.validate(password),
        errors: schema.validate(password, {
            list: true,
        }),
    }
}

export const validateEmail = (email) => {
    const isValid = isEmail(email)
    if (!isValid) return false

    return true
}

export const validateToken = (token) => {
    const isValid = isJWT(token)
    if (!isValid) return false

    const persistedSession = window.localStorage.getItem('persist:session')
    if (persistedSession || persistedSession !== undefined) {
        const { persistedData } = JSON.parse(persistedSession)
        const { token: persistedToken } = JSON.parse(persistedData)
        return persistedToken === token
    }

    return false
}

export const validateUsername = (name, min = 5, max = 30) => {
    const hasInvalidKeys = name.includes('#' || '!' || '<' || '>')
    if (name.length < min || name.length > max || hasInvalidKeys) return false

    return true
}

export const isAplhanumeric = (value) => {
    // accepts space too!
    const myRegEx = /^[a-zA-Z0-9_ ]*$/gi
    const isValid = myRegEx.test(value)

    // this regex is also useful to find special charachters only!
    // const myRegEx = /\W|_/g
    // const isValid = !myRegEx.test(value)

    return isValid
}

export const isAplhanumericWithLimit = (value, max = 250) => {
    // accepts space too!
    if (value.length > max) return false
    const myRegEx = /^[a-zA-Z0-9_ ]*$/gi
    const isValid = myRegEx.test(value)

    // this regex is also useful to find special charachters only!
    // const myRegEx = /\W|_/g
    // const isValid = !myRegEx.test(value)

    return isValid
}

export const isValidId = (id) => isMongoId(id)

export const isInteger = (n) => !Number.isNaN(n) && parseInt(n, 10) === n
