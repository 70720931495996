// TEST CENTRE
export const FETCH_EXAM_START = 'exam/FETCH_EXAM_START'
export const FETCH_EXAM_SUCCESS = 'exam/FETCH_EXAM_SUCCESS'
export const FETCH_EXAM_FAIL = 'exam/FETCH_EXAM_FAIL'

export const FETCH_EXAM_QUESTION_START = 'exam/FETCH_EXAM_QUESTION_START'
export const FETCH_EXAM_QUESTION_SUCCESS = 'exam/FETCH_EXAM_QUESTION_SUCCESS'
export const FETCH_EXAM_QUESTION_FAIL = 'exam/FETCH_EXAM_QUESTION_FAIL'

export const SUBMIT_EXAM_START = 'exam/SUBMIT_EXAM_START'
export const SUBMIT_EXAM_SUCCESS = 'exam/SUBMIT_EXAM_SUCCESS'
export const SUBMIT_EXAM_FAIL = 'exam/SUBMIT_EXAM_FAIL'

export const LIST_SUBMITTED_EXAMS_START = 'exam/LIST_SUBMITTED_EXAMS_START'
export const LIST_SUBMITTED_EXAMS_SUCCESS = 'exam/LIST_SUBMITTED_EXAMS_SUCCESS'
export const LIST_SUBMITTED_EXAMS_FAIL = 'exam/LIST_SUBMITTED_EXAMS_FAIL'

export const SET_SUBMISSION_SCORE_START = 'submit/SET_SUBMISSION_SCORE_START'
export const SET_SUBMISSION_SCORE_SUCCESS = 'submit/SET_SUBMISSION_SCORE_SUCCESS'
export const SET_SUBMISSION_SCORE_FAIL = 'submit/SET_SUBMISSION_SCORE_FAIL'

export const ADD_SUBMISSION_COMMENT_START = 'submit/ADD_SUBMISSION_COMMENT_START'
export const ADD_SUBMISSION_COMMENT_SUCCESS = 'submit/ADD_SUBMISSION_COMMENT_SUCCESS'
export const ADD_SUBMISSION_COMMENT_FAIL = 'submit/ADD_SUBMISSION_COMMENT_FAIL'

export const CLEAR_SUBMISSION_SCORE_START = 'submit/CLEAR_SUBMISSION_SCORE_START'
export const CLEAR_SUBMISSION_SCORE_SUCCESS = 'submit/CLEAR_SUBMISSION_SCORE_SUCCESS'
export const CLEAR_SUBMISSION_SCORE_FAIL = 'submit/CLEAR_SUBMISSION_SCORE_FAIL'

export const DELETE_SUBMISSION_COMMENT_START = 'submit/DELETE_SUBMISSION_COMMENT_START'
export const DELETE_SUBMISSION_COMMENT_SUCCESS = 'submit/DELETE_SUBMISSION_COMMENT_SUCCESS'
export const DELETE_SUBMISSION_COMMENT_FAIL = 'submit/DELETE_SUBMISSION_COMMENT_FAIL'

export const DELETE_EXAM_SUBMISSION_START = 'submit/DELETE_EXAM_SUBMISSION_START'
export const DELETE_EXAM_SUBMISSION_SUCCESS = 'submit/DELETE_EXAM_SUBMISSION_SUCCESS'
export const DELETE_EXAM_SUBMISSION_FAIL = 'submit/DELETE_EXAM_SUBMISSION_FAIL'

export const UPDATE_EXAM_EXPIRY_START = 'submit/UPDATE_EXAM_EXPIRY_START'
export const UPDATE_EXAM_EXPIRY_SUCCESS = 'submit/UPDATE_EXAM_EXPIRY_SUCCESS'
export const UPDATE_EXAM_EXPIRY_FAIL = 'submit/UPDATE_EXAM_EXPIRY_FAIL'

export const UPDATE_EXAM_SUBMIT_STATUS = 'submit/UPDATE_EXAM_SUBMIT_STATUS'
export const CLEAR_PERSISTED_SUBMISSIONS = 'submit/CLEAR_PERSISTED_SUBMISSIONS'
export const SORT_SUBMISSIONS = 'submit/SORT_SUBMISSIONS'
