import { createStore, applyMiddleware, compose } from 'redux'
import { persistStore, persistReducer } from 'redux-persist'
import storage from 'redux-persist/lib/storage'
import { routerMiddleware } from 'react-router-redux'
// eslint-disable-next-line import/no-extraneous-dependencies
import { createBrowserHistory } from 'history'
import { createLogger } from 'redux-logger'
import createSagaMiddleware from 'redux-saga'
// eslint-disable-next-line import/no-cycle
import sagas from './sagas'
import rootReducer from './reducers'
import 'core-js/stable'
import 'regenerator-runtime/runtime'
/**
 * STORE
 * this file holds the entire state tree of the app
 * it also contains store configurations including middlewares, enhancers and persist
 * @type redux store
 * @author Ehsan
 * @version 0.1.2
 */

export const history = createBrowserHistory()

// Config DevTools
const composeEnhancers =
    process.env.NODE_ENV !== 'production' &&
    typeof window === 'object' &&
    window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
        ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
        : compose

// Enhancers and Middlewares
const logger = createLogger({ collapsed: true })
const sagaMiddleware = createSagaMiddleware()

let middlewares
if (process.env.NODE_ENV !== 'production') {
    middlewares = [routerMiddleware(history), logger, sagaMiddleware]
} else {
    middlewares = [routerMiddleware(history), sagaMiddleware]
}

// const middlewares = [routerMiddleware(history), logger, sagaMiddleware]
// const middlewares = [routerMiddleware(history), sagaMiddleware]
const enhancers = [applyMiddleware(...middlewares)]

const configureStore = () => {
    // const store = createStore(rootReducer, {}, composeEnhancers(...enhancers))

    // /**
    //  * Start saga
    //  * NOTE: running sagas in store should ,happen after applying enhancers!
    //  */
    // sagaMiddleware.run(sagas)
    // return { store }

    // redux-persist config
    const persistConfig = {
        key: 'session',
        storage, // local storage
        whitelist: ['persistedData'], // you can also use blacklist to exclude reducer(s) from being persisted! (no nesting is accepted)
    }

    const persistedReducer = persistReducer(persistConfig, rootReducer)
    const store = createStore(persistedReducer, {}, composeEnhancers(...enhancers))

    /**
     * Start saga
     * NOTE: running sagas in store should happen after applying enhancers!
     */
    sagaMiddleware.run(sagas)

    const persistor = persistStore(store)
    return { store, persistor }
}

export default configureStore
