/* eslint-disable no-underscore-dangle */
import * as loginActions from './Auth/login/engine/login.action.types'
import * as tagActions from './Tags/engine/tags.action.types'
import * as testActions from './TestCentre/engine/test.centre.action.types'
import * as persistActions from './pages.persisted.action.types'
import {
    GET_QUESTION_LANGUAGE_SUCCESS,
    GET_QUESTION_LANGUAGE_FAIL,
    FILTER_QUESTION_BY_LNG_SUCCESS,
    FILTER_QUESTION_BY_LNG_FAIL,
    REMOVE_QUICK_FILTER,
} from './Questions/engine/questions.action.types'
import {
    FETCH_EXAM_QUESTION_SUCCESS,
    FETCH_EXAM_QUESTION_FAIL,
    LIST_SUBMITTED_EXAMS_SUCCESS,
    LIST_SUBMITTED_EXAMS_FAIL,
    CLEAR_PERSISTED_SUBMISSIONS,
    SORT_SUBMISSIONS,
    SET_SUBMISSION_SCORE_SUCCESS,
    CLEAR_SUBMISSION_SCORE_SUCCESS,
    ADD_SUBMISSION_COMMENT_SUCCESS,
    DELETE_SUBMISSION_COMMENT_SUCCESS,
} from './ExamDetails/engine/exam.details.action.types'
import { sortByDate } from '../utils'
import { setStorage } from '../services/auth/storage'

const initialState = {
    token: null,
    userData: null,
    tokenExpiry: null,
    selectedTheme: null,
    codingLanguages: null,
    tags: [],
    tagNames: [],
    sort: 'desc',
    sholdLoadBookmarks: false,
    urlPathname: '/',
    exams: [],
    toggle: true,
    examQuestions: null,
    examSubmissions: null,
    examSubmissionsCount: 0,
    submissionsSort: 'desc',
    showLanguageIcons: false,
    quickFilter: null,
    limit: 10,
}

export default (state = initialState, action) => {
    switch (action.type) {
        case loginActions.USER_LOGIN_REQUEST_SUCCESS: {
            const { token, data: user, expiresIn } = action.payload.data
            return {
                ...state,
                token,
                userData: user,
                tokenExpiry: expiresIn,
            }
        }

        case loginActions.USER_LOGIN_REQUEST_FAIL:
            return {
                ...state,
                token: null,
                userData: null,
                tokenExpiry: null,
            }

        case tagActions.LIST_TAGS_SUCCESS: {
            const { tags, tagNames } = action.payload
            return {
                ...state,
                tags,
                tagNames,
            }
        }
        case loginActions.USER_LOGOUT_SUCCESS:
            return {
                ...state,
                token: null,
                userData: null,
                tokenExpiry: null,
                tags: [],
                tagNames: [],
                exams: [],
                examSubmissions: null,
                examSubmissionsCount: 0,
                quickFilter: null,
            }

        case loginActions.USER_LOGOUT_FAIL:
            return state

        case loginActions.PERSIST_SELECTED_THEME:
            return {
                ...state,
                selectedTheme: action.data,
            }

        case GET_QUESTION_LANGUAGE_SUCCESS: {
            const codingLanguages = action.payload.data.reduce((res, curr) => {
                // eslint-disable-next-line camelcase
                const { created_at, __v, ...rest } = curr
                res.push({ ...rest, key: rest._id })

                return res
            }, [])
            return {
                ...state,
                codingLanguages,
            }
        }

        case GET_QUESTION_LANGUAGE_FAIL:
            return {
                ...state,
                codingLanguages: null,
            }

        case testActions.SORT_EXAM_ASC:
            return {
                ...state,
                sort: 'asc',
            }
        case testActions.SORT_EXAM_DESC:
            return {
                ...state,
                sort: 'desc',
            }

        case testActions.CACHE_SHOULD_LOAD_BOOKMARKS: {
            return {
                ...state,
                sholdLoadBookmarks: action.payload,
            }
        }

        case persistActions.PERSIST_LAST_VISITED_PATHNAME:
            if (action.payload !== '/login') {
                setStorage('urlPathname', action.payload)
                return {
                    ...state,
                    urlPathname: action.payload,
                }
            }
            return state

        case testActions.LIST_TESTS_SUCCESS: {
            const {
                data: { exams: userExams, bookmarks, bookmarkIds },
                count: examsCount,
                sortType,
            } = action.payload

            return {
                ...state,
                exams: sortByDate(userExams, 'created_at', sortType),
                examSubmissions: null,
                examSubmissionsCount: 0,
            }
        }

        case persistActions.PERSIST_TOGGLE:
            return {
                ...state,
                toggle: action.payload,
            }

        case FETCH_EXAM_QUESTION_SUCCESS:
            return {
                ...state,
                examQuestions: action.payload,
            }
        case FETCH_EXAM_QUESTION_FAIL:
            return {
                ...state,
                examQuestions: null,
            }
        case testActions.CLEAR_EXAM_QUESTIONS:
            return {
                ...state,
                examQuestions: null,
            }
        case LIST_SUBMITTED_EXAMS_SUCCESS: {
            const {
                submissions: { count, data },
                sortType,
            } = action.payload

            return {
                ...state,
                examSubmissions: count !== 0 ? sortByDate(data, 'submitted_at', sortType) : null,
                examSubmissionsCount: count,
            }
        }

        case persistActions.CLEAR_EXAM_SUBMISSIONS:
        case CLEAR_PERSISTED_SUBMISSIONS:
        case LIST_SUBMITTED_EXAMS_FAIL: {
            return {
                ...state,
                examSubmissions: null,
                examSubmissionsCount: 0,
            }
        }

        case SORT_SUBMISSIONS: {
            switch (action.payload) {
                case 'asc':
                    return {
                        ...state,
                        submissionsSort: 'asc',
                        examSubmissions: sortByDate(state.examSubmissions, 'submitted_at', 'asc'),
                    }
                case 'desc':
                    return {
                        ...state,
                        submissionsSort: 'desc',
                        examSubmissions: sortByDate(state.examSubmissions, 'submitted_at', 'desc'),
                    }

                default:
                    return state
            }
        }

        case SET_SUBMISSION_SCORE_SUCCESS: {
            const submissions = [...state.examSubmissions]
            const { data } = action.payload

            const updatedSubmission = submissions.reduce((res, submission) => {
                if (submission._id === data._id) {
                    res.push({ ...submission, ...data })
                } else {
                    res.push(submission)
                }

                return res
            }, [])

            return {
                ...state,
                examSubmissions: sortByDate(updatedSubmission, 'submitted_at', state.sortType),
            }
        }

        case CLEAR_SUBMISSION_SCORE_SUCCESS: {
            const submissions = [...state.examSubmissions]
            const { data } = action.payload

            if (!data.scores) {
                data.scores = {}
            }

            const updatedSubmission = submissions.reduce((res, submission) => {
                if (submission._id === data._id) {
                    res.push({ ...submission, ...data })
                } else {
                    res.push(submission)
                }

                return res
            }, [])

            return {
                ...state,
                examSubmissions: sortByDate(updatedSubmission, 'submitted_at', state.sortType),
            }
        }

        case ADD_SUBMISSION_COMMENT_SUCCESS: {
            const {
                res: { data },
                submissionId,
            } = action.payload
            const _examSubmissions = [...state.examSubmissions]

            _examSubmissions.forEach((submission) => {
                if (submission._id === submissionId) {
                    // eslint-disable-next-line no-param-reassign
                    submission.comments = data
                    setStorage('submission', submission)
                }
            })

            return {
                ...state,
                examSubmissions: _examSubmissions,
            }
        }

        case DELETE_SUBMISSION_COMMENT_SUCCESS: {
            const {
                res: { data },
                submissionId,
            } = action.payload
            const _examSubmissions = [...state.examSubmissions]

            _examSubmissions.forEach((submission) => {
                if (submission._id === submissionId) {
                    // eslint-disable-next-line no-param-reassign
                    submission.comments = data
                    setStorage('submission', submission)
                }
            })

            return {
                ...state,
                examSubmissions: _examSubmissions,
            }
        }

        case persistActions.PERSIST_SHOW_LNG_ICONS:
            return {
                ...state,
                showLanguageIcons: action.payload,
            }

        case FILTER_QUESTION_BY_LNG_SUCCESS:
            return {
                ...state,
                quickFilter: action.payload,
            }

        case REMOVE_QUICK_FILTER:
        case FILTER_QUESTION_BY_LNG_FAIL:
            return {
                ...state,
                quickFilter: null,
            }

        case persistActions.PERSIST_PAGE_SIZE:
            return {
                ...state,
                limit: action.payload,
            }

        default:
            return state
    }
}
