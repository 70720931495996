import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { Header, Grid, Form, Checkbox, Button, Icon, Dropdown } from 'semantic-ui-react'
import { Tag } from 'antd'
import styles from './Nav.module.css'

const { CheckableTag } = Tag
const FiltersPage = ({
    role,
    onchangeFilter,
    savedFilters,
    onClearFilters,
    onCloseFilters,
    persistedCodingLanguages,
    tags,
    tagOptions,
}) => {
    const [levelBasic, setLevelBasic] = useState(true)
    const [isLive, setIsLive] = useState(true)
    const [levelIntermediate, setLevelIntermediate] = useState(true)
    const [levelAdvanced, setLevelAdvanced] = useState(true)
    const [questionTags, setQuestionTags] = useState([])
    const [languages, setLanguages] = useState([])

    useEffect(() => {
        if (persistedCodingLanguages) {
            const arr = []
            // eslint-disable-next-line no-underscore-dangle
            persistedCodingLanguages.forEach((l) => arr.push(l._id))
            setLanguages(arr)
        }
    }, [persistedCodingLanguages])

    useEffect(() => {
        if (savedFilters) {
            const {
                levels: { basics, intermediate, advanced },
                tags,
                isLive,
                languages,
            } = savedFilters
            setLevelBasic(basics)
            setLevelIntermediate(intermediate)
            setLevelAdvanced(advanced)
            setQuestionTags([...tags])
            setIsLive(isLive)
            setLanguages(languages)
        }
    }, [savedFilters])

    const handleChangeTag = (tag, checked) => {
        const nextSelectedTags = checked
            ? [...questionTags, tag]
            : questionTags.filter((t) => t !== tag)

        setQuestionTags(nextSelectedTags)
    }

    const handleApplyFilters = () => {
        const data = {
            levels: {
                basics: levelBasic,
                intermediate: levelIntermediate,
                advanced: levelAdvanced,
            },
            tags: [...questionTags],
            isLive,
            languages,
        }
        onchangeFilter(data)
    }

    const onRemoveAllTags = () => {
        setQuestionTags([])
    }

    const onSelectAllTags = () => {
        setQuestionTags(tags)
    }

    const renderTags = () =>
        tags.map((t, i) => {
            const isChecked = questionTags.includes(t)
            return (
                <CheckableTag
                    key={t}
                    className={isChecked ? styles.checkTag_checked : styles.checkTag}
                    style={{ marginBottom: 4 }}
                    checked={questionTags.indexOf(t) > -1}
                    onChange={(checked) => handleChangeTag(t, checked)}
                >
                    {t}
                </CheckableTag>
            )
        })

    const changeSelectedLanguage = (data, key) => {
        const { checked } = data
        if (!checked) {
            // eslint-disable-next-line no-underscore-dangle
            const lngs = languages.filter((l) => l !== key)
            const arr = []
            if (lngs) {
                // eslint-disable-next-line no-underscore-dangle
                lngs.forEach((l) => arr.push(l))
            }
            setLanguages(arr)
        } else {
            const lngs = [...languages]
            lngs.push(key)
            setLanguages(lngs)
        }
    }

    return (
        <Grid columns={3} divided>
            <Grid.Row>
                <Grid.Column width={10}>
                    <Form>
                        <Header as="h4">Tags</Header>
                        <div className={styles.searchTagsWrapper}>
                            <Dropdown
                                clearable
                                fluid
                                multiple
                                search
                                selection
                                options={tagOptions}
                                onChange={(e, data) => {
                                    setQuestionTags(data.value)
                                }}
                                value={questionTags}
                                placeholder="Search ..."
                            />
                        </div>
                        <Form.Field>{renderTags()}</Form.Field>
                    </Form>
                </Grid.Column>
                <Grid.Column width={3}>
                    <Form>
                        <Header as="h4">Level</Header>
                        <Form.Field>
                            <Checkbox
                                label="Basics and Main Concepts"
                                onChange={(e, data) => {
                                    setLevelBasic(data.checked)
                                }}
                                checked={levelBasic}
                            />
                        </Form.Field>
                        <Form.Field>
                            <Checkbox
                                label="Intermediate"
                                onChange={(e, data) => {
                                    setLevelIntermediate(data.checked)
                                }}
                                checked={levelIntermediate}
                            />
                        </Form.Field>
                        <Form.Field>
                            <Checkbox
                                label="Advanced"
                                onChange={(e, data) => {
                                    setLevelAdvanced(data.checked)
                                }}
                                checked={levelAdvanced}
                            />
                        </Form.Field>
                        {role === 'Admin' && (
                            <>
                                <Header as="h4">Live</Header>
                                <Form.Field>
                                    <Checkbox
                                        label="Live questions"
                                        onChange={(e, data) => {
                                            setIsLive(data.checked)
                                        }}
                                        checked={isLive}
                                    />
                                </Form.Field>
                            </>
                        )}
                    </Form>
                </Grid.Column>
                <Grid.Column width={3}>
                    <Form>
                        <Header as="h4">Language</Header>
                        {persistedCodingLanguages &&
                            persistedCodingLanguages.map((pl) => (
                                <Form.Field key={pl.key}>
                                    <Checkbox
                                        label={pl.name}
                                        checked={languages.includes(pl.key) ? true : false}
                                        // disabled={
                                        //     pl.name === 'HTML' || pl.name === 'CSS' ? true : false
                                        // }
                                        onChange={(e, data) => {
                                            changeSelectedLanguage(data, pl.key)
                                        }}
                                    />
                                </Form.Field>
                            ))}
                    </Form>
                </Grid.Column>
            </Grid.Row>
            <Grid.Row style={{ paddingBottom: 0 }}>
                <Grid.Column width={16} className={styles.filterActionsWrapper}>
                    <div>
                        <Button
                            floated="right"
                            className={styles.applyButton}
                            onClick={handleApplyFilters}
                            disabled={languages.length === 0}
                            icon
                            labelPosition="left"
                        >
                            <Icon name="check" />
                            Apply
                        </Button>
                        <Button floated="right" icon labelPosition="left" onClick={onCloseFilters}>
                            <Icon name="close" />
                            Close
                        </Button>
                        <Button floated="right" icon labelPosition="left" onClick={onClearFilters}>
                            <Icon name="redo" />
                            Reset
                        </Button>
                    </div>
                    <div>
                        <Button floated="right" icon onClick={onSelectAllTags}>
                            Select All
                        </Button>
                        <Button floated="right" icon onClick={onRemoveAllTags}>
                            Clear Tags
                        </Button>
                    </div>
                </Grid.Column>
            </Grid.Row>
        </Grid>
    )
}

FiltersPage.defaultProps = {
    savedFilters: null,
    onClearFilters: () => {},
    onCloseFilters: () => {},
    role: 'User',
    persistedCodingLanguages: null,
}

FiltersPage.propTypes = {
    role: PropTypes.string,
    onchangeFilter: PropTypes.func.isRequired,
    savedFilters: PropTypes.object,
    onClearFilters: PropTypes.func,
    onCloseFilters: PropTypes.func,
    persistedCodingLanguages: PropTypes.array,
    tags: PropTypes.array.isRequired,
    tagOptions: PropTypes.array.isRequired,
}

export default FiltersPage
