import * as actions from './tags.action.types'

const initialState = {
    isFetching: false,
    tags: [],
    count: 0,
    added: false,
    questionTitlesForDupTags: [],
}

export default (state = initialState, action) => {
    switch (action.type) {
        case actions.LIST_TAGS_START:
        case actions.ADD_TAG_START:
        case actions.DELETE_TAG_START:
            return {
                ...state,
                isFetching: true,
                added: false,
            }
        case actions.LIST_TAGS_FAIL:
        case actions.ADD_TAG_FAIL:
            return {
                ...state,
                isFetching: false,
                added: false,
            }

        case actions.DELETE_TAG_FAIL: {
            const { data } = action.payload.response.data
            return {
                ...state,
                isFetching: false,
                added: false,
                questionTitlesForDupTags: data ? data : [],
            }
        }

        case actions.LIST_TAGS_SUCCESS: {
            const { count, tags } = action.payload
            return {
                ...state,
                isFetching: false,
                added: false,
                tags,
                count,
                questionTitlesForDupTags: [],
            }
        }

        case actions.REMOVE_QUESTION_TITLES:
            return {
                ...state,
                questionTitlesForDupTags: [],
            }

        case actions.ADD_TAG_SUCCESS:
            return {
                ...state,
                isFetching: false,
                added: true,
            }

        default:
            return state
    }
}
