// QUESTIONS
export const LIST_QUESTIONS_START = 'questions/LIST_QUESTIONS_START'
export const LIST_QUESTIONS_SUCCESS = 'questions/LIST_QUESTIONS_SUCCESS'
export const LIST_QUESTIONS_FAIL = 'questions/LIST_QUESTIONS_FAIL'

export const LIST_FILTERED_QUESTIONS_START = 'questions/LIST_FILTERED_QUESTIONS_START'
export const LIST_FILTERED_QUESTIONS_SUCCESS = 'questions/LIST_FILTERED_QUESTIONS_SUCCESS'
export const LIST_FILTERED_QUESTIONS_FAIL = 'questions/LIST_FILTERED_QUESTIONS_FAIL'

export const ADD_QUESTION_START = 'questions/ADD_QUESTION_START'
export const ADD_QUESTION_SUCCESS = 'questions/ADD_QUESTION_SUCCESS'
export const ADD_QUESTION_FAIL = 'questions/ADD_QUESTION_FAIL'

export const DELETE_QUESTION_START = 'questions/DELETE_QUESTION_START'
export const DELETE_QUESTION_SUCCESS = 'questions/DELETE_QUESTION_SUCCESS'
export const DELETE_QUESTION_FAIL = 'questions/DELETE_QUESTION_FAIL'

export const EDIT_QUESTION_START = 'questions/EDIT_QUESTION_START'
export const EDIT_QUESTION_SUCCESS = 'questions/EDIT_QUESTION_SUCCESS'
export const EDIT_QUESTION_FAIL = 'questions/EDIT_QUESTION_FAIL'

export const REMOVE_ADDED_QUESTION_ID = 'questions/REMOVE_ADDED_QUESTION_ID'

export const GET_QUESTION_START = 'questions/GET_QUESTION_START'
export const GET_QUESTION_SUCCESS = 'questions/GET_QUESTION_SUCCESS'
export const GET_QUESTION_FAIL = 'questions/GET_QUESTION_FAIL'

export const GET_QUESTION_LANGUAGE_START = 'questions/GET_QUESTION_LANGUAGE_START'
export const GET_QUESTION_LANGUAGE_SUCCESS = 'questions/GET_QUESTION_LANGUAGE_SUCCESS'
export const GET_QUESTION_LANGUAGE_FAIL = 'questions/GET_QUESTION_LANGUAGE_FAIL'

export const LIST_BOOKMARKED_QUESTIONS_START = 'questions/LIST_BOOKMARKED_QUESTIONS_START'
export const LIST_BOOKMARKED_QUESTIONS_SUCCESS = 'questions/LIST_BOOKMARKED_QUESTIONS_SUCCESS'
export const LIST_BOOKMARKED_QUESTIONS_FAIL = 'questions/LIST_BOOKMARKED_QUESTIONS_FAIL'

export const SEARCH_QUESTION_START = 'questions/SEARCH_QUESTION_START'
export const SEARCH_QUESTION_SUCCESS = 'questions/SEARCH_QUESTION_SUCCESS'
export const SEARCH_QUESTION_FAIL = 'questions/SEARCH_QUESTION_FAIL'

export const SEARCH_FILTERED_QUESTION_START = 'questions/SEARCH_FILTERED_QUESTION_START'
export const SEARCH_FILTERED_QUESTION_SUCCESS = 'questions/SEARCH_FILTERED_QUESTION_SUCCESS'
export const SEARCH_FILTERED_QUESTION_FAIL = 'questions/SEARCH_FILTERED_QUESTION_FAIL'

export const GET_QUESTIONS_STATS_START = 'questions/GET_QUESTIONS_STATS_START'
export const GET_QUESTIONS_STATS_SUCCESS = 'questions/GET_QUESTIONS_STATS_SUCCESS'
export const GET_QUESTIONS_STATS_FAIL = 'questions/GET_QUESTIONS_STATS_FAIL'

// SECTIONS
export const EDIT_SECTION_START = 'sections/EDIT_SECTION_START'
export const EDIT_SECTION_SUCCESS = 'sections/EDIT_SECTION_SUCCESS'
export const EDIT_SECTION_FAIL = 'sections/EDIT_SECTION_FAIL'

export const ADD_SECTION_START = 'sections/ADD_SECTION_START'
export const ADD_SECTION_SUCCESS = 'sections/ADD_SECTION_SUCCESS'
export const ADD_SECTION_FAIL = 'sections/ADD_SECTION_FAIL'

export const DELETE_SECTION_START = 'sections/DELETE_SECTION_START'
export const DELETE_SECTION_SUCCESS = 'sections/DELETE_SECTION_SUCCESS'
export const DELETE_SECTION_FAIL = 'sections/DELETE_SECTION_FAIL'

export const TOGGLE_SECTION_BOOKMARK_START = 'sections/TOGGLE_SECTION_BOOKMARK_START'
export const TOGGLE_SECTION_BOOKMARK_SUCCESS = 'sections/TOGGLE_SECTION_BOOKMARK_SUCCESS'
export const TOGGLE_SECTION_BOOKMARK_FAIL = 'sections/TOGGLE_SECTION_BOOKMARK_FAIL'

// BOOKMARKS
export const LIST_USER_BOOKMARKS_START = 'bookmarks/LIST_USER_BOOKMARKS_START'
export const LIST_USER_BOOKMARKS_SUCCESS = 'bookmarks/LIST_USER_BOOKMARKS_SUCCESS'
export const LIST_USER_BOOKMARKS_FAIL = 'bookmarks/LIST_USER_BOOKMARKS_FAIL'

export const LIST_USER_SECTION_BOOKMARKS_START = 'bookmarks/LIST_USER_SECTION_BOOKMARKS_START'
export const LIST_USER_SECTION_BOOKMARKS_SUCCESS = 'bookmarks/LIST_USER_SECTION_BOOKMARKS_SUCCESS'
export const LIST_USER_SECTION_BOOKMARKS_FAIL = 'bookmarks/LIST_USER_SECTION_BOOKMARKS_FAIL'

export const CLEAR_ALL_USER_BOOKMARKS_START = 'bookmarks/CLEAR_ALL_USER_BOOKMARKS_START'
export const CLEAR_ALL_USER_BOOKMARKS_SUCCESS = 'bookmarks/CLEAR_ALL_USER_BOOKMARKS_SUCCESS'
export const CLEAR_ALL_USER_BOOKMARKS_FAIL = 'bookmarks/CLEAR_ALL_USER_BOOKMARKS_FAIL'

export const ADD_USER_BOOKMARK_START = 'bookmarks/ADD_USER_BOOKMARK_START'
export const ADD_USER_BOOKMARK_SUCCESS = 'bookmarks/ADD_USER_BOOKMARK_SUCCESS'
export const ADD_USER_BOOKMARK_FAIL = 'bookmarks/ADD_USER_BOOKMARK_FAIL'

export const REMOVE_USER_BOOKMARK_START = 'bookmarks/REMOVE_USER_BOOKMARK_START'
export const REMOVE_USER_BOOKMARK_SUCCESS = 'bookmarks/REMOVE_USER_BOOKMARK_SUCCESS'
export const REMOVE_USER_BOOKMARK_FAIL = 'bookmarks/REMOVE_USER_BOOKMARK_FAIL'

export const SEARCH_BOOKMARK_QUESTION_START = 'bookmarks/SEARCH_BOOKMARK_QUESTION_START'
export const SEARCH_BOOKMARK_QUESTION_SUCCESS = 'bookmarks/SEARCH_BOOKMARK_QUESTION_SUCCESS'
export const SEARCH_BOOKMARK_QUESTION_FAIL = 'bookmarks/SEARCH_BOOKMARK_QUESTION_FAIL'

export const FILTER_QUESTION_BY_LNG_START = 'question/FILTER_QUESTION_BY_LNG_START'
export const FILTER_QUESTION_BY_LNG_SUCCESS = 'question/FILTER_QUESTION_BY_LNG_SUCCESS'
export const FILTER_QUESTION_BY_LNG_SUCCESS_BOOKMARK =
    'question/FILTER_QUESTION_BY_LNG_SUCCESS_BOOKMARK'
export const FILTER_QUESTION_BY_LNG_FAIL = 'question/FILTER_QUESTION_BY_LNG_FAIL'

export const SEARCH_FILTER_QUESTION_BY_LNG_START = 'question/SEARCH_FILTER_QUESTION_BY_LNG_START'
export const SEARCH_FILTER_QUESTION_BY_LNG_SUCCESS =
    'question/SEARCH_FILTER_QUESTION_BY_LNG_SUCCESS'
export const SEARCH_FILTER_QUESTION_BY_LNG_FAIL = 'question/SEARCH_FILTER_QUESTION_BY_LNG_FAIL'

export const LIST_BOOKMARK_FILTERED_QUESTION_START =
    'bookmarks/LIST_BOOKMARK_FILTERED_QUESTION_START'
export const LIST_BOOKMARK_FILTERED_QUESTION_SUCCESS =
    'bookmarks/LIST_BOOKMARK_FILTERED_QUESTION_SUCCESS'
export const LIST_BOOKMARK_FILTERED_QUESTION_FAIL = 'bookmarks/LIST_BOOKMARK_FILTERED_QUESTION_FAIL'

export const REMOVE_QUICK_FILTER = 'filter/REMOVE_QUICK_FILTER'
