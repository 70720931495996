import React from 'react'
import PropTypes from 'prop-types'
import styles from './Login.module.css'

const LoginLayout = ({ children, history }) => {
    return (
        <div className={styles.root}>
            <main className={styles.content}>{children}</main>
        </div>
    )
}

LoginLayout.propTypes = {
    children: PropTypes.array.isRequired,
    history: PropTypes.object.isRequired,
}

export default LoginLayout
