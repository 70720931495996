/* eslint-disable consistent-return */
export const sortByDate = (arr, dateKey, mode) => {
    if (mode === 'asc') {
        arr.sort((a, b) => {
            const dateA = new Date(a[dateKey])
            const dateB = new Date(b[dateKey])
            return dateA - dateB
        })
    }
    if (mode === 'desc') {
        arr.sort((a, b) => {
            const dateA = new Date(a[dateKey])
            const dateB = new Date(b[dateKey])
            return dateB - dateA
        })
    }

    return arr
}

export const generateDateTimeSting = (date = Date.now()) => {
    const dateTimeString = `${new Date(date).toLocaleDateString('en-CA', {
        year: 'numeric',
        month: 'long',
        day: '2-digit',
    })}  (${new Date(date).toLocaleTimeString([], {
        hour: '2-digit',
        minute: '2-digit',
        second: '2-digit',
    })})`

    return dateTimeString
}

export const generateTimeSting = (date = Date.now()) => {
    const timeString = `${new Date(date).toLocaleTimeString('en-CA', {
        hour: '2-digit',
        minute: '2-digit',
        second: '2-digit',
    })}`

    return timeString
}

export const setLocalStorage = (key, data) => {
    window.localStorage.setItem(key, JSON.stringify(data))
}

export const getLocalStorage = (key) => {
    return JSON.parse(window.localStorage.getItem(key))
}

export const removeLocalStorage = (key) => {
    return window.localStorage.removeItem(key)
}
