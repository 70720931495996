/* eslint-disable no-underscore-dangle */
import { format } from 'date-fns'

const generateDifficultyLevels = (levelBasic, levelIntermediate, levelAdvanced) => {
    const strArr = []
    let str = ''
    if (levelBasic) strArr.push('basics')
    if (levelIntermediate) strArr.push('intermediate')
    if (levelAdvanced) strArr.push('advanced')

    str = strArr.reduce((res, level) => {
        if (res) {
            // eslint-disable-next-line no-param-reassign
            res = `${res}, ${level}`
        } else {
            // eslint-disable-next-line no-param-reassign
            res = level
        }
        return res
    }, '')

    return str
}

const generateLanguages = (value, persistedCodingLanguages) => {
    const languagesArr = []
    persistedCodingLanguages.forEach((v) => {
        value.includes(v._id) && languagesArr.push(v.name)
    })
    const arrStr = languagesArr.length > 0 && languagesArr.reduce((pre, curr) => `${pre}, ${curr}`)

    return arrStr
}

const renderEstimatedTime = (estimatedTime) => {
    if (estimatedTime && estimatedTime > 0) {
        return `${estimatedTime} ${estimatedTime === 1 ? 'minute' : 'minutes'}`
    }

    return `NO Time-Limit (open exam)`
}

export const transformData = (data, persistedCodingLanguages) => {
    const result = {}
    const { data: exam } = data
    for (const [k, v] of Object.entries(exam)) {
        if (
            k !== 'levelBasic' &&
            k !== 'levelIntermediate' &&
            k !== 'levelAdvanced' &&
            k !== 'tagsCount' &&
            k !== 'created_at' &&
            k !== 'estimatedTime' &&
            k !== 'languages' &&
            k !== 'examTitle' &&
            k !== 'examDesc'
        ) {
            result[k] = v
        }

        if (k === 'estimatedTime') {
            result.estimatedTime = renderEstimatedTime(v)
            result.originalEstimatedTime = v
        }
        // if (k === 'estimatedTime') {
        //     if (v && v > 0) {
        //         result.estimatedTime = `${v} ${v === 1 ? 'minute' : 'minutes'}`
        //     }
        // }

        if (k === 'created_at') {
            result.created_at = format(new Date(v), 'dd MMMM yyyy, hh:mm aaaa')
        }
    }

    result.title = exam.examTitle
    result.desc = exam.examDesc
    result.user_id = exam.user_id
    result.created_at_stamp = exam.created_at

    result.languages = generateLanguages(exam.languages, persistedCodingLanguages)

    result.levels = generateDifficultyLevels(
        exam.levelBasic,
        exam.levelIntermediate,
        exam.levelAdvanced
    )

    result.examHasExpiry = exam.examHasExpiry
    result.isExpired = exam.isExpired

    return result
}

// eslint-disable-next-line camelcase
export const transformData_persisted_exam = (exam, persistedCodingLanguages) => {
    const result = {}
    for (const [k, v] of Object.entries(exam)) {
        if (
            k !== 'levelBasic' &&
            k !== 'levelIntermediate' &&
            k !== 'levelAdvanced' &&
            k !== 'tagsCount' &&
            k !== 'created_at' &&
            k !== 'estimatedTime' &&
            k !== 'languages' &&
            k !== 'examTitle' &&
            k !== 'examDesc'
        ) {
            result[k] = v
        }

        if (k === 'estimatedTime') {
            result.estimatedTime = renderEstimatedTime(v)
            result.originalEstimatedTime = v
        }

        if (k === 'created_at') {
            result.created_at = format(new Date(v), 'dd MMMM yyyy, hh:mm aaaa')
        }
    }

    result.title = exam.examTitle
    result.desc = exam.examDesc
    result.user_id = exam.user_id
    result.created_at_stamp = exam.created_at

    result.languages = generateLanguages(exam.languages, persistedCodingLanguages)

    result.levels = generateDifficultyLevels(
        exam.levelBasic,
        exam.levelIntermediate,
        exam.levelAdvanced
    )

    result.examHasExpiry = exam.examHasExpiry
    result.isExpired = exam.isExpired

    return result
}

export const transformValidatedExamQuestions = (data) => {
    const { questions, ...rest } = data
    const result = { ...rest, questions: { ...questions }, stats: [] }
    for (const [language, v] of Object.entries(questions)) {
        let cntBasics = 0
        let cntIntermediate = 0
        let cntAdvanced = 0
        const { data: languageQuestions } = v

        languageQuestions.forEach((lq) => {
            if (lq.difficulty === 'basics') cntBasics++
            if (lq.difficulty === 'intermediate') cntIntermediate++
            if (lq.difficulty === 'advanced') cntAdvanced++
        })

        result.stats.push({
            name: language,
            basics: cntBasics,
            intermediate: cntIntermediate,
            advanced: cntAdvanced,
        })
    }

    return result
}

export const transformExamQuestions = (data) => {
    const { data: questions, count } = data
    const result = {}

    const filteredQuestions = questions.reduce((res, curr) => {
        const { sections: s, __v: v, created_at: ca, isLive: il, user_id: ui, ...rest } = curr
        rest.sections = []
        rest.key = curr._id
        res.push(rest)

        return res
    }, [])

    result.questions = filteredQuestions
    result.count = count

    return result
}
