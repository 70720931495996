// TEST CENTRE
export const LIST_TESTS_START = 'exam/LIST_TESTS_START'
export const LIST_TESTS_SUCCESS = 'exam/LIST_TESTS_SUCCESS'
export const LIST_TESTS_FAIL = 'exam/LIST_TESTS_FAIL'

export const GENERATE_EXAM_START = 'exam/GENERATE_EXAM_START'
export const GENERATE_EXAM_SUCCESS = 'exam/GENERATE_EXAM_SUCCESS'
export const GENERATE_EXAM_FAIL = 'exam/GENERATE_EXAM_FAIL'

export const DELETE_EXAM_START = 'exam/DELETE_EXAM_START'
export const DELETE_EXAM_SUCCESS = 'exam/DELETE_EXAM_SUCCESS'
export const DELETE_EXAM_FAIL = 'exam/DELETE_EXAM_FAIL'

export const SEARCH_EXAM_START = 'exam/SEARCH_EXAM_START'
export const SEARCH_EXAM_SUCCESS = 'exam/SEARCH_EXAM_SUCCESS'
export const SEARCH_EXAM_FAIL = 'exam/SEARCH_EXAM_FAIL'

export const SORT_EXAM_ASC = 'exam/SORT_EXAM_ASC'
export const SORT_EXAM_DESC = 'exam/SORT_EXAM_DESC'

export const UPDATE_EXAMS_COUNT = 'exam/UPDATE_EXAMS_COUNT'

export const ADD_USER_EXAM_BOOKMARK_START = 'exam/ADD_USER_EXAM_BOOKMARK_START'
export const ADD_USER_EXAM_BOOKMARK_SUCCESS = 'exam/ADD_USER_EXAM_BOOKMARK_SUCCESS'
export const ADD_USER_EXAM_BOOKMARK_FAIL = 'exam/ADD_USER_EXAM_BOOKMARK_FAIL'

export const REMOVE_USER_EXAM_BOOKMARK_START = 'exam/REMOVE_USER_EXAM_BOOKMARK_START'
export const REMOVE_USER_EXAM_BOOKMARK_SUCCESS = 'exam/REMOVE_USER_EXAM_BOOKMARK_SUCCESS'
export const REMOVE_USER_EXAM_BOOKMARK_FAIL = 'exam/REMOVE_USER_EXAM_BOOKMARK_FAIL'

export const LIST_USER_EXAM_BOOKMARKS_START = 'exam/LIST_USER_EXAM_BOOKMARKS_START'
export const LIST_USER_EXAM_BOOKMARKS_SUCCESS = 'exam/LIST_USER_EXAM_BOOKMARKS_SUCCESS'
export const LIST_USER_EXAM_BOOKMARKS_FAIL = 'exam/LIST_USER_EXAM_BOOKMARKS_FAIL'

export const VALIDATE_EXAM_QUESTIONS_START = 'exam/VALIDATE_EXAM_QUESTIONS_START'
export const VALIDATE_EXAM_QUESTIONS_SUCCESS = 'exam/VALIDATE_EXAM_QUESTIONS_SUCCESS'
export const VALIDATE_EXAM_QUESTIONS_FAIL = 'exam/VALIDATE_EXAM_QUESTIONS_FAIL'

export const LIST_USER_BOOKMARKED_EXAMS = 'exam/LIST_USER_BOOKMARKED_EXAMS'
export const CLEAR_USER_BOOKMARKED_EXAMS = 'exam/CLEAR_USER_BOOKMARKED_EXAMS'
export const REPLCAE_EXAMS_WITH_CAHCED_EXAMS = 'exam/REPLCAE_EXAMS_WITH_CAHCED_EXAMS'
export const CACHE_SHOULD_LOAD_BOOKMARKS = 'exam/CACHE_SHOULD_LOAD_BOOKMARKS'

export const CLEAR_EXAM_QUESTIONS = 'exam/CLEAR_EXAM_QUESTIONS'
