import * as actions from './questions.action.types'

export const listQuestions = (...data) => ({
    type: actions.LIST_QUESTIONS_START,
    payload: data,
})

export const listFilteredQuestions = (...data) => ({
    type: actions.LIST_FILTERED_QUESTIONS_START,
    payload: data,
})

export const getQuestion = (...data) => ({
    type: actions.GET_QUESTION_START,
    payload: data,
})

export const removeAddedQuestionId = () => ({
    type: actions.REMOVE_ADDED_QUESTION_ID,
})

export const addQuestion = (...data) => ({
    type: actions.ADD_QUESTION_START,
    payload: data,
})

export const editQuestion = (...data) => ({
    type: actions.EDIT_QUESTION_START,
    payload: data,
})

export const deleteQuestion = (...data) => ({
    type: actions.DELETE_QUESTION_START,
    payload: data,
})

export const editSection = (...data) => ({
    type: actions.EDIT_SECTION_START,
    payload: data,
})

export const addSection = (...data) => ({
    type: actions.ADD_SECTION_START,
    payload: data,
})

export const deleteSection = (...data) => ({
    type: actions.DELETE_SECTION_START,
    payload: data,
})

export const listBookmarks = (...data) => ({
    type: actions.LIST_USER_BOOKMARKS_START,
    payload: data,
})

export const listSectionBookmarks = (...data) => ({
    type: actions.LIST_USER_SECTION_BOOKMARKS_START,
    payload: data,
})

export const addBookmark = (...data) => ({
    type: actions.ADD_USER_BOOKMARK_START,
    payload: data,
})

export const removeBookmark = (...data) => ({
    type: actions.REMOVE_USER_BOOKMARK_START,
    payload: data,
})

export const listBookmarkedQuestion = (...data) => ({
    type: actions.LIST_BOOKMARKED_QUESTIONS_START,
    payload: data,
})

export const searchQuestion = (...data) => ({
    type: actions.SEARCH_QUESTION_START,
    payload: data,
})

export const searchFilteredQuestion = (...data) => ({
    type: actions.SEARCH_FILTERED_QUESTION_START,
    payload: data,
})

export const searchBookmarkQuestion = (...data) => ({
    type: actions.SEARCH_BOOKMARK_QUESTION_START,
    payload: data,
})

export const listBookmarkFilteredQuestion = (...data) => ({
    type: actions.LIST_BOOKMARK_FILTERED_QUESTION_START,
    payload: data,
})

export const clearrAllBookmarks = (...data) => ({
    type: actions.CLEAR_ALL_USER_BOOKMARKS_START,
    payload: data,
})

export const toggleSectionBookmark = (...data) => ({
    type: actions.TOGGLE_SECTION_BOOKMARK_START,
    payload: data,
})

export const listQuestionLanguages = (token) => ({
    type: actions.GET_QUESTION_LANGUAGE_START,
    payload: token,
})

export const listQuestionsStats = (token) => ({
    type: actions.GET_QUESTIONS_STATS_START,
    payload: token,
})

export const filterQuestionByLanguage = (...data) => ({
    type: actions.FILTER_QUESTION_BY_LNG_START,
    payload: data,
})

export const searchFilterQuestionByLanguage = (...data) => ({
    type: actions.SEARCH_FILTER_QUESTION_BY_LNG_START,
    payload: data,
})

export const removeQuickFilters = () => ({
    type: actions.REMOVE_QUICK_FILTER,
})
