import React, { Suspense } from 'react'
import PropTypes from 'prop-types'
import { bindActionCreators } from 'redux'
import { withRouter, Route } from 'react-router-dom'
import { connect } from 'react-redux'
import { compose } from 'recompose'
import MainLayout from '../../shared.components/main.layout'
import Spinner from '../../ui.library/Spinner'
import {
    logoutUser,
    checkUserIsAuthenticated,
    persistSelectedTheme,
} from '../../pages/Auth/login/engine/login.actions'
import {
    getAccessToken,
    getUserRole,
    getUserId,
    getFetchStatus,
    getUserData,
    getPersistedTheme,
    getPersistedCodingLanguages,
    getUserExpiredExams,
} from '../../pages/Auth/login/engine/login.selectors'
import * as persistedActions from '../../pages/pages.persisted.actions'
import {
    updateExamSubmitStatus,
    updateExamExpiryStatus,
    listSubmittedExams,
    clearSubmissions,
    setSubmissionScore,
    clearSubmissionScore,
    addExamSubmissionComment,
    deleteExamSubmissionComment,
} from '../../pages/ExamDetails/engine/exam.details.actions'
import {
    getValidatedExamQuestions,
    getFetchStatus as getFetchStatusExam,
} from '../../pages/ExamDetails/engine/exam.details.selectors'
import { getAvailableTags, getAvailableTagNames } from '../../pages/Tags/engine/tags.selectors'
import {
    getPersistedPathname,
    getPersistedToggle,
    getExamSubmissionsCount,
    getSubmissionsSortType,
    getPersistedExams,
    getExamSubmissions,
    getShowIconsStatus,
    getQuickFilterId,
} from '../../pages/pages.selectors'
import {
    filterQuestionByLanguage,
    listQuestions,
    removeQuickFilters,
} from '../../pages/Questions/engine/questions.actions'
import { isInteger } from '../../services/validation'
import { setLocalStorage } from '../../utils'

// CODE-SPLITTING
const Home = React.lazy(() => import('../../pages/Home/home.index'))
const WebUsers = React.lazy(() => import('../../pages/Users/web.users.index'))
const MobileUsers = React.lazy(() => import('../../pages/Users/mobile.users.index'))
const CreateQuestion = React.lazy(() => import('../../pages/CreateQuestion/create.question.index'))
const Questions = React.lazy(() => import('../../pages/Questions/questions.index'))
const Profile = React.lazy(() => import('../../pages/Profile/profile.index'))
const UserFeedback = React.lazy(() => import('../../pages/UserFeedback/user.feedback.index'))
const Settings = React.lazy(() => import('../../pages/Settings/settings.index'))
const Feedbacks = React.lazy(() => import('../../pages/Feedbacks/feedbacks.index'))
const ReportedBugs = React.lazy(() => import('../../pages/ReportedBugs/reported.bugs.index'))
const Tags = React.lazy(() => import('../../pages/Tags/tags.index'))
const Exams = React.lazy(() => import('../../pages/TestCentre/test.centre.index'))
const ExamDetails = React.lazy(() => import('../../pages/ExamDetails/exam.details.index'))
const SubmissionDetails = React.lazy(() =>
    import('../../pages/ExamDetails/components/submission.details')
)

/**
 * MainRoutes
 * this components shows the home page after the user has been successully logged in.
 * @type Container
 * @author Ehsan
 * @version 0.1.0
 */

class MainRoutes extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            filters: null,
            showFilter: false,
            isExpanded: false,
            showNewRandomizedExam: false,
            examTime: null,
            hideSideNav: false,
            examEnded: false,
            endTime: null,
            resumeTime: null,
            beginAt: null,
            tagOptions: [],
            resetPagination: null,
        }
    }

    componentDidMount() {
        const { token, userRole, userId, tagNames } = this.props
        // if (token && userRole && userId) {
        //     const { history } = this.props
        //     if (userRole !== 'Admin') {
        //         history.push('/questions')
        //     }
        // }

        const tagOptions = tagNames.reduce((res, curr) => {
            res.push({
                key: curr,
                value: curr,
                text: curr,
            })

            return res
        }, [])

        this.setState({ tagOptions })
    }

    static getDerivedStateFromProps(props, state) {
        // eslint-disable-next-line react/prop-types
        const { token, userRole, userId } = props
        if (!userRole || !userId || !token) {
            props.history.push('/login')
        }
        return null
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevProps.persistedPathname !== this.props.persistedPathname) {
            // eslint-disable-next-line react/prop-types
            this.props.persistedActions.persistPathname(this.props.persistedPathname)
            // this.props.history.push(this.props.persistedPathname)
        }
        if (prevProps.tagNames !== this.props.tagNames && this.props.tagNames.length > 0) {
            const tagOptions = this.props.tagNames.reduce((res, curr) => {
                res.push({
                    key: curr,
                    value: curr,
                    text: curr,
                })

                return res
            }, [])
            this.setState({ tagOptions })
        }
    }

    handleLogout = () => {
        const self = this
        this.logoutPromise().then(() => {
            self.props.history.push('/login')
        })
    }

    logoutPromise = () =>
        new Promise((resolve, reject) => {
            try {
                const { logoutUser } = this.props
                logoutUser()
                resolve()
            } catch (e) {
                reject(e)
            }
        })

    handleChangeTheme = (theme) => {
        const { persistSelectedTheme } = this.props
        persistSelectedTheme(theme)
        // this.setState({ selectedTheme: theme }, () => {
        // })
    }

    renderNotAuthorized = (path) => (
        <Route
            exact
            path={path}
            render={() => {
                return (
                    <Suspense fallback={<Spinner centerScreen size={15} />}>
                        YOU ARE NOT AUTHORIZED TO VIEW THIS PAGE!
                    </Suspense>
                )
            }}
        />
    )

    handleChangeFilters = (filters) => {
        this.setState({ filters, showFilter: false })
    }

    handleClearFilters = () => {
        this.setState({ filters: null, showFilter: false })
    }

    handleFilterClick = (status) => {
        this.setState({ showFilter: status })
    }

    handleExpandClick = (status) => {
        this.setState({ isExpanded: status })
    }

    handleResetExpand = () => {
        this.setState({ isExpanded: false })
    }

    handleNewRandomizedExam = () => {
        this.setState({ showNewRandomizedExam: true })
    }

    handleCloseRandomizedExam = () => {
        this.setState({ showNewRandomizedExam: false })

        // TODO: clear validatedExamQuestions in the state
    }

    handleBeginExam = (time, hideSideNav, endTime, beginAt) => {
        this.setState({ examTime: time === 0 ? null : time, hideSideNav, endTime, beginAt })
    }

    handleStopExam = (hideSideNav, exam) => {
        window.sessionStorage.removeItem('exam')
        window.sessionStorage.removeItem('examAnswers')
        window.sessionStorage.removeItem('zenIndex')
        this.setState({
            examTime: null,
            hideSideNav,
            endTime: null,
            resumeTime: null,
            beginAt: null,
            examEnded: false,
        })
        this.props.updateExamSubmitStatus(false)

        if (exam) {
            const { token, userId, updateExamExpiryStatus } = this.props
            // eslint-disable-next-line no-underscore-dangle
            updateExamExpiryStatus(token, userId, exam._id)
        }
    }

    handleTimerEnd = () => {
        window.sessionStorage.removeItem('exam')
        window.sessionStorage.removeItem('examAnswers')
        window.sessionStorage.removeItem('zenIndex')
        this.setState({
            examTime: null,
            hideSideNav: false,
            examEnded: true,
            endTime: null,
            resumeTime: null,
            beginAt: null,
        })
        this.props.updateExamSubmitStatus(false)
    }

    handleResumeExam = (totalSeconds, hideSideNav, endTime, beginAt) => {
        this.setState({ resumeTime: totalSeconds, hideSideNav, endTime, beginAt })
    }

    handlePersistToggle = (visible) => {
        // eslint-disable-next-line react/prop-types
        const { persistedActions } = this.props
        // eslint-disable-next-line react/prop-types
        persistedActions.persistToggle(visible)
    }

    handleClearSubmissions = () => {
        const { clearSubmissions } = this.props
        clearSubmissions()
    }

    handleListSubmissions = () => {
        const {
            match: {
                params: { examId },
            },
            listSubmittedExams,
            token,
            submissionsSortType,
        } = this.props
        listSubmittedExams(token, examId, submissionsSortType)
    }

    handleSetSubmissionScore = (submissionId, questionId, score) => {
        const { token, setSubmissionScore } = this.props
        setSubmissionScore(token, submissionId, questionId, score)
    }

    handleClearSubmissionScore = (submissionId, questionId) => {
        const { token, clearSubmissionScore } = this.props
        clearSubmissionScore(token, submissionId, questionId)
    }

    handleSubmitComment = (commentedBy, submissionId, questionId, comment, commentCode) => {
        const { token, addExamSubmissionComment } = this.props
        addExamSubmissionComment(token, commentedBy, submissionId, questionId, comment, commentCode)
    }

    handleRemoveSubmissionComment = (submissionId, commentId) => {
        const { token, deleteExamSubmissionComment } = this.props
        deleteExamSubmissionComment(token, submissionId, commentId)
    }

    handleShowIcons = (show) => {
        // eslint-disable-next-line react/prop-types
        const { persistedActions } = this.props
        // eslint-disable-next-line react/prop-types
        persistedActions.persistShowIcons(show)
    }

    handleRandomizedQuestions = () => {
        const { listQuestions, removeQuickFilters, token, history } = this.props
        removeQuickFilters()
        setLocalStorage('resetPage', true)

        const paramData = this.getDataFromQueryParams(['page', 'limit', 'filter'])
        if (paramData) {
            const { limit } = paramData
            const validLimit = isInteger(Number(limit))
            listQuestions(validLimit ? Number(limit) : 10, 1, token)
            const q = `page=${1}&limit=${validLimit ? limit : 10}`
            history.replace({ ...history.location, search: q.toString() })
        } else {
            listQuestions(10, 1, token)
            const q = `page=${1}&limit=${10}`
            history.replace({ ...history.location, search: q.toString() })
        }
    }

    handleQuickFilter = (language) => {
        const { filterQuestionByLanguage, token, history } = this.props
        // eslint-disable-next-line no-underscore-dangle
        const { _id: lng } = language
        setLocalStorage('resetPage', false)
        const paramData = this.getDataFromQueryParams(['page', 'limit', 'filter'])
        if (paramData) {
            const { limit } = paramData
            const validLimit = isInteger(Number(limit))

            this.setState({
                resetPagination: {
                    limit: Number(limit),
                    page: 1,
                },
            })

            filterQuestionByLanguage(validLimit ? Number(limit) : 10, 1, lng, token)
            const q = `page=${1}&limit=${validLimit ? limit : 10}&filter=${lng}`
            history.replace({ ...history.location, search: q.toString() })
        } else {
            this.setState({
                resetPagination: {
                    limit: 10,
                    page: 1,
                },
            })

            filterQuestionByLanguage(10, 1, lng, token)
            const q = `page=${1}&limit=${10}&filter=${lng}`
            history.replace({ ...history.location, search: q.toString() })
        }
    }

    getDataFromQueryParams = (keys) => {
        const { history } = this.props
        const query = new URLSearchParams(history.location.search)
        const allParams = query.toString().split('&')

        const data = {}
        try {
            for (const key of keys) {
                for (const param of allParams) {
                    const hasKey = param.includes(key)

                    if (hasKey) {
                        const paramData = param.split('=')
                        // eslint-disable-next-line no-unused-vars
                        const [paramKey, paramValue] = paramData
                        data[key] = paramValue
                    }
                }
            }
            return data
        } catch (error) {
            // toastr['error'](`Invalid query param found!`)
            return null
        }
    }

    render() {
        const {
            location,
            history,
            token,
            userRole: role,
            userId,
            isFetching,
            userData,
            persistedTheme,
            persistedCodingLanguages,
            tagNames,
            persistedToggle,
            examQuestions,
            examIsLoading,
            persistedExams,
            persistedSubmissions,
            showLanguageIcons,
            quickFilter,
        } = this.props
        const {
            filters,
            showFilter,
            isExpanded,
            showNewRandomizedExam,
            examTime,
            hideSideNav,
            examEnded,
            endTime,
            resumeTime,
            beginAt,
            tagOptions,
            resetPagination,
        } = this.state

        const {
            match: {
                params: { examId, submissionId },
            },
            examSubmissionsCount,
            userExpiredExams,
        } = this.props

        return (
            <>
                <MainLayout
                    history={history}
                    onLogout={this.handleLogout}
                    role={role}
                    isFetching={isFetching}
                    pathname={location.pathname}
                    onChangeTheme={this.handleChangeTheme}
                    defaultTheme={persistedTheme}
                    onchangeFilter={this.handleChangeFilters}
                    savedFilters={filters}
                    onClearFilters={this.handleClearFilters}
                    onFilterClick={this.handleFilterClick}
                    onExpand={this.handleExpandClick}
                    isExpandedState={isExpanded}
                    persistedCodingLanguages={persistedCodingLanguages}
                    tags={tagNames}
                    onNewRandomizedExam={this.handleNewRandomizedExam}
                    examId={examId}
                    examTime={examTime}
                    hideSideNav={hideSideNav}
                    onTimerEnd={this.handleTimerEnd}
                    endTime={endTime}
                    resumeTime={resumeTime}
                    beginAt={beginAt}
                    onPersistToggle={this.handlePersistToggle}
                    persistedToggle={persistedToggle}
                    onListSubmissions={this.handleListSubmissions}
                    loadSubmissions={
                        examSubmissionsCount && examSubmissionsCount > 0 ? true : false
                    }
                    onClearSubmissions={this.handleClearSubmissions}
                    submissionId={submissionId}
                    tagOptions={tagOptions}
                    onShowIcons={this.handleShowIcons}
                    showLanguageIcons={showLanguageIcons}
                    onRandomize={this.handleRandomizedQuestions}
                    onQuickFilter={this.handleQuickFilter}
                    quickFilter={quickFilter}
                >
                    {/* {location.pathname === '/' && role === 'Admin' && ( */}
                    {location.pathname === '/' && (
                        <Route
                            exact
                            path="/"
                            render={() => {
                                return (
                                    <Suspense fallback={<Spinner centerScreen size={15} />}>
                                        <Home token={token} role={role} userId={userId} />
                                    </Suspense>
                                )
                            }}
                        />
                    )}
                    {location.pathname === '/web-users' && role === 'Admin' && (
                        <Route
                            exact
                            path="/web-users"
                            render={() => {
                                return (
                                    <Suspense fallback={<Spinner centerScreen size={15} />}>
                                        <WebUsers token={token} role={role} />
                                    </Suspense>
                                )
                            }}
                        />
                    )}
                    {location.pathname === '/mobile-users' && role === 'Admin' && (
                        <Route
                            exact
                            path="/mobile-users"
                            render={() => {
                                return (
                                    <Suspense fallback={<Spinner centerScreen size={15} />}>
                                        <MobileUsers token={token} role={role} />
                                    </Suspense>
                                )
                            }}
                        />
                    )}

                    {location.pathname === '/feedbacks' && role === 'Admin' && (
                        <Route
                            exact
                            path="/feedbacks"
                            render={() => {
                                return (
                                    <Suspense fallback={<Spinner centerScreen size={15} />}>
                                        <Feedbacks token={token} role={role} />
                                    </Suspense>
                                )
                            }}
                        />
                    )}

                    {location.pathname === '/reported-bugs' && role === 'Admin' && (
                        <Route
                            exact
                            path="/reported-bugs"
                            render={() => {
                                return (
                                    <Suspense fallback={<Spinner centerScreen size={15} />}>
                                        <ReportedBugs token={token} role={role} />
                                    </Suspense>
                                )
                            }}
                        />
                    )}

                    {location.pathname === '/tags' && role === 'Admin' && (
                        <Route
                            exact
                            path="/tags"
                            render={() => {
                                return (
                                    <Suspense fallback={<Spinner centerScreen size={15} />}>
                                        <Tags token={token} role={role} />
                                    </Suspense>
                                )
                            }}
                        />
                    )}

                    {location.pathname === '/create-question' &&
                        role === 'Admin' &&
                        persistedCodingLanguages &&
                        persistedCodingLanguages.length > 0 && (
                            <Route
                                exact
                                path="/create-question"
                                render={() => {
                                    return (
                                        <Suspense fallback={<Spinner centerScreen size={15} />}>
                                            <CreateQuestion
                                                token={token}
                                                role={role}
                                                selectedTheme={persistedTheme}
                                                codingLanguages={persistedCodingLanguages}
                                                tags={tagNames}
                                            />
                                        </Suspense>
                                    )
                                }}
                            />
                        )}
                    {location.pathname === '/questions' &&
                        persistedCodingLanguages &&
                        persistedCodingLanguages.length > 0 && (
                            <Route
                                exact
                                path="/questions"
                                render={() => {
                                    return (
                                        <Suspense fallback={<Spinner centerScreen size={15} />}>
                                            <Questions
                                                token={token}
                                                role={role}
                                                selectedTheme={persistedTheme}
                                                filters={filters}
                                                onClearFilters={this.handleClearFilters}
                                                showFilter={showFilter}
                                                isExpanded={isExpanded}
                                                onRestExpand={this.handleResetExpand}
                                                codingLanguages={persistedCodingLanguages}
                                                tags={tagNames}
                                                tagOptions={tagOptions}
                                                showLanguageIcons={showLanguageIcons}
                                                quickFilter={quickFilter}
                                                resetPagination={resetPagination}
                                            />
                                        </Suspense>
                                    )
                                }}
                            />
                        )}
                    {location.pathname === '/profile' && (
                        <Route
                            exact
                            path="/profile"
                            render={() => {
                                return (
                                    <Suspense fallback={<Spinner centerScreen size={15} />}>
                                        <Profile token={token} role={role} userData={userData} />
                                    </Suspense>
                                )
                            }}
                        />
                    )}
                    {location.pathname === '/user-feedback' && role === 'Guest' && (
                        <Route
                            exact
                            path="/user-feedback"
                            render={() => {
                                return (
                                    <Suspense fallback={<Spinner centerScreen size={15} />}>
                                        <UserFeedback token={token} role={role} />
                                    </Suspense>
                                )
                            }}
                        />
                    )}
                    {location.pathname === '/settings' && role === 'Admin' && (
                        <Route
                            exact
                            path="/settings"
                            render={() => {
                                return (
                                    <Suspense fallback={<Spinner centerScreen size={15} />}>
                                        <Settings token={token} role={role} />
                                    </Suspense>
                                )
                            }}
                        />
                    )}
                    {location.pathname === '/exams' && (
                        <Route
                            exact
                            path="/exams"
                            render={() => {
                                return (
                                    <Suspense fallback={<Spinner centerScreen size={15} />}>
                                        <Exams
                                            token={token}
                                            role={role}
                                            selectedTheme={persistedTheme}
                                            showNewRandomizedExam={showNewRandomizedExam}
                                            onCloseRandomizedExam={this.handleCloseRandomizedExam}
                                            persistedCodingLanguages={persistedCodingLanguages}
                                            tags={tagNames}
                                            userExpiredExams={userExpiredExams}
                                        />
                                    </Suspense>
                                )
                            }}
                        />
                    )}

                    {location.pathname === `/exams/${examId}` && examId && (
                        <Route
                            exact
                            path="/exams/:examId"
                            render={() => {
                                return (
                                    <Suspense fallback={<Spinner centerScreen size={15} />}>
                                        <ExamDetails
                                            token={token}
                                            role={role}
                                            selectedTheme={persistedTheme}
                                            persistedCodingLanguages={persistedCodingLanguages}
                                            tags={tagNames}
                                            onExamBegin={this.handleBeginExam}
                                            onExamStop={this.handleStopExam}
                                            examEnded={examEnded}
                                            onExamResume={this.handleResumeExam}
                                            userExpiredExams={userExpiredExams}
                                        />
                                    </Suspense>
                                )
                            }}
                        />
                    )}

                    {location.pathname === `/exams/${examId}/${submissionId}` &&
                        examId &&
                        submissionId &&
                        examQuestions && (
                            <Route
                                exact
                                path="/exams/:examId/:submissionId"
                                render={() => {
                                    return (
                                        <Suspense fallback={<Spinner centerScreen size={15} />}>
                                            <SubmissionDetails
                                                token={token}
                                                role={role}
                                                selectedTheme={persistedTheme}
                                                codingLanguages={persistedCodingLanguages}
                                                tags={tagNames}
                                                submissionId={submissionId}
                                                examQuestions={examQuestions}
                                                history={history}
                                                onSetScore={this.handleSetSubmissionScore}
                                                onClearScore={this.handleClearSubmissionScore}
                                                loading={examIsLoading}
                                                userId={userId}
                                                persistedExams={persistedExams}
                                                onSubmitComment={this.handleSubmitComment}
                                                submissions={persistedSubmissions}
                                                onRemoveComment={this.handleRemoveSubmissionComment}
                                            />
                                        </Suspense>
                                    )
                                }}
                            />
                        )}

                    {/* NOT AUTHORIZED PAGES */}
                    {(location.pathname === '/web-users' ||
                        location.pathname === '/settings' ||
                        location.pathname === '/create-question' ||
                        location.pathname === '/mobile-users' ||
                        location.pathname === '/feedbacks' ||
                        location.pathname === '/reported-bugs') &&
                        role !== 'Admin' &&
                        this.renderNotAuthorized(location.pathname)}
                </MainLayout>
            </>
        )
    }
}

MainRoutes.defaultProps = {
    token: null,
    userRole: null,
    userId: null,
    isFetching: false,
    logoutUser: () => {},
    persistSelectedTheme: () => {},
    persistedTheme: 'prism',
    persistedCodingLanguages: null,
    tagNames: [],
    // checkUserIsAuthenticated: () => {},
    examId: '',
    userData: null,
    persistedToggle: true,
    persistedPathname: '/',
    updateExamSubmitStatus: () => {},
    listSubmittedExams: () => {},
    clearSubmissions: () => {},
    setSubmissionScore: () => {},
    clearSubmissionScore: () => {},
    addExamSubmissionComment: () => {},
    deleteExamSubmissionComment: () => {},
    updateExamExpiryStatus: () => {},
    filterQuestionByLanguage: () => {},
    listQuestions: () => {},
    removeQuickFilters: () => {},
    examSubmissionsCount: 0,
    submissionsSortType: 'asc',
    examQuestions: null,
    examIsLoading: false,
    persistedExams: null,
    persistedSubmissions: null,
    userExpiredExams: [],
    showLanguageIcons: false,
    quickFilter: null,
}

MainRoutes.propTypes = {
    token: PropTypes.string,
    userRole: PropTypes.string,
    location: PropTypes.object.isRequired,
    history: PropTypes.object.isRequired,
    userData: PropTypes.object,
    logoutUser: PropTypes.func,
    // checkUserIsAuthenticated: PropTypes.func,
    userId: PropTypes.string,
    persistSelectedTheme: PropTypes.func,
    persistedExams: PropTypes.array,
    isFetching: PropTypes.bool,
    persistedTheme: PropTypes.string,
    persistedCodingLanguages: PropTypes.array,
    tagNames: PropTypes.array,
    examId: PropTypes.string,
    match: PropTypes.object.isRequired,
    persistedPathname: PropTypes.string,
    persistedToggle: PropTypes.bool,
    examSubmissionsCount: PropTypes.number,
    updateExamSubmitStatus: PropTypes.func,
    updateExamExpiryStatus: PropTypes.func,
    listSubmittedExams: PropTypes.func,
    clearSubmissions: PropTypes.func,
    setSubmissionScore: PropTypes.func,
    clearSubmissionScore: PropTypes.func,
    addExamSubmissionComment: PropTypes.func,
    deleteExamSubmissionComment: PropTypes.func,
    submissionsSortType: PropTypes.string,
    examQuestions: PropTypes.object,
    examIsLoading: PropTypes.bool,
    persistedSubmissions: PropTypes.array,
    userExpiredExams: PropTypes.array,
    showLanguageIcons: PropTypes.bool,
    filterQuestionByLanguage: PropTypes.func,
    listQuestions: PropTypes.func,
    removeQuickFilters: PropTypes.func,
    quickFilter: PropTypes.string,
}

const mapStateToProps = (state) => ({
    token: getAccessToken(state),
    userRole: getUserRole(state),
    userId: getUserId(state),
    userData: getUserData(state),
    userExpiredExams: getUserExpiredExams(state),
    isFetching: getFetchStatus(state),
    persistedTheme: getPersistedTheme(state),
    persistedCodingLanguages: getPersistedCodingLanguages(state),
    tagNames: getAvailableTagNames(state),
    persistedPathname: getPersistedPathname(state),
    persistedToggle: getPersistedToggle(state),
    examSubmissionsCount: getExamSubmissionsCount(state),
    submissionsSortType: getSubmissionsSortType(state),
    examQuestions: getValidatedExamQuestions(state),
    examIsLoading: getFetchStatusExam(state),
    persistedExams: getPersistedExams(state),
    persistedSubmissions: getExamSubmissions(state),
    showLanguageIcons: getShowIconsStatus(state),
    quickFilter: getQuickFilterId(state),
})

const mapDispatchToProps = (dispatch) => ({
    logoutUser: bindActionCreators(logoutUser, dispatch),
    checkUserIsAuthenticated: bindActionCreators(checkUserIsAuthenticated, dispatch),
    persistSelectedTheme: bindActionCreators(persistSelectedTheme, dispatch),
    persistedActions: bindActionCreators(persistedActions, dispatch),
    updateExamSubmitStatus: bindActionCreators(updateExamSubmitStatus, dispatch),
    updateExamExpiryStatus: bindActionCreators(updateExamExpiryStatus, dispatch),
    listSubmittedExams: bindActionCreators(listSubmittedExams, dispatch),
    clearSubmissions: bindActionCreators(clearSubmissions, dispatch),
    setSubmissionScore: bindActionCreators(setSubmissionScore, dispatch),
    clearSubmissionScore: bindActionCreators(clearSubmissionScore, dispatch),
    addExamSubmissionComment: bindActionCreators(addExamSubmissionComment, dispatch),
    deleteExamSubmissionComment: bindActionCreators(deleteExamSubmissionComment, dispatch),
    filterQuestionByLanguage: bindActionCreators(filterQuestionByLanguage, dispatch),
    listQuestions: bindActionCreators(listQuestions, dispatch),
    removeQuickFilters: bindActionCreators(removeQuickFilters, dispatch),
})

const enhance = compose(withRouter, connect(mapStateToProps, mapDispatchToProps))

export default enhance(MainRoutes)
