import React from 'react'
import PropTypes from 'prop-types'
import { Button, Header } from 'semantic-ui-react'
import styles from './Notmatch.module.css'

class NoMatch extends React.Component {
    handleBack = () => {
        const { history } = this.props
        history.goBack()
    }

    pushToHome = () => {
        const { history } = this.props
        history.push('/')
    }

    render() {
        const { location } = this.props
        return (
            <div className={styles.noMatchWrapper}>
                <Header as="h1" color="red" className={styles.errorTitle}>
                    404
                </Header>
                <Header as="h2" className={styles.message}>
                    No url match found!
                </Header>
                <p>
                    <code>{location.pathname}</code>
                </p>
                <div className="actions-container">
                    <Button type="submit" onClick={() => this.handleBack()}>
                        Back
                    </Button>
                    <Button type="submit" onClick={() => this.pushToHome()}>
                        Home
                    </Button>
                </div>
            </div>
        )
    }
}

NoMatch.propTypes = {
    location: PropTypes.object.isRequired,
    history: PropTypes.object.isRequired,
}

export default NoMatch
