import * as actions from './feedback.action.types'

const initialState = {
    isFetching: false,
    submitted: false,
    feedbacks: [],
    feedbacksCount: 0,
}

export default (state = initialState, action) => {
    switch (action.type) {
        case actions.ADD_USER_FEEDBACK_START:
            return {
                ...state,
                isFetching: true,
                submitted: false,
            }

        case actions.ADD_USER_FEEDBACK_SUCCESS:
            return {
                ...state,
                isFetching: false,
                submitted: true,
            }

        case actions.CLEAR_SUBMISSION:
        case actions.ADD_USER_FEEDBACK_FAIL:
            return {
                ...state,
                isFetching: false,
                submitted: false,
            }

        case actions.LIST_FEEDBACKS_START:
            return {
                ...state,
                isFetching: true,
            }

        case actions.LIST_FEEDBACKS_SUCCESS: {
            const { total: feedbacksCount, data: feedbacks } = action.payload
            return {
                ...state,
                isFetching: false,
                feedbacksCount,
                feedbacks,
            }
        }

        case actions.LIST_FEEDBACKS_FAIL:
            return {
                ...state,
                isFetching: false,
                feedbacks: [],
                feedbacksCount: 0,
            }

        case actions.DELETE_FEEDBACK_START:
            return {
                ...state,
                isFetching: true,
            }

        case actions.DELETE_FEEDBACK_SUCCESS:
            return {
                ...state,
                isFetching: false,
            }

        case actions.DELETE_FEEDBACK_FAIL:
            return {
                ...state,
                isFetching: false,
            }

        default:
            return state
    }
}
