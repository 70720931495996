import {
    PERSIST_LAST_VISITED_PATHNAME,
    PERSIST_TOGGLE,
    CLEAR_EXAM_SUBMISSIONS,
    PERSIST_SHOW_LNG_ICONS,
    PERSIST_PAGE_SIZE,
} from './pages.persisted.action.types'

export const persistPathname = (pathname) => ({
    type: PERSIST_LAST_VISITED_PATHNAME,
    payload: pathname,
})

export const persistToggle = (visible) => ({
    type: PERSIST_TOGGLE,
    payload: visible,
})

export const clearExamSubmissions = () => ({
    type: CLEAR_EXAM_SUBMISSIONS,
})

export const persistShowIcons = (show) => ({
    type: PERSIST_SHOW_LNG_ICONS,
    payload: show,
})

export const persistPageSize = (limit) => ({
    type: PERSIST_PAGE_SIZE,
    payload: limit,
})
