import React from 'react'
import { Router, Route, Switch } from 'react-router-dom'
import { history } from './store'
import PrivateRoute from './private.route'

// PAGES
import MainRoutes from './main.routes/main.routes.index'
import AuthLayout from './main.routes/auth.route.index'

import NoMatch from '../pages/404/404.index'

/**
 * ROUTER
 * all persisted application's routings
 * this component show all the existing routes in the app
 * @type Router
 * @author Ehsan
 * @version 0.1.1
 */

// const environment = process.env.NODE_ENV
// const showRouteInDevOnly = environment === 'development' ? true : false

const Routes = () => (
    <div className="app-wrapper">
        <Router history={history}>
            <Switch>
                <PrivateRoute exact path="/" component={MainRoutes} />
                <PrivateRoute exact path="/web-users" component={MainRoutes} />
                <PrivateRoute exact path="/mobile-users" component={MainRoutes} />
                <PrivateRoute exact path="/create-question" component={MainRoutes} />
                <PrivateRoute exact path="/questions" component={MainRoutes} />
                <PrivateRoute exact path="/profile" component={MainRoutes} />
                <PrivateRoute exact path="/settings" component={MainRoutes} />
                <PrivateRoute exact path="/user-feedback" component={MainRoutes} />
                <PrivateRoute exact path="/feedbacks" component={MainRoutes} />
                <PrivateRoute exact path="/reported-bugs" component={MainRoutes} />
                <PrivateRoute exact path="/tags" component={MainRoutes} />
                <PrivateRoute path="/exams" component={MainRoutes}>
                    <Route exact path="/exams" component={MainRoutes} />
                    <Route exact path="/exams/:examId" component={MainRoutes} />
                    <Route exact path="/exams/:examId/:submissionId" component={MainRoutes} />
                </PrivateRoute>

                <Route exact path="/login" component={AuthLayout} />
                {/* {showRouteInDevOnly && <Route exact path="/signup" component={AuthLayout} />} */}
                <Route exact path="/admin-signup" component={AuthLayout} />
                <Route exact path="/mobile-signup" component={AuthLayout} />
                <Route exact path="/guest-login" component={AuthLayout} />
                <Route component={NoMatch} />
            </Switch>
        </Router>
    </div>
)

export default Routes
