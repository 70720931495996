// TAGS
export const LIST_TAGS_START = 'tags/LIST_TAGS_START'
export const LIST_TAGS_SUCCESS = 'tags/LIST_TAGS_SUCCESS'
export const LIST_TAGS_FAIL = 'tags/LIST_TAGS_FAIL'

export const ADD_TAG_START = 'tag/ADD_TAG_START'
export const ADD_TAG_SUCCESS = 'tag/ADD_TAG_SUCCESS'
export const ADD_TAG_FAIL = 'tag/ADD_TAG_FAIL'

export const DELETE_TAG_START = 'tag/DELETE_TAG_START'
export const DELETE_TAG_SUCCESS = 'tag/DELETE_TAG_SUCCESS'
export const DELETE_TAG_FAIL = 'tag/DELETE_TAG_FAIL'

export const REMOVE_QUESTION_TITLES = 'tag/REMOVE_QUESTION_TITLES'
