/* eslint no-unused-vars: "off" */
import axios from 'axios'

/**
 * USER APIs
 * this file contains all user realted APIs
 * @type Service
 * @author Ehsan
 * @version 0.1.0
 * @param {array} credentials
 * @param {string} token
 */

const environment = process.env.NODE_ENV
let ENDPOINT
if (environment === 'development') {
    ENDPOINT = process.env.REACT_APP_DEV_API_ENDPOINT
} else {
    ENDPOINT = process.env.REACT_APP_PROD_API_ENDPOINT
}

export default {
    user: {
        login: (credentials) => {
            const [email, password] = credentials
            const url = `${ENDPOINT}/auth/`
            return axios({
                method: 'post',
                url,
                data: {
                    email,
                    password,
                },
            })
        },
        signup: (credentials) => {
            const [username, password] = credentials
            const url = `${ENDPOINT}/auth/signup`
            return axios({
                method: 'post',
                url,
                data: {
                    username,
                    password,
                },
            })
        },
        createGuestbUser: (credentials) => {
            const [data, token] = credentials
            const { username, email, password } = data
            const url = `${ENDPOINT}/auth/signup-guest`
            return axios({
                method: 'post',
                url,
                data: {
                    username,
                    email,
                    password,
                },
            })
        },
        adminSignup: (credentials) => {
            const [email, username, password, adminKey] = credentials
            const url = `${ENDPOINT}/auth/admin/signup`
            return axios({
                method: 'post',
                url,
                data: {
                    email,
                    username,
                    password,
                },
                headers: {
                    'X-Admin-Secret-Key': adminKey,
                },
            })
        },
        checkAvailability: (username) => {
            const url = `${ENDPOINT}/auth/user-availability`
            return axios({
                method: 'post',
                url,
                data: {
                    username,
                },
            })
        },
        checkUserAuth: (token) => {
            const url = `${ENDPOINT}/auth/validate-auth`
            return axios({
                method: 'post',
                url,
                data: {
                    token,
                },
            })
        },
        listWebUsers: (data) => {
            const [limit, page, accessToken] = data
            const url = `${ENDPOINT}/users/admin?limit=${limit}&page=${page}`
            return axios({
                method: 'get',
                url,
                headers: {
                    Authorization: `Bearer ${accessToken}`,
                },
            })
        },
        searchUsers: (data) => {
            const [q, cat, accessToken] = data
            const url = `${ENDPOINT}/users?q=${q}&cat=${cat}`
            return axios({
                method: 'get',
                url,
                headers: {
                    Authorization: `Bearer ${accessToken}`,
                },
            })
        },
        listMobileUsers: (data) => {
            const [limit, page, accessToken] = data
            const url = `${ENDPOINT}/mobile-users/admin?limit=${limit}&page=${page}`
            return axios({
                method: 'get',
                url,
                headers: {
                    Authorization: `Bearer ${accessToken}`,
                },
            })
        },
        deleteWebUser: (data) => {
            const [userId, accessToken] = data
            const url = `${ENDPOINT}/users/admin?user_id=${userId}`
            return axios({
                method: 'delete',
                url,
                headers: {
                    Authorization: `Bearer ${accessToken}`,
                },
            })
        },
        deleteMobilebUser: (data) => {
            const [userId, accessToken] = data
            const url = `${ENDPOINT}/mobile-users/admin?user_id=${userId}`
            return axios({
                method: 'delete',
                url,
                headers: {
                    Authorization: `Bearer ${accessToken}`,
                },
            })
        },
        listFeedbacks: (data) => {
            const [page, limit, accessToken, bugsOnly] = data
            const url = `${ENDPOINT}/feedbacks?page=${page}&limit=${limit}&bugsOnly=${bugsOnly}`
            return axios({
                method: 'get',
                url,
                headers: {
                    Authorization: `Bearer ${accessToken}`,
                },
            })
        },
        addUserFeedback: (data) => {
            const [postData, accessToken] = data
            const url = `${ENDPOINT}/feedbacks`
            return axios({
                method: 'post',
                url,
                data: {
                    ...postData,
                },
                headers: {
                    Authorization: `Bearer ${accessToken}`,
                },
            })
        },
        deleteFeedback: (data) => {
            const [id, accessToken] = data
            const url = `${ENDPOINT}/feedbacks?feedback_id=${id}`
            return axios({
                method: 'delete',
                url,
                headers: {
                    Authorization: `Bearer ${accessToken}`,
                },
            })
        },
        sendInvitation: (data) => {
            const [emailData, accessToken] = data
            const postData = {
                email: emailData.email,
                code: emailData.password,
                isInvitation: true,
            }

            const url = `${ENDPOINT}/users/admin/email`
            return axios({
                method: 'post',
                url,
                data: {
                    ...postData,
                },
                headers: {
                    Authorization: `Bearer ${accessToken}`,
                },
            })
        },
        sendGreetingFeedbackEmail: (data) => {
            const [feedbackData, accessToken] = data
            const postData = {
                email: feedbackData.email,
                username: feedbackData.username,
                feedbackMode: feedbackData.isBugReport ? 'Bug Report' : 'Feedback',
                isFeedbackGreeting: true,
            }

            const url = `${ENDPOINT}/users/admin/email`
            return axios({
                method: 'post',
                url,
                data: {
                    ...postData,
                },
                headers: {
                    Authorization: `Bearer ${accessToken}`,
                },
            })
        },
    },
}
