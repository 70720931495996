import * as actions from './login.action.types'
import { clearStorage } from '../../../../services/auth/storage'
import {
    GET_QUESTION_LANGUAGE_START,
    GET_QUESTION_LANGUAGE_SUCCESS,
    GET_QUESTION_LANGUAGE_FAIL,
} from '../../../Questions/engine/questions.action.types'

// import { PUSH_TO_PATHNAME } from '../../../pages.persisted.action.types'
// eslint-disable-next-line import/no-cycle

const initialState = {
    isFetching: false,
    isAuthenticated: false,
    isSignedup: false,
    loadingUsername: false,
    userIsAuthenticated: false,
}

export default (state = initialState, action) => {
    switch (action.type) {
        case actions.USER_LOGIN_REQUEST_START:
            // clearStorage()
            return {
                ...state,
                isFetching: true,
            }

        case actions.USER_LOGIN_REQUEST_SUCCESS: {
            return {
                ...state,
                isFetching: false,
                isAuthenticated: true,
                userIsAuthenticated: true,
            }
        }

        case actions.USER_LOGIN_REQUEST_FAIL:
            clearStorage()
            return {
                ...state,
                isFetching: false,
                isAuthenticated: false,
                userIsAuthenticated: false,
            }

        case actions.CHECK_USER_NAME_START:
            return {
                ...state,
                loadingUsername: true,
            }

        case actions.CHECK_USER_NAME_START_SUCCESS:
            return {
                ...state,
                loadingUsername: false,
            }

        case actions.CHECK_USER_NAME_START_FAIL:
            return {
                ...state,
                loadingUsername: false,
            }
        case actions.CHECK_USER_IS_AUTHENTICATED_START:
            return {
                ...state,
                userIsAuthenticated: false,
                isFetching: true,
            }

        case actions.CHECK_USER_IS_AUTHENTICATED_SUCCESS:
            return {
                ...state,
                userIsAuthenticated: true,
                isFetching: false,
            }

        case actions.CHECK_USER_IS_AUTHENTICATED_FAIL:
            return {
                ...state,
                userIsAuthenticated: false,
                isFetching: false,
            }

        case actions.USER_LOGOUT:
            return {
                ...state,
                isFetching: true,
                isAuthenticated: false,
            }
        case actions.USER_LOGOUT_SUCCESS:
            // clearStorage()
            // window.location.reload(true)
            setTimeout(() => window.location.reload(), 1000)
            window.sessionStorage.removeItem('exam')
            window.sessionStorage.removeItem('examAnswers')
            window.sessionStorage.removeItem('zenIndex')
            return {
                ...state,
                isFetching: false,
                isAuthenticated: false,
                userIsAuthenticated: false,
            }

        case actions.USER_LOGOUT_FAIL:
            return {
                ...state,
                isFetching: false,
                isAuthenticated: false,
            }

        case actions.USER_SIGNUP_REQUEST_START:
            // console.log('SIGNUP REQUEST STARTED.....')
            return {
                ...state,
                isFetching: true,
            }

        case actions.USER_SIGNUP_REQUEST_SUCCESS:
            // console.log('USER SUCCESSFULLY CREATED.')
            return {
                ...state,
                isFetching: false,
            }

        case actions.USER_SIGNUP_REQUEST_FAIL:
            return {
                ...state,
                isFetching: false,
            }
        case actions.USER_ADMIN_SIGNUP_REQUEST_START:
            // console.log('ADMIN SIGNUP REQUEST STARTED.....')
            return {
                ...state,
                isFetching: true,
                isSignedup: false,
            }

        case actions.USER_ADMIN_SIGNUP_REQUEST_SUCCESS:
            // console.log('ADMIN SUCCESSFULLY CREATED.')
            return {
                ...state,
                isFetching: false,
                isSignedup: true,
            }

        case actions.USER_ADMIN_SIGNUP_REQUEST_FAIL:
            return {
                ...state,
                isFetching: false,
                isSignedup: false,
            }

        case GET_QUESTION_LANGUAGE_START:
            return {
                ...state,
                isFetching: true,
            }

        case GET_QUESTION_LANGUAGE_FAIL:
        case GET_QUESTION_LANGUAGE_SUCCESS:
            return {
                ...state,
                isFetching: false,
            }

        default:
            return state
    }
}
