export const ADD_USER_FEEDBACK_START = 'user/ADD_USER_FEEDBACK_START'
export const ADD_USER_FEEDBACK_SUCCESS = 'user/ADD_USER_FEEDBACK_SUCCESS'
export const ADD_USER_FEEDBACK_FAIL = 'user/ADD_USER_FEEDBACK_FAIL'

export const LIST_FEEDBACKS_START = 'admin/LIST_FEEDBACKS_START'
export const LIST_FEEDBACKS_SUCCESS = 'admin/LIST_FEEDBACKS_SUCCESS'
export const LIST_FEEDBACKS_FAIL = 'admin/LIST_FEEDBACKS_FAIL'

export const DELETE_FEEDBACK_START = 'admin/DELETE_FEEDBACK_START'
export const DELETE_FEEDBACK_SUCCESS = 'admin/DELETE_FEEDBACK_SUCCESS'
export const DELETE_FEEDBACK_FAIL = 'admin/DELETE_FEEDBACK_FAIL'

export const CLEAR_SUBMISSION = 'user/CLEAR_SUBMISSION'
