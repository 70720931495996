/* eslint no-unused-vars: "off" */
import { put, call, fork, takeLatest } from 'redux-saga/effects'
import toastr from 'toastr'
import * as userTypes from './feedback.action.types'
import { USER_LOGOUT } from '../../Auth/login/engine/login.action.types'
import api from '../../../services/api/user.api'

import { TOASTR_OPTIONS } from '../../../consts'

Object.assign(toastr.options, TOASTR_OPTIONS)

export function* addUserFeedbackRequest(action) {
    try {
        const response = yield call(api.user.addUserFeedback, action.payload)
        // const delay = (ms) => new Promise((res) => setTimeout(res, ms))

        if (response && response.status === 201) {
            yield put({
                type: userTypes.ADD_USER_FEEDBACK_SUCCESS,
                payload: response.data,
            })
        } else {
            throw response
        }
    } catch (e) {
        const delay = (ms) => new Promise((res) => setTimeout(res, ms))
        yield put({
            type: userTypes.ADD_USER_FEEDBACK_FAIL,
            payload: e,
        })

        let message
        let status
        if (e.response) {
            message = e.response.data.message
            status = e.response.data.status
        }

        if (status === 401) {
            toastr['error'](message, 'Authentication Error')
            yield put({
                type: userTypes.ADD_USER_FEEDBACK_FAIL,
                payload: message,
            })
            yield delay(3000)
            yield put({
                type: USER_LOGOUT,
            })
        } else {
            toastr['error'](message, 'List users failed!')
        }
    }
}

export function* listFeedbacksRequest(action) {
    try {
        const [page, limit, accessToken, bugsOnly] = action.payload
        const response = yield call(api.user.listFeedbacks, action.payload)
        // const delay = (ms) => new Promise((res) => setTimeout(res, ms))

        if (response && response.status === 200) {
            yield put({
                type: userTypes.LIST_FEEDBACKS_SUCCESS,
                payload: response.data,
            })
            toastr['success'](`${bugsOnly ? 'Bugs' : 'Feedbacks'} successfully listed.`)
        } else {
            throw response
        }
    } catch (e) {
        const delay = (ms) => new Promise((res) => setTimeout(res, ms))
        yield put({
            type: userTypes.LIST_FEEDBACKS_FAIL,
            payload: e,
        })

        let message
        let status
        if (e.response) {
            message = e.response.data.message
            status = e.response.data.status
        }

        if (status === 401) {
            toastr['error'](message, 'Authentication Error')
            yield put({
                type: userTypes.LIST_FEEDBACKS_FAIL,
                payload: message,
            })
            yield delay(3000)
            yield put({
                type: USER_LOGOUT,
            })
        } else {
            toastr['error'](message, 'List feedbacks failed!')
        }
    }
}

export function* deleteUserFeedbackRequest(action) {
    try {
        const [id, accessToken, limit, bugsOnly] = action.payload
        const response = yield call(api.user.deleteFeedback, action.payload)
        // const delay = (ms) => new Promise((res) => setTimeout(res, ms))

        if (response && response.status === 200) {
            yield put({
                type: userTypes.DELETE_FEEDBACK_SUCCESS,
                payload: response.data,
            })
            toastr['success'](`${bugsOnly ? 'Bug' : 'Feedback'} successfully deleted.`)
            yield put({
                type: userTypes.LIST_FEEDBACKS_START,
                payload: [1, limit, accessToken, bugsOnly],
            })
        } else {
            throw response
        }
    } catch (e) {
        const delay = (ms) => new Promise((res) => setTimeout(res, ms))
        yield put({
            type: userTypes.DELETE_FEEDBACK_FAIL,
            payload: e,
        })

        let message
        let status
        if (e.response) {
            message = e.response.data.message
            status = e.response.data.status
        }

        if (status === 401) {
            toastr['error'](message, 'Authentication Error')
            yield put({
                type: userTypes.DELETE_FEEDBACK_FAIL,
                payload: message,
            })
            yield delay(3000)
            yield put({
                type: USER_LOGOUT,
            })
        } else {
            toastr['error'](message, 'Delete feedback failed!')
        }
    }
}

// a watcher to listen to our request ‘start’ action
function* watchUserLoginRequest() {
    yield takeLatest(userTypes.ADD_USER_FEEDBACK_START, addUserFeedbackRequest)
    yield takeLatest(userTypes.LIST_FEEDBACKS_START, listFeedbacksRequest)
    yield takeLatest(userTypes.DELETE_FEEDBACK_START, deleteUserFeedbackRequest)
}

// use 'fork' to start multiple sagas in the background
// they are always attached to thier parent
export default [fork(watchUserLoginRequest)]
