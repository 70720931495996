import React from 'react'
import PropTypes from 'prop-types'
import { Route } from 'react-router-dom'
import Authentication from '../services/auth/authentication.service'

const PrivateRoute = ({ component: Component, ...rest }) => {
    return (
        <Route {...rest} render={(props) => <Authentication Component={Component} {...props} />} />
    )
}

PrivateRoute.propTypes = {
    component: PropTypes.func.isRequired,
}

export default PrivateRoute
