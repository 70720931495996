/* eslint no-unused-vars: "off" */
import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import { Dimmer, Header, Icon, Modal, Button } from 'semantic-ui-react'
import NavActions from './components/nav.actions'
import styles from './Mainlayout.module.css'
import SidebarList from './components/sidebar.list'
import Spinner from '../../ui.library/Spinner'
import FiltersPage from './components/filters'

const MainLayout = ({
    children,
    onLogout,
    history,
    role,
    isFetching,
    pathname,
    onChangeTheme,
    defaultTheme,
    onchangeFilter,
    savedFilters,
    onClearFilters,
    onFilterClick,
    onExpand,
    isExpandedState,
    persistedCodingLanguages,
    tags,
    onNewRandomizedExam,
    examId,
    examTime,
    hideSideNav,
    onTimerEnd,
    endTime,
    resumeTime,
    beginAt,
    onPersistToggle,
    persistedToggle,
    onListSubmissions,
    loadSubmissions,
    onClearSubmissions,
    submissionId,
    tagOptions,
    onShowIcons,
    showLanguageIcons,
    onRandomize,
    onQuickFilter,
    quickFilter,
}) => {
    const [visible, setVisible] = useState(persistedToggle)
    const [hasSidebar, setHasSidebar] = useState(true) // only manually set the layout to either have sidebar or not!
    const [windowWidth, setWindowWidth] = useState(window.innerWidth)
    const [showFilter, setShowFilter] = useState(false)
    const isAuthorized = role === 'Admin'

    useEffect(() => {
        const onWindowResize = (e) => {
            // console.log(e.target.innerWidth)
            setWindowWidth(e.target.innerWidth)
        }
        window.addEventListener('resize', onWindowResize)

        return () => {
            window.removeEventListener('resize', onWindowResize)
        }
    }, [])

    useEffect(() => {
        if (hideSideNav) setHasSidebar(false)
        if (!hideSideNav) setHasSidebar(true)
    }, [hideSideNav])

    // uncomment this to keep the filter window open after apply
    // keeping it open cause scrolling issues!
    // useEffect(() => {
    //     setShowFilter(savedFilters ? true : false)
    // }, [savedFilters])

    useEffect(() => {
        setVisible(persistedToggle)
    }, [persistedToggle])

    const handleHideDimmer = () => {
        setVisible(false)
    }

    const handleFilterClick = () => {
        setShowFilter(!showFilter)
        onFilterClick(!showFilter)
    }

    const handleChangeFilter = (data) => {
        setShowFilter(false) // this line will be ignored while if savedFilters is true (then setShowFilter will be true!) in another useEffect
        onchangeFilter(data)
    }

    const handleClearFilters = (data) => {
        setShowFilter(false)
        onClearFilters()
    }

    const dimmerActive = hasSidebar && visible && windowWidth <= 900
    return (
        <div className={styles.root}>
            {hasSidebar && (
                <div
                    className={classnames(
                        hasSidebar && visible ? styles.drawerWrapper : styles.drawerWrapperClosed,
                        windowWidth <= 900 && visible ? styles.drawerWrapperMobile : ''
                    )}
                >
                    <div className={styles.sidebarHeader}>
                        <Header as="h3" className={styles.sidebarHeaderText}>
                            {isAuthorized && (
                                <>
                                    <Icon name="secret user" />
                                    <Header.Content>
                                        Dashboard
                                        <Header.Subheader>admin access</Header.Subheader>
                                    </Header.Content>
                                </>
                            )}
                            {!isAuthorized && (
                                <>
                                    <Icon
                                        name={
                                            role !== 'Admin' &&
                                            (role === 'User' || role === 'Mobile')
                                                ? 'address book'
                                                : 'user outline'
                                        }
                                    />
                                    <Header.Content>
                                        Dashboard
                                        <Header.Subheader>
                                            {`${
                                                role === 'User'
                                                    ? 'user access'
                                                    : 'guest user access'
                                            }`}
                                        </Header.Subheader>
                                    </Header.Content>
                                </>
                            )}
                        </Header>
                    </div>
                    {!examTime && (
                        <div className={styles.sidebarContent}>
                            <SidebarList
                                history={history}
                                onListClick={handleHideDimmer}
                                dimmerActive={dimmerActive}
                                role={role}
                            />
                        </div>
                    )}
                </div>
            )}
            <div className={styles.content}>
                <div className={styles.topNav}>
                    <NavActions
                        visible={visible}
                        hasSidebar={hasSidebar}
                        onToggle={() => {
                            setVisible(!visible)
                            onPersistToggle(!visible)
                        }}
                        onLogout={onLogout}
                        history={history}
                        onChangeTheme={onChangeTheme}
                        pathname={pathname}
                        defaultTheme={defaultTheme}
                        onFilterClick={handleFilterClick}
                        onExpand={onExpand}
                        isExpandedState={isExpandedState}
                        onNewRandomizedExam={onNewRandomizedExam}
                        examId={examId}
                        examTime={examTime}
                        hideSideNav={hideSideNav}
                        onTimerEnd={onTimerEnd}
                        endTime={endTime}
                        resumeTime={resumeTime}
                        beginAt={beginAt}
                        onListSubmissions={onListSubmissions}
                        loadSubmissions={loadSubmissions}
                        onClearSubmissions={onClearSubmissions}
                        submissionId={submissionId}
                        onShowIcons={onShowIcons}
                        showLanguageIcons={showLanguageIcons}
                        persistedCodingLanguages={persistedCodingLanguages}
                        onRandomize={onRandomize}
                        onQuickFilter={onQuickFilter}
                        quickFilter={quickFilter}
                        savedFilters={savedFilters}
                    />
                </div>
                <Dimmer.Dimmable blurring dimmed={dimmerActive} style={{ height: '100%' }}>
                    <Dimmer active={dimmerActive} onClickOutside={handleHideDimmer} />
                    {isFetching && <Spinner centerScreen size={15} />}
                    {!isFetching && (
                        <div className={styles.mainContent}>
                            <>
                                {/* {showFilter && pathname === '/questions' && (
                                    <div className={styles.filtersWrapper}>
                                        <FiltersPage
                                            role={role}
                                            onchangeFilter={handleChangeFilter}
                                            savedFilters={savedFilters}
                                            onClearFilters={handleClearFilters}
                                            persistedCodingLanguages={persistedCodingLanguages}
                                            tags={tags}
                                            tagOptions={tagOptions}
                                        />
                                    </div>
                                )} */}
                                {children}
                            </>
                        </div>
                    )}
                </Dimmer.Dimmable>
            </div>

            <Modal
                size="large"
                open={showFilter}
                onClose={() => setShowFilter(false)}
                closeOnEscape={true}
                closeOnDimmerClick={false}
            >
                <Modal.Header>Advanced Filter</Modal.Header>
                <Modal.Content scrolling>
                    <div className={styles.filtersWrapper}>
                        <FiltersPage
                            role={role}
                            onchangeFilter={handleChangeFilter}
                            savedFilters={savedFilters}
                            onClearFilters={handleClearFilters}
                            onCloseFilters={() => setShowFilter(false)}
                            persistedCodingLanguages={persistedCodingLanguages}
                            tags={tags}
                            tagOptions={tagOptions}
                        />
                    </div>
                </Modal.Content>
            </Modal>
        </div>
    )
}
MainLayout.defaultProps = {
    role: 'User',
    isFetching: false,
    savedFilters: null,
    defaultTheme: 'prism',
    isExpandedState: false,
    persistedCodingLanguages: null,
    examId: '',
    submissionId: '',
    examTime: null,
    endTime: null,
    resumeTime: null,
    beginAt: null,
    tagOptions: [],
    showLanguageIcons: false,
    onRandomize: () => {},
    onQuickFilter: () => {},
    quickFilter: null,
}

MainLayout.propTypes = {
    children: PropTypes.array.isRequired,
    history: PropTypes.object.isRequired,
    onLogout: PropTypes.func.isRequired,
    onChangeTheme: PropTypes.func.isRequired,
    role: PropTypes.string,
    isFetching: PropTypes.bool,
    isExpandedState: PropTypes.bool,
    pathname: PropTypes.string.isRequired,
    defaultTheme: PropTypes.string,
    onchangeFilter: PropTypes.func.isRequired,
    onClearFilters: PropTypes.func.isRequired,
    onFilterClick: PropTypes.func.isRequired,
    onTimerEnd: PropTypes.func.isRequired,
    onExpand: PropTypes.func.isRequired,
    onNewRandomizedExam: PropTypes.func.isRequired,
    savedFilters: PropTypes.object,
    persistedCodingLanguages: PropTypes.array,
    tags: PropTypes.array.isRequired,
    examId: PropTypes.string,
    submissionId: PropTypes.string,
    examTime: PropTypes.number,
    hideSideNav: PropTypes.bool.isRequired,
    endTime: PropTypes.string,
    beginAt: PropTypes.string,
    resumeTime: PropTypes.number,
    onPersistToggle: PropTypes.func.isRequired,
    onListSubmissions: PropTypes.func.isRequired,
    onClearSubmissions: PropTypes.func.isRequired,
    onShowIcons: PropTypes.func.isRequired,
    persistedToggle: PropTypes.bool.isRequired,
    loadSubmissions: PropTypes.bool.isRequired,
    tagOptions: PropTypes.array,
    showLanguageIcons: PropTypes.bool,
    onRandomize: PropTypes.func,
    onQuickFilter: PropTypes.func,
    quickFilter: PropTypes.string,
}

export default MainLayout
