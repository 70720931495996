export const LIST_USERS_START = 'admin/LIST_USERS_START'
export const LIST_USERS_SUCCESS = 'admin/LIST_USERS_SUCCESS'
export const LIST_USERS_FAIL = 'admin/LIST_USERS_FAIL'

export const DELETE_USER_START = 'admin/DELETE_USER_START'
export const DELETE_USER_SUCCESS = 'admin/DELETE_USER_SUCCESS'
export const DELETE_USER_FAIL = 'admin/DELETE_USER_FAIL'

export const LIST_MOBILE_USERS_START = 'admin/LIST_MOBILE_USERS_START'
export const LIST_MOBILE_USERS_SUCCESS = 'admin/LIST_MOBILE_USERS_SUCCESS'
export const LIST_MOBILE_USERS_FAIL = 'admin/LIST_MOBILE_USERS_FAIL'

export const DELETE_MOBILE_USER_START = 'admin/DELETE_MOBILE_USER_START'
export const DELETE_MOBILE_USER_SUCCESS = 'admin/DELETE_MOBILE_USER_SUCCESS'
export const DELETE_MOBILE_USER_FAIL = 'admin/DELETE_MOBILE_USER_FAIL'

export const CREATE_GUEST_USER_START = 'admin/CREATE_GUEST_USER_START'
export const CREATE_GUEST_USER_SUCCESS = 'admin/CREATE_GUEST_USER_SUCCESS'
export const CREATE_GUEST_USER_FAIL = 'admin/CREATE_GUEST_USER_FAIL'

export const SEND_INVITATION_EMAIL_START = 'admin/SEND_INVITATION_EMAIL_START'
export const SEND_INVITATION_EMAIL_SUCCESS = 'admin/SEND_INVITATION_EMAIL_SUCCESS'
export const SEND_INVITATION_EMAIL_FAIL = 'admin/SEND_INVITATION_EMAIL_FAIL'

export const SEND_FEEDBACK_EMAIL_START = 'admin/SEND_FEEDBACK_EMAIL_START'
export const SEND_FEEDBACK_EMAIL_SUCCESS = 'admin/SEND_FEEDBACK_EMAIL_SUCCESS'
export const SEND_FEEDBACK_EMAIL_FAIL = 'admin/SEND_FEEDBACK_EMAIL_FAIL'

export const SEARCH_USERS_START = 'admin/SEARCH_USERS_START'
export const SEARCH_USERS_SUCCESS = 'admin/SEARCH_USERS_SUCCESS'
export const SEARCH_USERS_FAIL = 'admin/SEARCH_USERS_FAIL'
