/* eslint-disable no-underscore-dangle */
export const transformData = (data) => {
    const result = {}
    const { data: questions } = data
    result.count = data.count

    const list = questions.reduce((res, q, i) => {
        res.push({
            order: i + 1,
            id: q._id,
            key: q._id,
            title: q.title,
            titleCode: q.titleCode,
            titleCodeLanguage: q.titleCodeLanguage,
            tags: q.tags,
            isLive: q.isLive,
            sandboxUrl: q.sandboxUrl,
            difficulty: q.difficulty,
            language_id: q.language_id,
            sections: q.sections.reduce((res, sec) => {
                res.push({
                    ...sec,
                    key: sec._id,
                    title: sec.subject,
                })
                return res
            }, []),
            by: q.user_id,
        })
        return res
    }, [])

    result.questions = list
    return result
}
