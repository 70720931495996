/* eslint no-unused-vars: "off" */
import { put, call, fork, takeLatest } from 'redux-saga/effects'
import toastr from 'toastr'
import * as tagTypes from './tags.action.types'
import { USER_LOGOUT } from '../../Auth/login/engine/login.action.types'
import api from '../../../services/api/tags.api'
import { TOASTR_OPTIONS } from '../../../consts'
import { transformData } from '../../../services/transform/list.tags.res.transform'

/**
 * TAGS SAGAS
 * this file contains all related user sagas and its associated async calls
 * @type Core
 * @author Ehsan
 * @version 0.1.1
 */

Object.assign(toastr.options, TOASTR_OPTIONS)

export function* listTagsRequest(action) {
    try {
        const response = yield call(api.tags.list, action.payload)
        if (response && response.status === 200) {
            const transformedData = yield call(transformData, response.data)
            if (transformedData) {
                yield put({
                    type: tagTypes.LIST_TAGS_SUCCESS,
                    payload: transformedData,
                })
            }
        } else {
            throw response
        }
    } catch (e) {
        const delay = (ms) => new Promise((res) => setTimeout(res, ms))
        yield put({
            type: tagTypes.LIST_TAGS_FAIL,
            payload: e,
        })
        let message
        let status
        if (e.response) {
            message = e.response.data.message
            status = e.response.data.status
        }
        if (status === 401) {
            toastr['error'](message, 'Authentication Error')
            yield put({
                type: tagTypes.LIST_TAGS_FAIL,
                payload: message,
            })
            yield delay(3000)
            yield put({
                type: USER_LOGOUT,
            })
        } else {
            toastr['error'](message, 'List tags failed!')
        }
    }
}

export function* addTagRequest(action) {
    try {
        const [name, accessToken] = action.payload
        const response = yield call(api.tags.add, action.payload)
        if (response && response.status === 201) {
            yield put({
                type: tagTypes.ADD_TAG_SUCCESS,
                payload: response.data.data,
            })
            yield put({
                type: tagTypes.LIST_TAGS_START,
                payload: [accessToken],
            })
            toastr['success']('Tag successfully added.')
        } else {
            throw response
        }
    } catch (e) {
        const delay = (ms) => new Promise((res) => setTimeout(res, ms))
        yield put({
            type: tagTypes.ADD_TAG_FAIL,
            payload: e,
        })
        let message
        let status
        if (e.response) {
            message = e.response.data.message
            status = e.response.data.status
        }
        if (status === 401) {
            toastr['error'](message, 'Authentication Error')
            yield put({
                type: tagTypes.ADD_TAG_FAIL,
                payload: message,
            })
            yield delay(3000)
            yield put({
                type: USER_LOGOUT,
            })
        } else {
            toastr['error'](message, 'Add tag failed!')
        }
    }
}

export function* deleteTagRequest(action) {
    try {
        const [id, accessToken] = action.payload
        const response = yield call(api.tags.delete, action.payload)
        if (response && response.status === 200) {
            yield put({
                type: tagTypes.DELETE_TAG_SUCCESS,
            })
            yield put({
                type: tagTypes.LIST_TAGS_START,
                payload: [accessToken],
            })
            toastr['success']('Tag successfully deleted.')
        } else {
            throw response
        }
    } catch (e) {
        const delay = (ms) => new Promise((res) => setTimeout(res, ms))
        let message
        let status
        if (e.response) {
            message = e.response.data.message
            status = e.response.data.status
        }
        if (status === 401) {
            toastr['error'](message, 'Authentication Error')
            yield put({
                type: tagTypes.DELETE_TAG_FAIL,
                payload: message,
            })
            yield delay(3000)
            yield put({
                type: USER_LOGOUT,
            })
        } else {
            yield put({
                type: tagTypes.DELETE_TAG_FAIL,
                payload: e,
            })
            toastr['error'](message, 'Delete tag failed!')
        }
    }
}

// a watcher to listen to our request ‘start’ action
function* watchUserLoginRequest() {
    yield takeLatest(tagTypes.LIST_TAGS_START, listTagsRequest)
    yield takeLatest(tagTypes.ADD_TAG_START, addTagRequest)
    yield takeLatest(tagTypes.DELETE_TAG_START, deleteTagRequest)
}

// use 'fork' to start multiple sagas in the background
// they are always attached to thier parent
export default [fork(watchUserLoginRequest)]
