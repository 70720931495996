/* eslint no-unused-vars: "off" */
import React, { Suspense, useState } from 'react'
import PropTypes from 'prop-types'
import { Dimmer, Header } from 'semantic-ui-react'
import { bindActionCreators } from 'redux'
import { withRouter, Route } from 'react-router-dom'
import { connect } from 'react-redux'

import { compose } from 'recompose'

// import {
//     getUserLoginStatus,
//     getAuthTokenStatus,
//     getAccessToken
// } from '../../pages/login/engine/login.selectors'

// import { logoutUser } from '../../pages/login/engine/login.actions'
import LoginLayout from '../../shared.components/login.layout'

import Spinner from '../../ui.library/Spinner'

// CODE-SPLITTING
const Login = React.lazy(() => import('../../pages/Auth/login'))
const AdminSignup = React.lazy(() => import('../../pages/Auth/signup/admin.index'))
const MobileSignup = React.lazy(() => import('../../pages/Auth/signup/mobile.index'))
const GuestLogin = React.lazy(() => import('../../pages/Auth/guest/guest.login.index'))

/**
 * AuthRoute
 * this components shows the home page after the user has been successully logged in.
 * @type Container
 * @author Ehsan
 * @version 0.1.0
 */

const AuthRoute = ({ location, history }) => {
    const [active, setActive] = useState(false)

    const handleCloseDimmer = () => {
        setActive(false)
    }
    return (
        <>
            <LoginLayout history={history}>
                {location.pathname === '/login' && (
                    <Route
                        exact
                        path="/login"
                        render={() => {
                            return (
                                <Suspense fallback={<Spinner centerScreen size={15} />}>
                                    <Login />
                                </Suspense>
                            )
                        }}
                    />
                )}
                {location.pathname === '/admin-signup' && (
                    <Route
                        exact
                        path="/admin-signup"
                        render={() => {
                            return (
                                <Suspense fallback={<Spinner centerScreen size={15} />}>
                                    <AdminSignup />
                                </Suspense>
                            )
                        }}
                    />
                )}
                {location.pathname === '/mobile-signup' && (
                    <Route
                        exact
                        path="/mobile-signup"
                        render={() => {
                            return (
                                <Suspense fallback={<Spinner centerScreen size={15} />}>
                                    <MobileSignup />
                                </Suspense>
                            )
                        }}
                    />
                )}
                {location.pathname === '/guest-login' && (
                    <Route
                        exact
                        path="/guest-login"
                        render={() => {
                            return (
                                <Suspense fallback={<Spinner centerScreen size={15} />}>
                                    <GuestLogin />
                                </Suspense>
                            )
                        }}
                    />
                )}
            </LoginLayout>
            <Dimmer active={active} onClickOutside={handleCloseDimmer} page>
                <Header.Subheader style={{ paddingBottom: 4 }}>Authenticating...</Header.Subheader>
                <Spinner size={15} />
            </Dimmer>
        </>
    )
}

AuthRoute.propTypes = {
    location: PropTypes.object.isRequired,
    history: PropTypes.object.isRequired,
}

const mapStateToProps = (state) => ({})

const mapDispatchToProps = (dispatch) => ({
    // logoutUser: bindActionCreators(logoutUser, dispatch),
})

const enhance = compose(withRouter, connect(mapStateToProps, mapDispatchToProps))

export default enhance(AuthRoute)
