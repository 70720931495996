import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
// eslint-disable-next-line import/no-extraneous-dependencies
import { Redirect } from 'react-router'
import {
    getAccessToken,
    getUserRole,
    getUserAuthStatus,
} from '../../pages/Auth/login/engine/login.selectors'
import { checkUserIsAuthenticated } from '../../pages/Auth/login/engine/login.actions'

class Authentication extends React.Component {
    componentDidMount() {
        const { checkUserIsAuthenticated, token } = this.props
        checkUserIsAuthenticated(token)
    }

    isUserAuthenticated = () => {
        const { token, userRole } = this.props
        if (userRole && token) {
            return true
        }

        return false
    }

    render() {
        const { Component, ...rest } = this.props
        return this.isUserAuthenticated() ? <Component {...rest} /> : <Redirect to="/login" />
    }
}

Authentication.defaultProps = {
    token: null,
    userRole: null,
    checkUserIsAuthenticated: () => {},
    // userIsAuthenticated: false,
}

Authentication.propTypes = {
    Component: PropTypes.func.isRequired,
    token: PropTypes.string,
    userRole: PropTypes.string,
    checkUserIsAuthenticated: PropTypes.func,
    // userIsAuthenticated: PropTypes.bool,
}

const mapStateToProps = (state) => ({
    token: getAccessToken(state),
    userRole: getUserRole(state),
    userIsAuthenticated: getUserAuthStatus(state),
})

const mapDispatchToProps = (dispatch) => ({
    checkUserIsAuthenticated: bindActionCreators(checkUserIsAuthenticated, dispatch),
})

export default connect(mapStateToProps, mapDispatchToProps)(Authentication)
