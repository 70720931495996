/* eslint no-unused-vars: "off" */
import axios from 'axios'

/**
 * Exams APIs
 * this file contains all exams realted APIs
 * @type Service
 * @author Ehsan
 * @version 0.1.0
 * @param {array} credentials
 * @param {string} token
 */

const environment = process.env.NODE_ENV
let ENDPOINT
if (environment === 'development') {
    ENDPOINT = process.env.REACT_APP_DEV_API_ENDPOINT
} else {
    ENDPOINT = process.env.REACT_APP_PROD_API_ENDPOINT
}

export default {
    exams: {
        list: (data) => {
            const [accessToken, userId] = data
            const url = `${ENDPOINT}/exams?user_id=${userId}`
            return axios({
                method: 'get',
                url,
                headers: {
                    Authorization: `Bearer ${accessToken}`,
                },
            })
        },
        getExam: (data) => {
            const [accessToken, userId, examId] = data
            const url = `${ENDPOINT}/exams?user_id=${userId}&exam_id=${examId}`
            return axios({
                method: 'get',
                url,
                headers: {
                    Authorization: `Bearer ${accessToken}`,
                },
            })
        },
        generate: (data) => {
            const [examData, accessToken, userId] = data
            const url = `${ENDPOINT}/exams?user_id=${userId}`
            return axios({
                method: 'post',
                url,
                data: {
                    examData,
                },
                headers: {
                    Authorization: `Bearer ${accessToken}`,
                },
            })
        },
        delete: (data) => {
            const [examId, accessToken, userId] = data
            const url = `${ENDPOINT}/exams?user_id=${userId}`
            return axios({
                method: 'delete',
                url,
                data: {
                    exam_id: examId,
                },
                headers: {
                    Authorization: `Bearer ${accessToken}`,
                },
            })
        },
        addBookmark: (data) => {
            const [payload, accessToken] = data
            const url = `${ENDPOINT}/users/bookmarks/exams`
            return axios({
                method: 'post',
                url,
                data: {
                    ...payload,
                },
                headers: {
                    Authorization: `Bearer ${accessToken}`,
                },
            })
        },
        removeBookmark: (data) => {
            const [payload, accessToken] = data
            const url = `${ENDPOINT}/users/bookmarks/exams`
            return axios({
                method: 'delete',
                url,
                data: {
                    ...payload,
                },
                headers: {
                    Authorization: `Bearer ${accessToken}`,
                },
            })
        },
        listExamBookmarks: (data) => {
            const [accessToken, userId] = data
            const url = `${ENDPOINT}/users/bookmarks/exams?user_id=${userId}`
            return axios({
                method: 'get',
                url,
                headers: {
                    Authorization: `Bearer ${accessToken}`,
                },
            })
        },
        validateExamQuestions: (data) => {
            const [accessToken, options] = data
            const url = `${ENDPOINT}/exams/validate`
            return axios({
                method: 'post',
                url,
                data: {
                    options: { ...options },
                },
                headers: {
                    Authorization: `Bearer ${accessToken}`,
                },
            })
        },
        getExamQuestions: (data) => {
            const [accessToken, userId, questions] = data
            const url = `${ENDPOINT}/questions/exam?user_id=${userId}`
            return axios({
                method: 'post',
                url,
                data: {
                    questions: { ...questions },
                },
                headers: {
                    Authorization: `Bearer ${accessToken}`,
                },
            })
        },
        submitExam: (data) => {
            const [accessToken, userId, submitData] = data
            const url = `${ENDPOINT}/exams/submit?user_id=${userId}`
            return axios({
                method: 'post',
                url,
                data: {
                    data: { ...submitData },
                },
                headers: {
                    Authorization: `Bearer ${accessToken}`,
                },
            })
        },
        deleteExamSubmission: (data) => {
            const [accessToken, userId, submissionId, candidateId, examId, sortType] = data
            const url = `${ENDPOINT}/exams/submit?user_id=${userId}&submission_id=${submissionId}`
            return axios({
                method: 'delete',
                url,
                data: {
                    candidateId,
                    examId,
                },
                headers: {
                    Authorization: `Bearer ${accessToken}`,
                },
            })
        },
        listSubmittedExams: (data) => {
            const [accessToken, examId] = data
            const url = `${ENDPOINT}/exams/submits?exam_id=${examId}`
            return axios({
                method: 'get',
                url,
                headers: {
                    Authorization: `Bearer ${accessToken}`,
                },
            })
        },
        setSubmissionScore: (data) => {
            const [accessToken, submissionId, questionId, score] = data
            const url = `${ENDPOINT}/exams/submits/score?submission_id=${submissionId}&question_id=${questionId}`
            return axios({
                method: 'post',
                url,
                data: {
                    score,
                },
                headers: {
                    Authorization: `Bearer ${accessToken}`,
                },
            })
        },
        clearSubmissionScore: (data) => {
            const [accessToken, submissionId, questionId] = data
            const url = `${ENDPOINT}/exams/submits/score?submission_id=${submissionId}&question_id=${questionId}`
            return axios({
                method: 'delete',
                url,
                headers: {
                    Authorization: `Bearer ${accessToken}`,
                },
            })
        },
        addSubmissionComment: (data) => {
            const [accessToken, commentedBy, submissionId, questionId, comment, commentCode] = data
            const url = `${ENDPOINT}/exams/submits/comment?submission_id=${submissionId}&question_id=${questionId}&user_id=${commentedBy}`
            return axios({
                method: 'post',
                url,
                data: {
                    comment,
                    code: commentCode,
                },
                headers: {
                    Authorization: `Bearer ${accessToken}`,
                },
            })
        },
        deleteSubmissionComment: (data) => {
            const [accessToken, submissionId, commentId] = data
            const url = `${ENDPOINT}/exams/submits/comment?submission_id=${submissionId}&comment_id=${commentId}`
            return axios({
                method: 'delete',
                url,
                headers: {
                    Authorization: `Bearer ${accessToken}`,
                },
            })
        },
        updateExamExpiry: (data) => {
            const [accessToken, userId, examId] = data
            const url = `${ENDPOINT}/exams/expiry`
            return axios({
                method: 'post',
                data: {
                    userId,
                    examId,
                },
                url,
                headers: {
                    Authorization: `Bearer ${accessToken}`,
                },
            })
        },
    },
}
