/* eslint-disable no-underscore-dangle */
import { createSelector } from 'reselect'

const fetchStatusSelector = (state) => state.pages.examDetails.isFetching
export const getFetchStatus = createSelector(fetchStatusSelector, (status) => status)

const examSelector = (state) => state.pages.examDetails.exam
export const getExam = createSelector(examSelector, (exam) => exam)

const bookmarksSelector = (state) => state.pages.exams.bookmarks
export const getBookmarks = createSelector(bookmarksSelector, (bookmarks) => bookmarks)

const examQuestionsSelector = (state) => state.persistedData.examQuestions
export const getValidatedExamQuestions = createSelector(
    examQuestionsSelector,
    (questions) => questions
)

const examSubmitStatusSelector = (state) => state.pages.examDetails.examIsSubmitting
export const getExamSubmitStatus = createSelector(examSubmitStatusSelector, (status) => status)

const examSubmittedStatusSelector = (state) => state.pages.examDetails.examIsSubmitted
export const getExamSubmittedStatus = createSelector(
    examSubmittedStatusSelector,
    (status) => status
)
