import * as actions from './login.action.types'

export const authenticateUser = (creds) => ({
    type: actions.USER_LOGIN_REQUEST_START,
    data: creds,
})

export const logoutUser = () => {
    return {
        type: actions.USER_LOGOUT,
    }
}

export const checkAccessToken = (token) => {
    return {
        type: actions.CHECK_ACCESS_TOKEN_IS_EXISTED,
        token,
    }
}

export const checkUserIsAuthenticated = (token) => {
    return {
        type: actions.CHECK_USER_IS_AUTHENTICATED_START,
        token,
    }
}

export const createUser = (creds) => {
    return {
        type: actions.USER_SIGNUP_REQUEST_START,
        data: creds,
    }
}

export const checkUsername = (username) => {
    return {
        type: actions.CHECK_USER_NAME_START,
        data: username,
    }
}

export const createAdminUser = (creds) => {
    return {
        type: actions.USER_ADMIN_SIGNUP_REQUEST_START,
        data: creds,
    }
}

export const persistSelectedTheme = (theme) => {
    return {
        type: actions.PERSIST_SELECTED_THEME,
        data: theme,
    }
}
