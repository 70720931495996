export const setStorage = (key, data) => {
    window.localStorage.setItem(key, JSON.stringify(data))
}

export const clearStorageByKey = (key) => {
    window.localStorage.removeItem(key)
}

export const clearStorage = () => {
    window.localStorage.clear()
}

export const getStorageData = (key) => {
    const data = window.localStorage.getItem(key)
    return JSON.parse(data)
}
