/* eslint-disable no-underscore-dangle */
/* eslint no-unused-vars: "off" */
import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { Tooltip } from 'antd'
import { Button, Icon, Dropdown, Form, Checkbox } from 'semantic-ui-react'
import CountdownTimer from './countdown.timer'
import styles from './Nav.module.css'

// eslint-disable-next-line react/prop-types
const NavActions = ({
    onLogout,
    onToggle,
    hasSidebar,
    visible,
    history,
    onChangeTheme,
    pathname,
    defaultTheme,
    onFilterClick,
    onExpand,
    onNewRandomizedExam,
    isExpandedState,
    examId,
    examTime,
    hideSideNav,
    onTimerEnd,
    endTime,
    resumeTime,
    beginAt,
    onListSubmissions,
    loadSubmissions,
    onClearSubmissions,
    submissionId,
    onShowIcons,
    showLanguageIcons,
    persistedCodingLanguages,
    onRandomize,
    onQuickFilter,
    quickFilter,
    savedFilters,
}) => {
    const [themes, setThemes] = useState([
        'prism',
        'duotoneSpace',
        'darcula',
        'pojoaque',
        'xonokai',
        'coy',
        'cb',
    ])
    const [selectedTheme, setSelectedTheme] = useState(defaultTheme || 'prism')
    const [isExpanded, setIsExpanded] = useState(false)
    const [showIcons, setShowIcons] = useState(false)
    const [selectedQuickFilter, setSelectedQuickFilter] = useState(quickFilter)

    useEffect(() => {
        if (quickFilter) {
            setSelectedQuickFilter(quickFilter)
        }
    }, [quickFilter])

    useEffect(() => {
        setShowIcons(showLanguageIcons)
    }, [showLanguageIcons])

    useEffect(() => {
        onChangeTheme(selectedTheme)
    }, [onChangeTheme, selectedTheme])

    useEffect(() => {
        setIsExpanded(isExpandedState)
    }, [isExpandedState])

    const handleExpandClick = () => {
        setIsExpanded(!isExpanded)
        onExpand(!isExpanded)
    }

    const handleShowIcons = () => {
        setShowIcons(!showIcons)
        onShowIcons(!showIcons)
    }

    const handleSetSelectedFilter = (lang) => {
        setSelectedQuickFilter(lang._id)
        onQuickFilter(lang)
    }

    return (
        <>
            <div className={styles.leftActions}>
                {hasSidebar && (
                    <Tooltip title="Toggle Sidebar" mouseEnterDelay={0.75}>
                        <Button icon onClick={onToggle}>
                            <Icon name="bars" />
                        </Button>
                    </Tooltip>
                )}
                {pathname === '/questions' && (
                    <>
                        <Tooltip title="Advance Filter" mouseEnterDelay={0.75}>
                            <Button
                                circular
                                icon="filter"
                                className={styles.filtersButton}
                                onClick={onFilterClick}
                                disabled={!!quickFilter}
                            />
                        </Tooltip>
                        <Tooltip
                            title={isExpanded ? 'Collapse All Questions' : 'Expand All Questions'}
                            mouseEnterDelay={0.5}
                        >
                            <Button
                                circular
                                icon={isExpanded ? 'chevron down' : 'chevron right'}
                                className={styles.filtersButton}
                                onClick={handleExpandClick}
                            />
                        </Tooltip>
                    </>
                )}
                {pathname === `/exams/${examId}` && !hideSideNav && (
                    <Button
                        circular
                        icon="arrow left"
                        className={styles.filtersButton}
                        onClick={() => {
                            if (loadSubmissions) {
                                onClearSubmissions()
                            } else {
                                history.push('/exams')
                            }
                        }}
                        content="Back"
                        disabled={!!examTime}
                    />
                )}
                {pathname === `/profile` && (
                    <Tooltip title="Go back!" mouseEnterDelay={0.5}>
                        <Button
                            circular
                            icon="arrow left"
                            className={styles.filtersButton}
                            onClick={() => history.goBack()}
                            content="Back"
                        />
                    </Tooltip>
                )}
                {pathname === `/exams/${examId}/${submissionId}` && (
                    <Tooltip title="Submissions" mouseEnterDelay={0.5}>
                        <Button
                            circular
                            icon="arrow left"
                            className={styles.filtersButton}
                            onClick={() => history.goBack()}
                            content="Back"
                        />
                    </Tooltip>
                )}
                {hideSideNav && (
                    <CountdownTimer
                        start={examTime}
                        onTimerEnd={onTimerEnd}
                        endTime={endTime}
                        resumeTime={resumeTime}
                        beginAt={beginAt}
                    />
                )}
            </div>
            <div className={styles.rightActions}>
                <div className={styles.themeWrapper}>
                    {pathname === '/questions' && (
                        <>
                            <Button.Group className={styles.themeWrapper}>
                                <Tooltip title="Quick Filter" mouseEnterDelay={0.75}>
                                    <Button
                                        circular
                                        icon="filter"
                                        className={
                                            quickFilter ? styles.filterButtonWrapper : undefined
                                        }
                                        disabled={!!savedFilters}
                                    />
                                </Tooltip>
                                <Dropdown
                                    disabled={!!savedFilters}
                                    className="button icon"
                                    floating
                                    trigger={<></>}
                                >
                                    <Dropdown.Menu className={styles.dropdownMenuTheme}>
                                        <Dropdown.Header content="Quick filters" />
                                        <Dropdown.Divider />
                                        <Dropdown.Item
                                            onClick={() => {
                                                // setRandomize(true)
                                                setSelectedQuickFilter(null)
                                                onRandomize()
                                            }}
                                            disabled={!quickFilter}
                                        >
                                            {!quickFilter && (
                                                <Icon
                                                    name="check"
                                                    color="grey"
                                                    className="right floated"
                                                />
                                            )}
                                            {/* TODO: change this to Randomized - feature#65 */}
                                            All Questions
                                        </Dropdown.Item>
                                        <Dropdown.Divider />
                                        {/* eslint-disable-next-line react/prop-types */}
                                        {persistedCodingLanguages &&
                                            persistedCodingLanguages.map((pl) => (
                                                <Dropdown.Item
                                                    onClick={() => handleSetSelectedFilter(pl)}
                                                    key={pl.name}
                                                >
                                                    {selectedQuickFilter &&
                                                        selectedQuickFilter === pl._id &&
                                                        quickFilter && (
                                                            <Icon
                                                                name="check"
                                                                color="grey"
                                                                className="right floated"
                                                            />
                                                        )}
                                                    {pl.name}
                                                </Dropdown.Item>
                                            ))}
                                    </Dropdown.Menu>
                                </Dropdown>
                            </Button.Group>
                            <Button.Group>
                                <Tooltip title="Themes" mouseEnterDelay={0.75}>
                                    <Button circular icon="paint brush" />
                                </Tooltip>
                                <Dropdown className="button icon" floating trigger={<></>}>
                                    <Dropdown.Menu className={styles.dropdownMenuTheme}>
                                        <Dropdown.Header content="Code styles" />
                                        <Dropdown.Divider />
                                        {/* eslint-disable-next-line react/prop-types */}
                                        {themes.map((theme) => {
                                            if (selectedTheme === theme) {
                                                return (
                                                    <Dropdown.Item onClick={() => {}} key={theme}>
                                                        <Icon
                                                            name="check"
                                                            color="grey"
                                                            className="right floated"
                                                        />
                                                        {theme}
                                                    </Dropdown.Item>
                                                )
                                            }
                                            return (
                                                <Dropdown.Item
                                                    onClick={() => setSelectedTheme(theme)}
                                                    key={theme}
                                                >
                                                    {theme}
                                                </Dropdown.Item>
                                            )
                                        })}
                                        <Dropdown.Divider />
                                        <Dropdown.Header content="Questions" />
                                        <Dropdown.Divider />
                                        <Dropdown.Item onClick={handleShowIcons}>
                                            {showIcons && (
                                                <Icon
                                                    name="check"
                                                    color="grey"
                                                    className="right floated"
                                                />
                                            )}
                                            Language Icons
                                        </Dropdown.Item>
                                    </Dropdown.Menu>
                                </Dropdown>
                            </Button.Group>
                        </>
                    )}

                    {pathname === `/exams/${examId}` && (
                        <>
                            {!hideSideNav && !loadSubmissions && (
                                <Button
                                    icon="download"
                                    labelPosition="left"
                                    content="Submissions"
                                    className={styles.viewSubmissions}
                                    onClick={onListSubmissions}
                                    disabled={loadSubmissions}
                                />
                            )}
                        </>
                    )}

                    {pathname === `/exams/${examId}/${submissionId}` && (
                        <Button.Group>
                            <Tooltip title="Themes" mouseEnterDelay={0.75}>
                                <Button circular icon="paint brush" />
                            </Tooltip>
                            <Dropdown className="button icon" floating trigger={<></>}>
                                <Dropdown.Menu className={styles.dropdownMenuTheme}>
                                    <Dropdown.Header content="Code styles" />
                                    <Dropdown.Divider />
                                    {/* eslint-disable-next-line react/prop-types */}
                                    {themes.map((theme) => {
                                        if (selectedTheme === theme) {
                                            return (
                                                <Dropdown.Item onClick={() => {}} key={theme}>
                                                    <Icon
                                                        name="check"
                                                        color="grey"
                                                        className="right floated"
                                                    />
                                                    {theme}
                                                </Dropdown.Item>
                                            )
                                        }
                                        return (
                                            <Dropdown.Item
                                                onClick={() => setSelectedTheme(theme)}
                                                key={theme}
                                            >
                                                {theme}
                                            </Dropdown.Item>
                                        )
                                    })}
                                </Dropdown.Menu>
                            </Dropdown>
                        </Button.Group>
                    )}
                    {pathname === '/exams' && (
                        <>
                            <Button.Group style={{ marginRight: 8 }}>
                                <Button icon labelPosition="left" onClick={onNewRandomizedExam}>
                                    <Icon name="add" />
                                    New Exam
                                </Button>
                                <Dropdown className="button icon" floating trigger={<></>}>
                                    <Dropdown.Menu className={styles.dropdownMenuThemeExam}>
                                        <Dropdown.Header content="Exam Type" />
                                        <Dropdown.Divider />
                                        <Dropdown.Item onClick={onNewRandomizedExam}>
                                            Randomized
                                        </Dropdown.Item>
                                        <Dropdown.Item onClick={() => {}}>
                                            Custom Questions
                                        </Dropdown.Item>
                                    </Dropdown.Menu>
                                </Dropdown>
                            </Button.Group>
                            <Button.Group>
                                <Tooltip title="Themes" mouseEnterDelay={0.75}>
                                    <Button circular icon="paint brush" />
                                </Tooltip>
                                <Dropdown className="button icon" floating trigger={<></>}>
                                    <Dropdown.Menu className={styles.dropdownMenuTheme}>
                                        <Dropdown.Header content="Code styles" />
                                        <Dropdown.Divider />
                                        {/* eslint-disable-next-line react/prop-types */}
                                        {themes.map((theme) => {
                                            if (selectedTheme === theme) {
                                                return (
                                                    <Dropdown.Item onClick={() => {}} key={theme}>
                                                        <Icon
                                                            name="check"
                                                            color="grey"
                                                            className="right floated"
                                                        />
                                                        {theme}
                                                    </Dropdown.Item>
                                                )
                                            }
                                            return (
                                                <Dropdown.Item
                                                    onClick={() => setSelectedTheme(theme)}
                                                    key={theme}
                                                >
                                                    {theme}
                                                </Dropdown.Item>
                                            )
                                        })}
                                    </Dropdown.Menu>
                                </Dropdown>
                            </Button.Group>
                        </>
                    )}
                </div>
                {!beginAt && (
                    <Button.Group>
                        <Tooltip title="Profile" mouseEnterDelay={0.75}>
                            <Button circular icon="user" onClick={() => history.push('/profile')} />
                        </Tooltip>

                        <Dropdown
                            className="button icon"
                            floating
                            trigger={<></>}
                            style={{ height: '33px !important' }}
                        >
                            <Dropdown.Menu className={styles.dropdownMenu}>
                                <Dropdown.Header content="Account" />
                                <Dropdown.Divider />
                                {/* eslint-disable-next-line react/prop-types */}
                                <Dropdown.Item
                                    onClick={() => history.push('/profile')}
                                    disabled={!!examTime}
                                >
                                    <Icon
                                        name="user circle"
                                        color="grey"
                                        className="right floated"
                                    />
                                    Profile
                                </Dropdown.Item>
                                <Dropdown.Item onClick={onLogout}>
                                    <Icon name="log out" color="grey" className="right floated" />
                                    Logout
                                </Dropdown.Item>
                            </Dropdown.Menu>
                        </Dropdown>
                    </Button.Group>
                )}
            </div>
        </>
    )
}

NavActions.defaultProps = {
    onLogout: () => {},
    onToggle: () => {},
    onChangeTheme: () => {},
    visible: false,
    isExpandedState: false,
    hasSidebar: false,
    defaultTheme: 'prism',
    examTime: null,
    endTime: null,
    resumeTime: null,
    beginAt: null,
    submissionId: '',
    onShowIcons: () => {},
    onRandomize: () => {},
    onQuickFilter: () => {},
    persistedCodingLanguages: [],
    quickFilter: null,
    savedFilters: null,
}

NavActions.propTypes = {
    onLogout: PropTypes.func,
    onToggle: PropTypes.func,
    onChangeTheme: PropTypes.func,
    visible: PropTypes.bool,
    hasSidebar: PropTypes.bool,
    isExpandedState: PropTypes.bool,
    pathname: PropTypes.string.isRequired,
    history: PropTypes.object.isRequired,
    defaultTheme: PropTypes.string,
    onFilterClick: PropTypes.func.isRequired,
    onListSubmissions: PropTypes.func.isRequired,
    onNewRandomizedExam: PropTypes.func.isRequired,
    onExpand: PropTypes.func.isRequired,
    onTimerEnd: PropTypes.func.isRequired,
    onClearSubmissions: PropTypes.func.isRequired,
    examId: PropTypes.string.isRequired,
    submissionId: PropTypes.string,
    examTime: PropTypes.number,
    hideSideNav: PropTypes.bool.isRequired,
    loadSubmissions: PropTypes.bool.isRequired,
    endTime: PropTypes.string,
    beginAt: PropTypes.string,
    resumeTime: PropTypes.number,
    onShowIcons: PropTypes.func,
    showLanguageIcons: PropTypes.bool.isRequired,
    persistedCodingLanguages: PropTypes.array,
    onRandomize: PropTypes.func,
    onQuickFilter: PropTypes.func,
    quickFilter: PropTypes.string,
    savedFilters: PropTypes.object,
}

export default NavActions
