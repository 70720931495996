export const USER_LOGIN_REQUEST_START = 'auth/USER_LOGIN_REQUEST_START'
export const USER_LOGIN_REQUEST_SUCCESS = 'auth/USER_LOGIN_REQUEST_SUCCESS'
export const USER_LOGIN_REQUEST_FAIL = 'auth/USER_LOGIN_REQUEST_FAIL'

export const USER_SIGNUP_REQUEST_START = 'auth/USER_SIGNUP_REQUEST_START'
export const USER_SIGNUP_REQUEST_SUCCESS = 'auth/USER_SIGNUP_REQUEST_SUCCESS'
export const USER_SIGNUP_REQUEST_FAIL = 'auth/USER_SIGNUP_REQUEST_FAIL'

export const USER_ADMIN_SIGNUP_REQUEST_START = 'auth/USER_ADMIN_SIGNUP_REQUEST_START'
export const USER_ADMIN_SIGNUP_REQUEST_SUCCESS = 'auth/USER_ADMIN_SIGNUP_REQUEST_SUCCESS'
export const USER_ADMIN_SIGNUP_REQUEST_FAIL = 'auth/USER_ADMIN_SIGNUP_REQUEST_FAIL'

export const USER_LOGOUT = 'auth/USER_LOGOUT'
export const USER_LOGOUT_SUCCESS = 'auth/USER_LOGOUT_SUCCESS'
export const USER_LOGOUT_FAIL = 'auth/USER_LOGOUT_FAIL'

export const CHECK_USER_IS_AUTHENTICATED_START = 'auth/CHECK_USER_IS_AUTHENTICATED_START'
export const CHECK_USER_IS_AUTHENTICATED_SUCCESS = 'auth/CHECK_USER_IS_AUTHENTICATED_SUCCESS'
export const CHECK_USER_IS_AUTHENTICATED_FAIL = 'auth/CHECK_USER_IS_AUTHENTICATED_FAIL'

export const CHECK_USER_NAME_START = 'auth/CHECK_USER_NAME_START'
export const CHECK_USER_NAME_START_SUCCESS = 'auth/CHECK_USER_NAME_START_SUCCESS'
export const CHECK_USER_NAME_START_FAIL = 'auth/CHECK_USER_NAME_START_FAIL'

export const CHECK_ACCESS_TOKEN_IS_EXISTED = 'auth/CHECK_ACCESS_TOKEN_IS_EXISTED'
export const CHECK_ACCESS_TOKEN_IS_EXISTED_TRUE = 'auth/CHECK_ACCESS_TOKEN_IS_EXISTED_TRUE'
export const CHECK_ACCESS_TOKEN_IS_EXISTED_FALSE = 'auth/CHECK_ACCESS_TOKEN_IS_EXISTED_FALSE'
export const CHECK_ACCESS_TOKEN_IS_EXISTED_FAILED = 'auth/CHECK_ACCESS_TOKEN_IS_EXISTED_FAILED'

export const PERSIST_SELECTED_THEME = 'theme/PERSIST_SELECTED_THEME'
